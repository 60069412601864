import Api from "../../services/api";

export const HVehicles = () => ({
    index: async (data: any) => {
        const response = await Api.get('/vehicles', { params: data });
        return response.data;
    },
    store: async (data: any) => {
        const response = await Api.post('/vehicles', data);
        return response.data;
    },
    update: async (data: any) => {
        const response = await Api.put(`/vehicles/${data.id}`, data);
        return response.data;
    },
});