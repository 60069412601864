import convertSaveImage from "../../components/ImageUploader";
import Api from "../../services/api";


export const HResidents = () => ({
    index: async (data: any) => {
        const response = await Api.get('/residents', { params: data });
        return response.data;
    },
    store: async (data: any) => {

        try {
            const formData = new FormData();

            // Adicionar campos de formulário ao objeto FormData
            formData.append('name', data.name);
            formData.append('document', data.document);
            formData.append('phone', data.phone);
            formData.append('home_address', data.home_address);
            formData.append('email', data.email);
            formData.append('observation', data.observation);


            var sendFormData = await convertSaveImage(data, formData);

            const response = await Api.post('/residents', sendFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Definir o tipo de conteúdo como multipart/form-data
                },
            });

            return response.data;
        } catch (error) {
            console.error('Erro ao cadastrar visitante:', error);
            throw error;
        }
    },
    update: async (data: any) => {

        try {
            const formData = new FormData();


            // editar campos de formulário ao objeto FormData
            formData.append('name', data.name);
            formData.append('document', data.document);
            formData.append('phone', data.phone);
            formData.append('home_address', data.home_address);
            formData.append('email', data.email);
            formData.append('active', data.active);
            formData.append('observation', data.observation);

            var sendFormData = await convertSaveImage(data, formData);

            const response = await Api.post(`/residents/update/${data.id}`, sendFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Definir o tipo de conteúdo como multipart/form-data
                },
            });

            return response.data;


        } catch (error) {
            console.error('Erro ao editar visitante:', error);
            throw error;
        }
    },
    updateDeleteDependentes: async (data: any) => {
        const respose = await Api.put(`/residents/${data.id}`, data);
        return respose.data;
    },
    updateDeleteEmployees: async (data: any) => {
        const respose = await Api.put(`/residents/update_delete_employees/${data.id}`, data);
        return respose.data;
    }
});

