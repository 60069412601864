import { Link } from 'react-router-dom';
import SvgImg from '../../assets/svg/access_denied.svg'
import { FiChevronLeft } from "react-icons/fi";

export default function AccessDanied() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignContent: "center", alignItems: "center", textAlign: 'center' }}>
            <img src={SvgImg} width={500} alt="" />
            <br />
            <div>
                <h1>Você não tem permissão para acessar este módulo.</h1>
                <p>Entre em contato com o administrador para obter acesso.</p>

                <Link to={`/dashboard`} className="btn btn-primary">
                    <div data-i18n="Analytics">  <FiChevronLeft /> Voltar</div>
                </Link>
            </div>
        </div>
    );
}