import Api from "../../services/api";

const HDashboard = () => ({

    index: async () => {

        try {
            const response = await Api.get('/dashboard');

            return response.data;
        } catch (error) {
            throw error;
        }

    },
    lastPeopleVehicles: async () => {
        try {
            const response = await Api.get('/dashboard/last_people_vehicles');
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    lastPeoplePasses: async () => {
        try {
            const response = await Api.get('/dashboard/last_people_passes');
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    graphicData: async () => {
        try {
            const response = await Api.get('/dashboard/graphic_data');
            return response.data;
        } catch (error) {
            throw error;
        }
    }

});

export default HDashboard;