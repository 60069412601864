import { useEffect, useState } from "react";
import HDashboard from "../../hooks/dashboard";
import moment from "moment";
import 'moment/locale/pt-br'
import { Link } from "react-router-dom";
import {
    ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import employee from '../../assets/icones/employee.png';
import residents from '../../assets/icones/residents.png';
import dependents from '../../assets/icones/dependents.png';
import vehicles from '../../assets/icones/vehicles.png';
import totalPass from '../../assets/icones/totalPass.png';
import waiting from '../../assets/icones/waiting.png';
import operation from '../../assets/icones/operation.png';
import completed from '../../assets/icones/completed.png';




export default function Dashboard() {

    const apiDashboard = HDashboard();

    const [amount, setAmount] = useState<any>();
    const [dataLastPeopleVehicles, setDataLastPeopleVehicles] = useState<any>();
    const [dataLastPeoplePasses, setDataLastPeoplePasses] = useState<any>();
    const [graficData, setGraficData] = useState<any>()

    const getDashboard = async () => {
        await apiDashboard.index().then((res) => {
            setAmount(res)
        })
    }
    const getgraficData = async () => {
        await apiDashboard.graphicData().then((res) => {
            setGraficData(res);
        })
    }

    const getLastPeopleVehicles = async () => {
        apiDashboard.lastPeopleVehicles().then((res) => {
            setDataLastPeopleVehicles(res)
        }).catch((error) => {
        })
    }
    const getLastPeoplePasses = async () => {
        apiDashboard.lastPeoplePasses().then((res) => {
            setDataLastPeoplePasses(res)
        }).catch((error) => {
        })
    }

    const entryTime = (dia?: any, hora?: any) => {
        if (hora) {
            return moment(`${dia} ${hora}`).locale('pt-BR').startOf('seconds').fromNow();
        }
        return ''
    }

    useEffect(() => {
        getLastPeopleVehicles();
        getLastPeoplePasses();
        getDashboard();
        getgraficData();
    }, []);

    return (
        <div className="container-xxl flex-grow-1 container-p-y">

            <div className="row">

                <div className="col-lg-12 mb-4 order-0">
                    <div className="card">
                        <div className="d-flex align-items-end row">

                            <div className="col-sm-6">
                                <div className="card-body">
                                    <h5 className="card-title text-primary">Safe Entry 🎉</h5>

                                    <p className="mb-4">
                                        Sua tranquilidade é nossa missão!
                                    </p>

                                    <a href="https://oliversofttech.com" className="btn btn-sm btn-outline-primary">Ver Módulos do Sistema</a>
                                </div>
                            </div>

                            <div className="col-sm-5 text-center text-sm-left">
                                <div className="card-body pb-0 px-0 px-md-4">
                                    <img
                                        src="../assets/img/illustrations/man-with-laptop-light.png"
                                        height="140"
                                        alt="View Badge User"
                                        data-app-dark-img="illustrations/man-with-laptop-dark.png"
                                        data-app-light-img="illustrations/man-with-laptop-light.png"
                                    />
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className="col-lg-12 col-md-4 order-1">

                    <div className="row">

                        <div className="col-lg-3 col-md-12 col-6 mb-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title d-flex align-items-start justify-content-between">
                                        <div className="avatar flex-shrink-0">
                                            <img
                                                src={residents}
                                                alt="chart success"
                                                className="rounded"
                                            />
                                        </div>
                                    </div>
                                    <span className="fw-semibold d-block mb-1">Moradores Total</span>
                                    <h3 className="card-title mb-2">{amount?.residents}</h3>
                                    <small className="text-success fw-semibold"><i className="bx bx-up-arrow-alt"></i> Total</small>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-12 col-6 mb-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title d-flex align-items-start justify-content-between">
                                        <div className="avatar flex-shrink-0">
                                            <img
                                                src={employee}
                                                alt="chart success"
                                                className="rounded"
                                            />
                                        </div>
                                    </div>
                                    <span className="fw-semibold d-block mb-1">Funcionários</span>
                                    <h3 className="card-title mb-2">{amount?.employees}</h3>
                                    <small className="text-success fw-semibold"><i className="bx bx-up-arrow-alt"></i> Total</small>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12 col-6 mb-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title d-flex align-items-start justify-content-between">
                                        <div className="avatar flex-shrink-0">
                                            <img
                                                src={dependents}
                                                alt="chart success"
                                                className="rounded"
                                            />
                                        </div>
                                    </div>
                                    <span className="fw-semibold d-block mb-1">Dependentes</span>
                                    <h3 className="card-title mb-2">{amount?.dependents}</h3>
                                    <small className="text-success fw-semibold"><i className="bx bx-up-arrow-alt"></i> Total</small>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-12 col-6 mb-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title d-flex align-items-start justify-content-between">
                                        <div className="avatar flex-shrink-0">
                                            <img
                                                src={vehicles}
                                                alt="chart success"
                                                className="rounded"
                                            />
                                        </div>
                                    </div>
                                    <span className="fw-semibold d-block mb-1">Veículos</span>
                                    <h3 className="card-title mb-2">{amount?.vehicles}</h3>
                                    <small className="text-success fw-semibold"><i className="bx bx-up-arrow-alt"></i> Total</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-12 col-md-4 order-1">

                    <div className="row">

                        <div className="col-lg-3 col-md-12 col-6 mb-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title d-flex align-items-start justify-content-between">
                                        <div className="avatar flex-shrink-0">
                                            <img
                                                src={totalPass}
                                                alt="chart Info"
                                                className="rounded"
                                            />
                                        </div>

                                        <h3 style={{ marginRight: 10 }} className="card-title">{amount?.totalPasses}</h3>
                                    </div>
                                    <span className="fw-semibold d-block mb-1">Total</span>
                                    <h3 className="card-title mb-2"></h3>
                                    <small className="text-Info fw-semibold"><i className="bx bx-up-arrow-alt"></i> Passagem</small>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-12 col-6 mb-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title d-flex align-items-start justify-content-between">
                                        <div className="avatar flex-shrink-0">
                                            <img
                                                src={waiting}
                                                alt="chart danger"
                                                className="rounded"
                                            />
                                        </div>
                                        <h3 style={{ marginRight: 10 }} className="card-title">{amount?.passageWaiting}</h3>
                                    </div>
                                    <span className="fw-semibold d-block mb-1">Total Aguardando</span>
                                    <h3 className="card-title mb-2">{ }</h3>
                                    <small className="text-danger fw-semibold"><i className="bx bx-up-arrow-alt"></i> Passagem</small>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12 col-6 mb-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title d-flex align-items-start justify-content-between">
                                        <div className="avatar flex-shrink-0">
                                            <img
                                                src={operation}
                                                alt="chart warning"
                                                className="rounded"
                                            />
                                        </div>
                                        <h3 style={{ marginRight: 10 }} className="card-title">{amount?.passOperation}</h3>
                                    </div>
                                    <span className="fw-semibold d-block mb-1">Total Em Operação</span>
                                    <h3 className="card-title mb-2">{ }</h3>
                                    <small className="text-warning fw-semibold"><i className="bx bx-up-arrow-alt"></i> Passagem</small>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12 col-6 mb-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title d-flex align-items-start justify-content-between">
                                        <div className="avatar flex-shrink-0">
                                            <img
                                                src={completed}
                                                alt="chart Secondary"
                                                className="rounded"
                                            />
                                        </div>
                                        <h3 style={{ marginRight: 10 }} className="card-title">{amount?.passageCompleted}</h3>
                                    </div>
                                    <span className="fw-semibold d-block mb-1">Total Concluída</span>

                                    <small className="text-Secondary fw-semibold"><i className="bx bx-up-arrow-alt"></i> Passagem</small>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>


            <div className="col-lg-12">
                <div className='card  p-4 mb-5'>
                    <h5>Passagens por Mês</h5>
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={graficData?.totalPassPerCategory}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="month" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="Visitante" fill="#300060" />
                            <Bar dataKey="Morador" fill="#600080" />
                            <Bar dataKey="Dependente" fill="#8000A0" />
                            <Bar dataKey="Funcionário" fill="#A020F0" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>


            <div className="row">
                <div className="col-md-6 col-lg-6 order-2 mb-4">
                    <div className="card h-100">
                        <div className="card-header d-flex align-items-center justify-content-between">
                            <h5 className="card-title m-0 me-2">Últimas passagens sem veículo</h5>
                            <div className="dropdown">
                                <button
                                    className="btn p-0"
                                    type="button"
                                    id="transactionID"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <i className="bx bx-dots-vertical-rounded"></i>
                                </button>
                                <Link className="dropdown-menu dropdown-menu-end" to={'/passages'}>
                                    <a className="dropdown-item">Ver todas</a>
                                </Link>
                            </div>
                        </div>
                        <div className="card-body">
                            <ul className="p-0 m-0">

                                {
                                    dataLastPeoplePasses &&
                                    dataLastPeoplePasses.map((d: any, k: any) => (
                                        <li key={k} className="d-flex mb-4 pb-1">
                                            <div style={{ borderRadius: 5, backgroundColor: '#696cffd9', alignContent: 'center', display: 'flex', justifyContent: 'center' }} className="avatar flex-shrink-0 me-3">
                                                <span style={{ marginTop: 8, color: '#ffffff' }} className="rounded" >M</span>
                                            </div>
                                            <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                <div className="me-2">
                                                    {
                                                        d.exit_time ?
                                                            <small className="text-muted d-block mb-1"> {d.register_common?.types_peoples?.description} - {d.pass_status?.name}  {d.exit_time && ' - ' + entryTime(moment(d.exit_time).format('YYYY-MM-DD'), moment(d.exit_time).format('HH:mm:ss'))}</small> :
                                                            <small className="text-muted d-block mb-1"> {d.register_common?.types_peoples?.description} - {d.pass_status?.name}  {d.entry_time && ' - ' + entryTime(moment(d.entry_time).format('YYYY-MM-DD'), moment(d.entry_time).format('HH:mm:ss'))}</small>
                                                    }

                                                    <h6 className="mb-0">{d.register_common?.name}</h6>
                                                </div>
                                            </div>
                                        </li>
                                    ))
                                }



                            </ul>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-lg-6 order-2 mb-4">
                    <div className="card h-100">

                        <div className="card-header d-flex align-items-center justify-content-between">
                            <h5 className="card-title m-0 me-2">Últimas passagens com veículos</h5>
                            <div className="dropdown">
                                <button
                                    className="btn p-0"
                                    type="button"
                                    id="transactionID"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <i className="bx bx-dots-vertical-rounded"></i>
                                </button>

                                <Link className="dropdown-menu dropdown-menu-end" to={'/passages'}>
                                    <a className="dropdown-item">Ver todas</a>
                                </Link>
                            </div>
                        </div>

                        <div className="card-body">
                            <ul className="p-0 m-0">

                                {
                                    dataLastPeopleVehicles &&
                                    dataLastPeopleVehicles.map((d: any, k: any) => (
                                        <li key={k} className="d-flex mb-4 pb-1">
                                            <div style={{ borderRadius: 5, backgroundColor: '#03c3ec', alignContent: 'center', display: 'flex', justifyContent: 'center' }} className="avatar flex-shrink-0 me-3">
                                                <span style={{ marginTop: 8, color: '#ffffff' }} className="rounded" >{d.passagem?.register_common?.name.slice(0, 1)}</span>
                                            </div>
                                            <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                <div className="me-2">
                                                    <small className=" d-block "> {d.passagem?.register_common?.name}</small>

                                                    {
                                                        d.passagem?.exit_time ?
                                                            < small className="text-muted d-block mb-1"> {d.passagem?.register_common?.types_peoples?.description} - {d.passagem?.pass_status?.name}  {d.passagem?.exit_time && ' - ' + entryTime(moment(d.passagem?.exit_time).format('YYYY-MM-DD'), moment(d.passagem?.exit_time).format('HH:mm:ss'))}</small> :
                                                            <small className="text-muted d-block mb-1"> {d.passagem?.register_common?.types_peoples?.description} - {d.passagem?.pass_status?.name}  {d.passagem?.entry_time && ' - ' + entryTime(moment(d.passagem?.entry_time).format('YYYY-MM-DD'), moment(d.passagem?.entry_time).format('HH:mm:ss'))}</small>
                                                    }

                                                    <h6 className="mb-0">{d.vehicle?.brand}</h6>
                                                </div>
                                                <div className="user-progress d-flex align-items-center gap-1">
                                                    <h6 className="mb-0">{d.vehicle?.plate}</h6>
                                                </div>
                                            </div>
                                        </li>
                                    ))
                                }

                            </ul>
                        </div>
                    </div>
                </div>


            </div>
        </div >
    );
}