
import { ModalDialog } from "../../components/modalComponent";
import { useForm } from "react-hook-form";
import { ToastInfo } from "../../components/ToastInfo";

export default function EditVehicles({ id, apiVehicles, getLisData, firstData }: any) {

    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            brand: firstData.brand,
            model: firstData.model,
            type: firstData.type,
            plate: firstData.plate,
            active: firstData.active ? 1 : 0,
            vacancy_type: firstData.vacancy_type,
            vehicle_color: firstData.vehicle_color,
            observation: firstData.observation ? firstData.observation : '',
        },
        values: {
            brand: firstData.brand,
            model: firstData.model,
            type: firstData.type,
            plate: firstData.plate,
            active: firstData.active ? 1 : 0,
            vacancy_type: firstData.vacancy_type,
            vehicle_color: firstData.vehicle_color,
            observation: firstData.observation ? firstData.observation : '',
        }
    })

    const edit = async (formData: any) => {
        formData.id = firstData.id
        try {
            apiVehicles.update(formData).then((res: any) => {
                ToastInfo(res);
                (window as any).$(`#${id}`).modal('hide');
                getLisData();
            })
        } catch (errors) {
            (window as any).$(`#${id}`).modal('hide')
        }
    }



    return (
        <ModalDialog id={id} form={true} title="Editar Veículo" onClick={handleSubmit(edit)} buttonClose={[true, 'Cancelar']} buttonSend={[true, 'Editar']}>

            <div className="mb-3">
                <label >Placa</label>
                <input {...register('plate', { required: true })} type="text" className="form-control" placeholder="Ex.: AAA-8596" />
                {
                    errors.plate &&
                    <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                }
            </div>
            <div className="mb-3">
                <label >Marca do veículo</label>
                <input {...register('brand', { required: true })} type="text" className="form-control" placeholder="Ex.: Fiat" />
                {
                    errors.brand &&
                    <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                }
            </div>

            <div className="mb-3">
                <label >Modelo</label>
                <input {...register('model', { required: true })} type="text" className="form-control" placeholder="Ex.: UP" />
                {
                    errors.model &&
                    <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                }
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className="mb-3">
                        <label >Cor Veículo</label>
                        <input {...register('vehicle_color', { required: true })} type="text" className="form-control" />
                        {
                            errors.vehicle_color &&
                            <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                        }
                    </div>

                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label >Status:</label>
                        <select {...register('active', { required: true })} className="form-control">
                            <option value="">Selecione...</option>
                            <option value={1}>Ativo</option>
                            <option value={0}>Inativo</option>
                        </select>
                        {
                            errors.active &&
                            <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                        }
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className="mb-3">
                        <label >Tipo do veículo</label>
                        <select {...register('type', { required: true })} className="form-control">
                            <option value="">Selecione...</option>
                            <option value="Carro">Carro</option>
                            <option value="Moto">Moto</option>
                            <option value="Caminhonete">Caminhonete</option>
                            <option value="Caminhão">Caminhão</option>
                            <option value="Outros">Outros</option>
                        </select>
                        {
                            errors.type &&
                            <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                        }
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label >Vaga Tipo:</label>
                        <select {...register('vacancy_type', { required: true })} className="form-control">
                            <option value="">Selecione...</option>
                            <option value="Própria">Própria</option>
                            <option value="Alugada">Alugada</option>
                        </select>
                        {
                            errors.vacancy_type &&
                            <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                        }
                    </div>
                </div>
            </div>

            <div className="mb-3">
                <label >Observação</label>
                <textarea  {...register('observation')} rows={5} className="form-control"></textarea>
            </div>

        </ModalDialog>
    );
}