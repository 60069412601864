import { useForm } from "react-hook-form";
import { ModalDialog } from "../../components/modalComponent";
import HPost from "../../hooks/post";
import { ToastInfo } from "../../components/ToastInfo";
import { FiPlusCircle } from "react-icons/fi";
import { useEffect, useState } from "react";
import EditableItem from "../../components/editableItem";
import { useNavigate, useParams } from "react-router-dom";



export default function EditPost() {

    const apiPost = HPost();
    const navigate = useNavigate();
    const { id } = useParams();
    const [itemName, setItemName] = useState<string>('');
    const [itemAmount, setItemAmount] = useState<string>('');
    const [firstListData, setfirstListData] = useState<any>();


    const { register, handleSubmit, formState: { errors } } = useForm<any>({
        defaultValues: {
            name: firstListData?.name,
            description: firstListData?.description,
        },
        values: {
            name: firstListData?.name,
            description: firstListData?.description,
        }
    })

    const update = async (formData: any) => {
        formData.id = firstListData?.id;
        await apiPost.update(formData).then((res) => {
            ToastInfo(res);
        })
    }

    const addItem = async () => {

        if (itemName === '' || itemAmount === '') {
            return false;
        }

        var dataSend = {
            name: itemName,
            amount: itemAmount,
            post_id: firstListData?.id,
        }

        await apiPost.storeItem(dataSend).then((res) => {
            ToastInfo(res)
            setItemName('');
            setItemAmount('');
            getFirstListData()
        })
        // aqui você pode chamar a API para adicionar o item
    }


    const updateItem = async (itemKey: any, { name, amount, id: itemId }: any) => {
        var dataSend = {
            name: name,
            amount: parseInt(amount),
            id: itemId,
        }


        await apiPost.updateItem(dataSend).then((res) => {
            ToastInfo(res)
     
            getFirstListData()
        })
    };


    const getFirstListData = async () => {
        const params = {
            id: id,
        }
        await apiPost.index(params).then((res) => {
            setfirstListData(res.data[0]);
        })
    }

    const deleteItem = async ($idItem: any) => {
        await apiPost.delteItem($idItem).then((res) => {
            ToastInfo(res)
            getFirstListData()
        })
    }

    useEffect(() => {
        getFirstListData()
    }, [])



    return (
        <div className=' card' style={{ padding: 30, }}>
            <h4>Edita dados Posto #{firstListData?.id}</h4>
            <hr />
            <div className="mb-3">
                <label >Nome do Posto</label>
                <input autoComplete="off" {...register('name', { required: true })} type="text" className="form-control" />
                {
                    errors.name &&
                    <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                }
            </div>

            <div className="mb-3">
                <label >Descrição do Posto</label>
                <input autoComplete="off"  {...register('description', { required: true })} type="text" className="form-control" />
                {
                    errors.description &&
                    <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                }
            </div>

            <hr />

            <h5>Itens do Posto</h5>

            {
                firstListData?.post_items?.map((item: any, key: any) => (
                    <EditableItem key={key} itemKey={key} item={item} updateItem={updateItem} deleteItem={deleteItem} itemId={item.id} />
                ))
            }
            <br />

            <div className="row">
                <div className="col-md-7 mb-2">
                    <input type="text" value={itemName} onChange={(e: any) => setItemName(e.target.value)} className="form-control form-control-sm" placeholder="Cadastre um novo item, informe o nome" />
                    {
                        !itemName
                    }
                </div>

                <div className="col-md-3 mb-2">
                    <input type="number" value={itemAmount} onChange={(e: any) => setItemAmount(e.target.value)} className="form-control form-control-sm" placeholder="Informe a quantidade" />
                </div>
                <div className="col-md-2">
                    <button type="button" onClick={addItem} className="btn btn-primary btn-sm"> <FiPlusCircle /> </button>
                </div>

            </div>

            <hr />

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button onClick={() => { navigate('/post') }} className='btn btn-primary'>Voltar</button>
                <button onClick={handleSubmit(update)} type='button' className='btn btn-primary'>Editar</button>
            </div>



        </div>
    );
}