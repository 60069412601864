import { useState } from 'react';
import { ModalDialog } from "../../components/modalComponent";

import InputMask from 'react-input-mask';
import { WebcamComponent } from '../../components/WebcamComponent';
import { HDependents } from '../../hooks/dependents';
import { useForm } from 'react-hook-form';
import { ToastInfo } from '../../components/ToastInfo';

interface IAddDependents {
    id: any;
    listTypesDependents: any[];
    getListData: () => void
}

export default function AddDependents({ id, getListData, listTypesDependents }: IAddDependents) {

    const apiDependents = HDependents();

    const [imgAddPhoto, setImgAddPhoto] = useState<string | null>(null);

    const { handleSubmit, register, reset, formState: { errors } } = useForm({
        defaultValues: {
            name: '',
            document: '',
            phone: '',
            image: '',
            type_dependent_id: '',
            observation: ''
        },
    })

    const handleTakePhoto = (photo: string) => {
        setImgAddPhoto(photo);
    }

    const add = async (formData: any) => {
        formData.image = imgAddPhoto;
        formData.type_dependent_id = parseInt(formData.type_dependent_id);

        apiDependents.store(formData).then((res: any) => {
            ToastInfo(res);
            (window as any).$(`#${id}`).modal('hide');
            reset();
            getListData();
        }).catch((error) => {
            (window as any).$(`#${id}`).modal('hide');
            console.error('Error ao salvar Cadastrar visitante', error)
        });
    }

    return (
        <ModalDialog id={id} form={true} title="Cadastrar Dependente" onClick={handleSubmit(add)} buttonClose={[true, 'Cancelar']} buttonSend={[true, 'Salvar']}>

            <WebcamComponent onTakePhoto={handleTakePhoto} />

            <div className="">
                <div className="mb-3">
                    <label >Nome completo Dependente:</label>
                    <input {...register('name', { required: true })} type="text" className="form-control" />
                    {
                        errors.name &&
                        <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                    }
                </div>
                <div className="mb-3">
                    <label >Tipo de Dependente:</label>
                    <select {...register('type_dependent_id', { required: true })} className="form-control" >
                        <option value="">Selecione...</option>
                        {
                            listTypesDependents.map((d:any, k:any) => (
                                <option key={k} value={d.id}>{d.description}</option>
                            ))
                        }
                    </select>
                    {
                        errors.type_dependent_id &&
                        <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                    }
                </div>
                <div className="mb-3">
                    <label >Documento:</label>
                    <input {...register('document', { required: true })} type="text" className="form-control" />
                </div>
                {
                    errors.document &&
                    <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                }
                <div className="mb-3">
                    <label >Telefone:</label>
                    <InputMask {...register('phone', { required: true })} className="form-control" mask="(99) 9 9999-9999" />
                </div>
                {
                    errors.phone &&
                    <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                }

                <div className="mb-3">
                    <label >Observação</label>
                    <textarea {...register('observation')} rows={5} className="form-control"></textarea>
                </div>

            </div>


        </ModalDialog>
    );
}
