import convertSaveImage from "../../components/ImageUploader";
import Api from "../../services/api";


export const HDependents = () => ({
    index: async (data: any) => {
        const response = await Api.get('/dependents', { params: data });
        return response.data;
    },
    store: async (data: any) => {

        try {
            const formData = new FormData();

            // Adicionar campos de formulário ao objeto FormData
            formData.append('name', data.name);
            formData.append('document', data.document);
            formData.append('phone', data.phone);
            formData.append('type_dependent_id', data.type_dependent_id);
            formData.append('observation', data.observation);

            var sendFormData = await convertSaveImage(data, formData);

            const response = await Api.post('/dependents', sendFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Definir o tipo de conteúdo como multipart/form-data
                },
            });

            return response.data;
        } catch (error) {
            console.error('Erro ao cadastrar Dependentes:', error);
            throw error;
        }
    },
    update: async (data: any) => {

        try {
            const formData = new FormData();

            // Adicionar campos de formulário ao objeto FormData
            formData.append('name', data.name);
            formData.append('document', data.document);
            formData.append('phone', data.phone);
            formData.append('type_dependent_id', data.type_dependent_id);
            formData.append('active', data.active);
            formData.append('observation', data.observation);

            var sendFormData = await convertSaveImage(data, formData);

            const response = await Api.post(`/dependents/update/${data.id}`, sendFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Definir o tipo de conteúdo como multipart/form-data
                },
            });

            return response.data;


        } catch (error) {
            console.error('Erro ao editar Dependentes:', error);
            throw error;
        }
    },
    TypesDependents: async () => {
        const response = await Api.get('/dependents/types-dependents');
        return response.data;
    }
});

