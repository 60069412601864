import { useRef } from "react";
import { ModalDialog } from "../../components/modalComponent";
import { useForm, Controller } from "react-hook-form";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import { FcApproval, FcCancel } from "react-icons/fc";
import { useState } from 'react';
import HOccurrenceBook from "../../hooks/occurrenceBook";
import { ToastInfo } from "../../components/ToastInfo";

interface IDeletePassages {
    id: any;
    firstItemsPost: any;
    getListData?: any;

}

interface Item {
    quantity: number;
    discrepancy: number;
}

interface FormValues {
    items?: Item[]; // array de objetos Item
    post: any;
    start_of_shift: any;
    end_of_shift: any;
    complete_description: any;
    conciergeItems: any;
    idBook?: any;
}


export default function EditOccurrenceBook({ id, firstItemsPost, getListData }: IDeletePassages) {

    const apiOccurrenceBook = HOccurrenceBook();
    const [showCheckAll, setShowCheckAll] = useState<boolean>(false);
    const [updateRealTimeDescription, setUpdateRealTimeDescription] = useState<string>('');
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
    const [showUpdatedMessage, setShowUpdatedMessage] = useState<boolean>(false);
    const [showAlertMessage, setShowAlertMessage] = useState<any>();

    const [showDiscrepancy, setShowDiscrepancy] = useState(
        firstItemsPost?.check_concierge_items
            ? Array(firstItemsPost.check_concierge_items.length).fill(false)
            : []
    );

    const { control, handleSubmit, reset, register, formState: { errors } } = useForm<FormValues>({
        defaultValues: {
            post: firstItemsPost?.post?.name,
            start_of_shift: firstItemsPost.start_of_shift,
            end_of_shift: firstItemsPost.end_of_shift,
            complete_description: firstItemsPost.complete_description,
            conciergeItems: ''
        },
        values: {
            post: firstItemsPost?.post?.name,
            start_of_shift: firstItemsPost.start_of_shift,
            end_of_shift: firstItemsPost.end_of_shift,
            complete_description: firstItemsPost.complete_description,
            conciergeItems: '',
        }
    })

    const handleAmountChange = (index: number, value: string, amount: number) => {
        const newShowDiscrepancy = [...showDiscrepancy];
        newShowDiscrepancy[index] = Number(value) !== amount;
        setShowDiscrepancy(newShowDiscrepancy);
    }

    const update = async (formData: FormValues) => {

        const conciergeItems = firstItemsPost?.check_concierge_items.map((item: any, i: any) => ({
            id: item.id,
            id_post: item.post_item_id,
            check_final: formData && formData.items && formData.items[i] ? formData.items[i].quantity : null,
            check_description: formData.items![i]?.discrepancy ?? ''
        }));


        var checkTotalItensSelected = conciergeItems.filter((item: any) => item?.check_final).length;
        var chekTotalItensApi = firstItemsPost?.check_concierge_items.filter((item: any) => item.id).length;


        if (chekTotalItensApi <= (checkTotalItensSelected + 1)) {

        } else {
            setShowCheckAll(true)
            return false;
        }

        setShowCheckAll(false);
        const dataSend = {
            id: firstItemsPost.id,
            start_of_shift: formData.start_of_shift,
            end_of_shift: formData.end_of_shift,
            complete_description: formData.complete_description,
            conciergeItems: conciergeItems
        }

        await apiOccurrenceBook.update(dataSend).then((res) => {
            ToastInfo(res);
            (window as any).$(`#${id}`).modal('hide');
            getListData();
            setShowCheckAll(false);
            setShowDiscrepancy(
                firstItemsPost?.check_concierge_items
                    ? Array(firstItemsPost.check_concierge_items.length).fill(false)
                    : []
            );
        }).finally(() => { reset(); })


    }

    const updateRealTimeDescriptionAta = async (value: string) => {

        if (!value) {
            setShowUpdatedMessage(true);
            setShowAlertMessage({ status: 0, msg: 'Campo obrigatório' })
            return false
        }
        const dataSend = {
            id: firstItemsPost.id,
            complete_description: value,
        };

        await apiOccurrenceBook.updateRealTimeDescriptionAta(dataSend).then((res: any) => {
            setShowUpdatedMessage(true);
            setShowAlertMessage(res)
        });

        setTimeout(() => {
            setShowUpdatedMessage(false);
            getListData();
        }, 3000); // A mensagem desaparecerá depois de 3 segundos
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = e.target.value;
        setUpdateRealTimeDescription(newValue);

        if (timer) {
            clearTimeout(timer);
        }

        setTimer(setTimeout(() => {
            updateRealTimeDescriptionAta(newValue);
        }, 500));  // Espera 500ms após o usuário parar de digitar para chamar a API
    };

    return (
        <ModalDialog id={id} modalSize={'xl'} form={true} onClick={handleSubmit(update)} title={`Editar Ata Nº #${firstItemsPost.id}`} buttonClose={[firstItemsPost.finalized === 1 ? false : true, 'Cancelar']} buttonSend={[firstItemsPost.finalized === 1 ? false : true, 'Finalizar']}>
            <div>
                <div className="mb-3">
                    <label >Posto:</label>
                    <input {...register('post')} readOnly type="text" className="form-control" />
                </div>
                <div className="mb-3">
                    <label >Porteiro:</label>
                    <input type="text" value={firstItemsPost!.user?.name} className="form-control" readOnly />
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label >Inicio do Turno:</label>
                            <input {...register('start_of_shift', { required: true })} type="time" className="form-control" />
                            {
                                errors.start_of_shift &&
                                <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                            }
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label >Fim do Turno:</label>
                            <input {...register('end_of_shift', { required: true })} type="time" className="form-control" />
                            {
                                errors.end_of_shift &&
                                <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                            }
                        </div>
                    </div>
                </div>

                <div className="mb-3">
                    <label>Descrição completa da ATA:</label>
                    <textarea
                        {...register('complete_description', { required: true })}
                        onChange={handleOnChange}
                        value={updateRealTimeDescription}
                        rows={10}
                        className="form-control"
                    />
                    {
                        errors.complete_description &&
                        <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                    }
                    {showUpdatedMessage &&
                        <span style={{ marginTop: 10 }} className={`badge bg-label-${showAlertMessage?.status === 1 ? 'success' : 'danger'}`}>{showAlertMessage?.msg}</span>
                    }
                </div>
                {/* showAlertMessage */}
            </div>

            <div><hr />
                <h6>Checklist Entrada</h6>
                <div>
                    <table className="table table-sm">
                        <thead>
                            <tr>
                                <th>check</th>
                                <th>Descrição</th>
                                <th>QTD. inicial</th>
                                <th>QTD. Check</th>
                                <th>Divergência</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                firstItemsPost?.check_concierge_items &&
                                firstItemsPost?.check_concierge_items.map((d: any, k: any) => (

                                    <tr key={k}>
                                        <td>
                                            {
                                                d.description_initial ? <FcCancel /> : <FcApproval />
                                            }
                                        </td>
                                        <td >{d.name}</td>
                                        <td >{d.initial_volume}</td>
                                        <td>{d.check_initial}</td>
                                        <td>{d.description_initial ?? '---'}</td>
                                    </tr>

                                ))
                            }
                        </tbody>

                    </table>
                </div>

            </div>



            {
                firstItemsPost?.check_concierge_items !== undefined && firstItemsPost?.check_concierge_items![0]?.check_final &&
                (
                    <div><hr />
                        <h6>Checklist Saída</h6>
                        <div>
                            <table className="table table-sm">
                                <thead>
                                    <tr>
                                        <th>check</th>
                                        <th>Descrição</th>
                                        <th>QTD. inicial</th>
                                        <th>QTD. Check Final</th>
                                        <th>Divergência</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        firstItemsPost?.check_concierge_items &&
                                        firstItemsPost?.check_concierge_items.map((d: any, k: any) => (

                                            <tr key={k}>
                                                <td>
                                                    {
                                                        d.description_final ? <FcCancel /> : <FcApproval />
                                                    }
                                                </td>
                                                <td >{d.name}</td>
                                                <td >{d.initial_volume}</td>
                                                <td>{d.check_final}</td>
                                                <td>{d.description_final ?? '---'}</td>
                                            </tr>

                                        ))
                                    }
                                </tbody>

                            </table>
                        </div>

                    </div>
                )
            }



            {
                firstItemsPost?.check_concierge_items &&
                firstItemsPost?.check_concierge_items.map((d: any, k: any) => {

                    if (!d.check_final) {
                        return (
                            <div className="row" key={k}>
                                <div className="col-md-4">
                                    <div className="form-check" >
                                        <label className="form-check-label" htmlFor="itens">
                                            • {d.name}
                                        </label>
                                    </div>
                                </div>


                                <div className="col-md-2 mb-1">
                                    <div style={{ marginLeft: 20 }}>
                                        <Controller
                                            control={control}
                                            name={`items.${k}.quantity`}
                                            render={({ field }) => (
                                                <input
                                                    type="number"
                                                    placeholder={`Qtd: ${d.check_initial}`}
                                                    className="form-control form-control-sm"
                                                    value={field.value}
                                                    onChange={e => {
                                                        field.onChange(e.target.value);
                                                        handleAmountChange(k, e.target.value, d.check_initial);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>


                                {showDiscrepancy[k] &&
                                    <div className="col-md-6">
                                        <div style={{ marginLeft: 20 }}>
                                            <Controller
                                                control={control}
                                                name={`items.${k}.discrepancy`}
                                                rules={{ required: showDiscrepancy[k] ? "Campo obrigatório" : undefined }}


                                                render={({ field }) => (
                                                    <input
                                                        type="text"
                                                        placeholder={`Informe a Divergência.`}
                                                        className="form-control form-control-sm"
                                                        value={field.value}
                                                        onChange={e => field.onChange(e.target.value)}
                                                    />
                                                )}
                                            />
                                            {errors.items?.[k]?.discrepancy && <span style={{ fontSize: 10, color: 'red' }}>Campo obrigatório</span>}
                                        </div>
                                    </div>
                                }

                            </div>
                        )
                    }

                })
            }

            {showCheckAll ? <span style={{ fontSize: 12, color: 'red' }}>Preencha todos os campos do checklist</span> : ''}

        </ModalDialog >
    );
}