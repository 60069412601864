
import { Controller, useForm } from "react-hook-form";
import { ModalDialog } from "../../components/modalComponent";
import { useState } from "react";
import { HRegisterCommons } from "../../hooks/RegisterCommons";
import HInfractions from "../../hooks/Infractions";
import { ToastInfo } from "../../components/ToastInfo";
import { NumericFormat } from 'react-number-format';

export default function AddInfractions({ id, getInfractions }: any) {

    const [registerCommons, setRegisterCommons] = useState<any>([]);

    const apiRegisterCommons = HRegisterCommons();
    const apiInfractions = HInfractions();


    const { handleSubmit, register, reset, formState: { errors }, control } = useForm({
        defaultValues: {
            register_common_id: '',
            occurrence_date: '',
            occurrence_description: '',
            notified: '',
            received_fine: '',
            fine_paid: '',
            amount_of_the_fine: '',
            observation: ''
        },
    })


    const getRegisterCommons = async () => {

        let typePeople: number | null = null;

        (window as any).$(".form-check-input:checked").each((i: number, e: any) => {
            typePeople = parseInt(e.value);
        });

        if (typePeople) {
            await apiRegisterCommons.registerCommons(typePeople).then(resp => {
                setRegisterCommons(resp);
            })
        }

    }


    const add = async (formdata: any) => {

        let valor = formdata.amount_of_the_fine;
        valor = valor.replace("R$", "").replace(".", "").replace(",", ".");
        let valorEmDouble = parseFloat(valor);
        formdata.amount_of_the_fine = valorEmDouble;

        await apiInfractions.store(formdata).then(resp => {
            ToastInfo(resp);
            (window as any).$("#" + id).modal("hide");
            reset();
            getInfractions();
        }).catch(error => {
      
            (window as any).$(`#${id}`).modal('hide');
        })

    }

    return (
        <ModalDialog id={id} title="Cadastra nova ocorrência" onClick={handleSubmit(add)} buttonClose={[true, 'Cancelar']} buttonSend={[true, 'Salvar']}>

            <div className="mb-3">
                <label htmlFor="">Descrição da ocorrência</label>
                <textarea {...register("occurrence_description", { required: true })} name="occurrence_description" rows={2} className="form-control"></textarea>
                {
                    errors.occurrence_description &&
                    <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                }
            </div>

            <div className="">
                <div className="mb-3">
                    <label htmlFor="">Foi Notificado:</label>
                    <select className="form-control" {...register("notified", { required: true })}>
                        <option value="">Selecione...</option>
                        <option value="1">Sim</option>
                        <option value="0">Não</option>
                    </select>
                    {
                        errors.notified &&
                        <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                    }
                </div>
                <div className="mb-3">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions" onChange={getRegisterCommons} id="inlineRadio1" value="1" />
                        <label className="form-check-label" htmlFor="inlineRadio1">Visitante</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions" onChange={getRegisterCommons} id="inlineRadio2" value="2" />
                        <label className="form-check-label" htmlFor="inlineRadio2">Morador</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions" onChange={getRegisterCommons} id="inlineRadio3" value="3" />
                        <label className="form-check-label" htmlFor="inlineRadio3">Dependente</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions" onChange={getRegisterCommons} id="inlineRadio4" value="4" />
                        <label className="form-check-label" htmlFor="inlineRadio3">Funcionário</label>
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="">Responsável:</label>
                    <select className="form-control" {...register("register_common_id", { required: true })}>
                        <option value="">Selecione...</option>
                        {
                            registerCommons.map((r: any, k:any) => {
                                return <option key={k} value={r.id}>{r.name}</option>;
                            })
                        }
                    </select>
                    {
                        errors.register_common_id &&
                        <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                    }
                </div>
                <div className="mb-3">
                    <label htmlFor="">Data da Notificação:</label>
                    <input type="date" className="form-control" {...register("occurrence_date")} />
                </div>
                <div className="mb-3">
                    <label htmlFor="">Recebeu Multa:</label>
                    <select className="form-control" {...register("received_fine", { required: true })} >
                        <option value="">Selecione...</option>
                        <option value="1">Sim</option>
                        <option value="0">Não</option>
                    </select>
                    {
                        errors.received_fine &&
                        <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                    }
                </div>
                <div className="mb-3">
                    <label htmlFor="">Multa paga:</label>
                    <select className="form-control" {...register("fine_paid", { required: true })}>
                        <option value="">Selecione...</option>
                        <option value="1">Sim</option>
                        <option value="0">Não</option>
                    </select>
                    {
                        errors.fine_paid &&
                        <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                    }
                </div>
                <div className="mb-3">
                    <label htmlFor="">Valor da Multa</label>
                    <Controller
                        control={control}
                        name="amount_of_the_fine"
                        defaultValue=""
                        render={({ field }) => (
                            <NumericFormat
                                {...field}
                                thousandSeparator="."
                                decimalSeparator=","
                                prefix="R$"
                                className="form-control"
                            />
                        )}
                    />

                </div>
                <div className="mb-3">
                    <label htmlFor="">Mais detalhes sobre o caso:</label>
                    <textarea rows={6} className="form-control" {...register("observation")}></textarea>
                </div>

            </div>

        </ModalDialog>
    );
}