import { useState, useEffect } from "react";
import StaffPage from "../../components/staffPage";
import SvgCheck from '../../assets/svg/itemsChecklist.svg'
import CardSearchAndAdd from "../../components/cardSearchAndAdd";
import TableComponent from "../../components/tableComponent";
import EditItemsChecklist from "./edit_post";
import AddItemsChecklist from "./add_post";
import { FiEdit } from "react-icons/fi";
import HPost from "../../hooks/post";
import moment from "moment";
import { useNavigate } from "react-router-dom";


export default function ItemsChecklist() {

    const apiPost = HPost();
    const navigate = useNavigate();
    const [listData, setListData] = useState<any>();
    const [search, setSearch] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);


    const getListData = async (numPage = 1, _search = search) => {
        setLoading(true)
        const params = {
            page: numPage,
            search: _search
        }
        await apiPost.index(params).then((res) => {
            setListData(res);
        }).finally(() => { setLoading(false) })
    }

    useEffect(() => {
        getListData();
    }, [])

    return (
        <>
            <StaffPage iconSvg={SvgCheck} description='Sua portaria organizada: cadastre postos e seus respectivos itens.' name='Posto' />


            <CardSearchAndAdd searchValue={search} searchChange={(e: any) => { setSearch(e.target.value) }} searchButton={() => { getListData(1, search) }} add={() => { (window as any).$(`#modalAdd`).modal('show') }} />

            <div className="card">
                <h5 className="card-header">Todos Postos</h5>
                <TableComponent loading={loading} PaginateFunction={getListData} PaginateListData={listData?.meta} colums={['#', 'Nome do Posto', 'Descrição', 'totoal de itens', 'Total Livro Ata', 'cadastro', 'Ações',]} >

                    {
                        listData &&
                        listData.data.map((d: any, k: any) => (
                            <tr key={k}>
                                <td>{d.id}</td>
                                <td>{d.name}</td>
                                <td>{d.description} </td>
                                <td>{d.amount_itens_check} </td>
                                <td>{d.occurrence_book}</td>
                                <td>{d.created_at ? moment(d.created_at).format('lll') : '----'}</td>
                                <td>
                                    <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                                        <button onClick={() => { navigate(`edit/${d.id}`) }} title='Editar' type="button" className="btn btn-primary"><FiEdit /></button>
                                    </div>
                                </td>
                            </tr>
                        ))
                    }

                </TableComponent>
            </div>

            <AddItemsChecklist getListData={getListData} id='modalAdd' />

        </>
    )
}

