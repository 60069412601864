

import { ModalDialog } from "../../components/modalComponent";
import TableComponent from "../../components/tableComponent";
import nullAbleImg from '../../assets/nuable_img.png'
import moment from "moment";

export default function ViewEmployees({ id, data, token }: any) {
    return (
        <ModalDialog modalSize={'lg'} id={id} title={`Detalhes do Funcionários #${data.id_employee}`} >

            <div style={{ border: '1px solid #cbdbdb', padding: 20 }}>
                <div className="row">
                    <div className="col-md-4">
                        <div className="col-md-6">
                            <div style={{ borderRadius: 10 }}>
                                <img style={{ borderRadius: 10 }} width={200} src={data.photo_path ? data.image_url + '/' + token : nullAbleImg} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-8">
                        <div className="mb-2">
                            <strong >Nome Funcionário:</strong> <br />
                            <span>{data.name}</span>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <strong >Tipo Funcionário:</strong> <br />
                                    <span>{data.type_employee}</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <strong >Documento:</strong> <br />
                                    <span>{data.document}</span>
                                </div>
                            </div>

                        </div>

                        <div className="row">

                            <div className="col-md-6">
                                <div className="mb-2">
                                    <strong >Telefone:</strong> <br />
                                    <span >{data.phone}</span>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="mb-2">
                                    <strong >Status:</strong> <br />
                                    <span className={`badge bg-label-${data.active == 1 ? 'success' : 'danger'} me-1`}>{data.active == 1 ? 'ATIVO' : 'INATIVO'}</span>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="mb-2">
                                <strong >Cadastro:</strong> <br />
                                <span>{moment(data.created_at).format('DD/MM/YYYY')}</span>
                            </div>
                        </div>

                        <span><strong>Descrição: </strong>  {data.observation}</span><br />


                    </div>

                </div>

            </div>

            <div className="mt-3">
                <div style={{ border: '1px solid #cbdbdb', padding: 20 }}>

                    <div style={{ top: 13, backgroundColor: '#ffffff', zIndex: 1000, paddingLeft: 10, paddingRight: 10 }}>
                        <strong>Veículos</strong>
                    </div>
                    <hr />

                    <TableComponent colums={['#', 'Marca', 'Modelo', 'Tipo', 'Placa']} size="sm">
                        {
                            data.vehicles &&
                            data.vehicles.map((d: any, k: any) => (
                                <tr key={k}>
                                    <td>{d.id}</td>
                                    <td>{d.brand}</td>
                                    <td>{d.model}</td>
                                    <td>{d.type}</td>
                                    <td>{d.plate}</td>
                                </tr>
                            ))
                        }
                    </TableComponent>

                </div>
            </div>

        </ModalDialog>
    );
}