import { useState } from 'react';
import { useForm } from "react-hook-form";
import { ModalDialog } from "../../components/modalComponent";
import { HUsers } from '../../hooks/Users';
import { ToastInfo } from '../../components/ToastInfo';
import Swal from 'sweetalert2';


interface IEditProfile {
    id: any
}

export default function EditProfile({ id }: IEditProfile) {

    const apiUser = HUsers();
    const [checkPass, setCheckPass] = useState<boolean>(false)

    const { handleSubmit, register, reset, formState: { errors } } = useForm<any>({
        defaultValues: {
            old_pass: '',
            new_pass: '',
            repet_pass: ''
        }
    })


    const saveNewPass = async (formData: any) => {


        if (formData.new_pass.length < 8) {
            Swal.fire({
                icon: "warning",
                title: 'Atenção!',
                text: 'Sua nova senha deve ter mais de que 8 caracteres',
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: 'Tentar novamente',


            });
            (window as any).$(`#${id}`).modal('hide')
            return false
        }

        if (formData.new_pass != formData.repet_pass) {
            setCheckPass(true);
            return false
        }
        setCheckPass(false);

        console.log(formData);

        await apiUser.alterPassword(formData).then(res => {
            ToastInfo(res);
            (window as any).$(`#${id}`).modal('hide')
        }).finally(() => { reset(); })
    }




    return (
        <ModalDialog id={id} title="Alterar senha" form={true} onClick={handleSubmit(saveNewPass)} buttonClose={[true, 'Cancelar']} buttonSend={[true, 'Alterar']}>
            <div className="mb-3">
                <label htmlFor="">Senha antiga:</label>
                <input type="password" {...register('old_pass', { required: true })} className="form-control" />
                {
                    errors.old_pass &&
                    <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                }
            </div>
            <div className="mb-3">
                <label htmlFor="">Nova senha:</label>
                <input type="password" {...register('new_pass', { required: true })} className="form-control" />
                {
                    errors.new_pass &&
                    <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                }
            </div>
            <div className="mb-3">
                <label htmlFor="">Comfirme a nova senha:</label>
                <input type="password" {...register('repet_pass', { required: true })} className="form-control" />
                {
                    errors.repet_pass &&
                    <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                }
                {checkPass && <span style={{ fontSize: 12, color: 'red' }}>Por favor verifique as senhas informada para alteração.</span>}
            </div>

        </ModalDialog>
    );
}