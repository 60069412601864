import { useContext } from "react";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import moment from "moment";
import EditProfile from "./edit_profile";

export default function Profile() {
    const auth = useContext(AuthContext);



    return (
        <div className=' card' style={{ padding: 30, }}>

            <h4>Perfil</h4>
            <br />
            <div>
                <h5 >Dados</h5>
                <span><strong>Nome:</strong>  {auth!.user?.name}</span> <br />
                <span><strong>E-mail:</strong>  {auth!.user?.email}</span> <br />
                <span><strong>Usuário</strong>  {auth!.user?.username}</span> <br />
                <span><strong>Cadastro</strong>  {moment(auth!.user?.created_at).format('lll')}</span> <br />
            </div>

            <hr />
            <div>
                <h5>Permições</h5>
                {
                    auth!.user?.roles?.map((d: any, k: any) => (
                        <div key={k}>
                            <span>{d.description}</span>
                            <br />
                        </div>
                    ))
                }

            </div>
            <hr />
            <div style={{ display: 'flex', justifyContent: 'end' }}>
                <button onClick={() => { (window as any).$('#modalEdit').modal('show') }} className="btn btn-primary">Altera senha</button>
            </div>

            <EditProfile id={'modalEdit'} />
        </div>
    );
}