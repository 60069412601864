import { useState, useEffect } from 'react';
import { ModalDialog } from "../../components/modalComponent";
import { FiPlus, FiSearch } from "react-icons/fi";
import TableComponent from "../../components/tableComponent";
import { WebcamComponent } from "../../components/WebcamComponent";
import { useForm } from 'react-hook-form';
import { ToastInfo } from '../../components/ToastInfo';
import { HVisitors } from '../../hooks/visitors';
import { useNavigate, useParams } from 'react-router-dom';
import nullAbleImg from '../../assets/nuable_img.png'
import { HVehicles } from '../../hooks/vehicles';
import { HRegisterCommons } from '../../hooks/RegisterCommons';

export default function EditVisitors() {

    const { id } = useParams();
    const hVisitors = HVisitors();
    const apiVehicles = HVehicles();
    const apiRegisterCommons = HRegisterCommons();
    const navigate = useNavigate();
    const token = localStorage.getItem('T');
    const [firstVisitor, setFirstVisitor] = useState<any>([]);
    const [listDatahVehicles, setListDatahVehicles] = useState<any>();
    const [searchVehicle, setSearchVehicle] = useState<string>('');
    const [selectedVehicles, setSelectedVehicles] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [imgAddPhoto, setImgAddPhoto] = useState<string | null>(null);

    const { handleSubmit, register, formState: { errors } } = useForm({
        defaultValues: {
            name: firstVisitor.name ?? '',
            document: firstVisitor.document ?? '',
            phone: firstVisitor.phone ?? '',
            image: firstVisitor.image ?? '',
            company: firstVisitor.company ?? '',
            observation: firstVisitor.observation ? firstVisitor.observation : '',
            active: firstVisitor.active ? 1 : 0,

        },
        values: {
            name: firstVisitor.name ?? '',
            document: firstVisitor.document ?? '',
            phone: firstVisitor.phone ?? '',
            image: firstVisitor.image ?? '',
            company: firstVisitor.company ?? '',
            observation: firstVisitor.observation ? firstVisitor.observation : '',
            active: firstVisitor.active ? 1 : 0,
        }
    })

    const handleTakePhoto = (photo: string) => {
        setImgAddPhoto(photo);
    }


    const getFirstVisitors = async () => {
        await hVisitors.index({ id: id }).then((res) => {
            setFirstVisitor(res.data[0]);
            const vehicleIds = res.data[0].vehicles.map((vehicle: any) => vehicle.id);
            setSelectedVehicles(vehicleIds);
        })
    }


    const getListDatahVehicles = async (numPage = 1, _search = searchVehicle) => {
        const params = {
            page: numPage,
            paginate: 10,
            search: _search,
            showActive: 1
        }
        await apiVehicles.index(params).then((res) => {
            setListDatahVehicles(res)
        }).finally(() => {
            setLoading(false)
        });
    }


    const edit = async (formData: any) => {
        formData.image = imgAddPhoto;
        formData.id = firstVisitor.id;
        try {
            await hVisitors.update(formData).then((res: any) => {
                ToastInfo(res);
                (window as any).$(`#${id}`).modal('hide');
                (window as any).$(`#modalWebcam`).modal('hide');
                getFirstVisitors();
            });
        } catch (error) {
            (window as any).$(`#${id}`).modal('hide');
        }
    }


    const addVehicle = async () => {
        const datasend = {
            id: firstVisitor.id,
            vehicles: selectedVehicles
        }
        await apiRegisterCommons.updateVehicles(datasend).then((res: any) => {
            ToastInfo(res)
        }).finally(() => {
            getFirstVisitors();
            (window as any).$('#addVeiculos').modal('hide');
        })
    }

    const handleCheckboxClick = (vehicleId: number, isChecked: boolean) => {
        // Se o checkbox está marcado e o ID do veículo não está na lista de selecionados, adicione-o.
        if (isChecked && !selectedVehicles.includes(vehicleId)) {
            setSelectedVehicles([...selectedVehicles, vehicleId]);
        }
        // Se o checkbox não está marcado e o ID do veículo está na lista de selecionados, remova-o.
        else if (!isChecked && selectedVehicles.includes(vehicleId)) {
            setSelectedVehicles(selectedVehicles.filter((id: any) => id !== vehicleId));
        }
    }



    useEffect(() => {
        getFirstVisitors();
        getListDatahVehicles();
    }, [])


    return (
        <div className=' card' style={{ padding: 30, }}>

            <div className='row'>

                <div className='col-md-4'>

                    <strong>Foto Atual #{firstVisitor.id_visitors}</strong>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                        {
                            firstVisitor.photo_path ? <img style={{ width: 250 }} src={firstVisitor.image_url + '/' + token} /> : <img style={{ width: 250 }} src={nullAbleImg} />
                        }
                    </div>
                    <br />
                    <WebcamComponent showPhoto={false} modalPhoto={true} onClick={handleSubmit(edit)} onTakePhoto={handleTakePhoto} />
                </div>
                <div className='col-md-8'>
                    <div className="">

                        <div className='row'>
                            <div className='col-md-6'>
                                <div className="mb-3">
                                    <label >Nome do Visitante:</label>
                                    <input {...register('name', { required: true })} name="name" type="text" className="form-control" />
                                    {
                                        errors.name &&
                                        <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                                    }
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="mb-3">
                                    <label >Documento	:</label>
                                    <input {...register('document', { required: true })} type="text" className="form-control" />
                                    {
                                        errors.document &&
                                        <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className="mb-3">
                                    <label >Telefone:</label>
                                    <input {...register('phone', { required: true })} className="form-control" placeholder="(00) 0 0000-0000" />
                                    {
                                        errors.phone &&
                                        <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                                    }
                                </div>
                            </div>
                            <div className='col-md-6'>

                                <div className="mb-3">
                                    <label >status:</label>
                                    <select className="form-control" {...register('active')} >
                                        <option value={1}>Ativo</option>
                                        <option value={0}>Inativo</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="mb-3">
                            <label >Empresa:</label>
                            <input {...register('company')} type="text" className="form-control" />

                        </div>

                        <div className="mb-3">
                            <label >Observação</label>
                            <textarea {...register('observation')} rows={2} className="form-control"></textarea>
                        </div>
                    </div>
                </div>
            </div>


            <hr />

            <div className="row">
                <div className="col-md-8" >
                    <strong>Veículos</strong>
                </div>
                <div className="col-md-4" style={{ display: 'flex', justifyContent: 'end' }}>
                    <button type='button' onClick={() => { (window as any).$('#addVeiculos').modal('show') }} className="btn btn-primary btn-sm" ><FiPlus /></button>
                </div>
            </div>

            <TableComponent colums={['#', 'Marca', 'Modelo', 'Tipo', 'Placa']} size="sm">

                {
                    firstVisitor.vehicles &&
                    firstVisitor.vehicles.map((d: any, k: any) => (
                        <tr key={k}>
                            <td>{d.id}</td>
                            <td>{d.brand}</td>
                            <td>{d.model}</td>
                            <td>{d.type}</td>
                            <td>{d.plate}</td>
                        </tr>
                    ))
                }

            </TableComponent>

            <hr />

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button onClick={() => { navigate('/visitors') }} className='btn btn-primary'>Voltar</button>
                <button onClick={handleSubmit(edit)} type='button' className='btn btn-primary'>Editar</button>
            </div>


            <ModalDialog id="addVeiculos" modalDialog="centered" onClick={addVehicle} buttonClose={[true, 'Cancelar']} buttonSend={[true, 'Atualizar']} title="Adicionar Veículos" >

                <div>
                    <div className="input-group mb-3">
                        <input type="text" value={searchVehicle} onChange={(e: any) => { setSearchVehicle(e.target.value) }} className="form-control" placeholder="Pesquisar..." />
                        <button onClick={() => getListDatahVehicles(1, searchVehicle)} type='button' className="btn btn-primary"><FiSearch /></button>
                    </div>
                </div>

                <TableComponent size='sm' PaginateFunction={getListDatahVehicles} PaginateListData={listDatahVehicles} colums={['selecione', '#', 'Marca', 'Modelo', 'Tipo', 'Placa']}>
                    {
                        listDatahVehicles &&
                        listDatahVehicles.data.map((d: any, k: any) => (
                            <tr key={k}>
                                <td>
                                    <div className="form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={`vehicle-checkbox-${d.id}`}
                                            onChange={(e) => handleCheckboxClick(d.id, e.target.checked)}
                                            checked={selectedVehicles.includes(d.id)}
                                        />

                                    </div>
                                </td>
                                <td>{d.id}</td>
                                <td>{d.brand}</td>
                                <td>{d.model}</td>
                                <td>{d.type}</td>
                                <td>{d.plate}</td>
                            </tr>
                        ))
                    }
                </TableComponent>
            </ModalDialog >

        </div >
    );
}

