import moment from "moment"
import CardSearchAndAdd from "../../../components/cardSearchAndAdd"
import StaffPage from "../../../components/staffPage"
import TableComponent from "../../../components/tableComponent"
import { IUsePassagensListOpen } from "../hooks/types/i-use-passagens-list-open"
import EditPassages from "../edit_passages"
import DeletePassages from "../delete_passages"
import { FiEdit, FiTrash2 } from "react-icons/fi"

import nullAbleImg from '../../../assets/nuable_img.png'
import SvgPassages from '../../../assets/svg/passages.svg'

interface IProps {
  hookPassagensListOpen: IUsePassagensListOpen
}

export default function PassagensComponentOpen({ hookPassagensListOpen }: IProps) {
  const {
    navigate,
    getListPassages,
    setSearch,
    perPageOnChange,
    listPassages,
    token,
    statusColors,
    elapsedTimes,
    firstListAllPassStatus,
    firstDataPassage,
    loading,
    search,
    perPage,
    setFirstDataPassage
  } = hookPassagensListOpen

  return (
    <>
      <StaffPage iconSvg={SvgPassages} description='Registre todas as passagens para manter um controle detalhado!' name='Passagens em aberto' />

      <CardSearchAndAdd add={() => { navigate(`store`) }} searchButton={() => { getListPassages(1, search, perPage) }} searchValue={search} searchChange={(e: any) => { setSearch(e.target.value) }} />

      <div className="card">
        <h5 className="card-header">Todas Passagens em aberto</h5>
        <TableComponent size='sm' loading={loading} perPageOnChange={perPageOnChange} perPageValue={perPage} PaginateFunction={getListPassages} PaginateListData={listPassages?.meta} colums={['#', 'Foto', 'Nome Completo', 'status', 'Chegada', 'Entrada', 'Tempo', 'Saída', 'Tipo', 'Documento', 'Ações',]} >
          {
            listPassages &&
            listPassages.data.map((d: any, k: any) => {

              return (
                <tr key={k}>
                  <td>{d.id}</td>

                  <td>
                    <span title={d.name} style={{ cursor: 'pointer' }} onClick={() => { (window as any).$('#viewImage').modal('show') }} >
                      {
                        d.registerCommon.photo_path ?
                          <img width={32} height={32} src={d.registerCommon.image_url + '/' + token} className="rounded-circle" />
                          : <img width={32} height={32} src={nullAbleImg} />
                      }
                    </span>
                  </td>

                  <td>{d.registerCommon.name}</td>

                  <td>
                    <span style={{ backgroundColor: statusColors[d.pass_status_id] || statusColors.default }} className='badge'>
                      {d.pass_status}
                    </span>
                  </td>

                  <td>{moment(d.created_at).format('DD/MM/YYYY HH:mm')}</td>
                  <td>{d.entry_time ? moment(d.entry_time).format('DD/MM/YYYY HH:mm') : '---'}</td>
                  <td>{elapsedTimes[d.id] || '---'}</td>
                  <td>{d.exit_time ? moment(d.exit_time).format('DD/MM/YYYY HH:mm') : '---'}</td>
                  <td>{d.registerCommon.types_people}</td>
                  <td>{d.registerCommon.document}</td>

                  <td>
                    <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                      <button onClick={() => { (window as any).$('#modalEdit').modal('show'); setFirstDataPassage(d) }} title='Editar' type="button" className="btn btn-primary"><FiEdit /></button>
                      <button onClick={() => { (window as any).$('#modalDelete').modal('show'); setFirstDataPassage(d) }} disabled={d.pass_status_id != 1 ? true : false} title='Depois de alterar o status desta passagem, a exclusão da mesma não será mais possível.' type="button" className="btn btn-primary"><FiTrash2 /></button>
                    </div>
                  </td>
                </tr>
              )
            })
          }

        </TableComponent>
      </div>

      <EditPassages getListPassages={getListPassages} firstListAllPassStatus={firstListAllPassStatus} firstDataPassage={firstDataPassage} token={token} id='modalEdit' />
      <DeletePassages getListPassages={getListPassages} firstDataPassage={firstDataPassage} id='modalDelete' />
    </>
  )
}

