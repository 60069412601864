import { useEffect, useState } from 'react';
import SvgInfractions from '../../assets/svg/infractions.svg'
import CardSearchAndAdd from '../../components/cardSearchAndAdd';
import StaffPage from '../../components/staffPage';
import TableComponent from '../../components/tableComponent';
import HInfractions from '../../hooks/Infractions';
import AddInfractions from './add_infractions';
import DeleteInfractions from './delete_infractions';
import EditInfractions from './edit_infractions';
import ViewInfractions from './view_infractions';
import { FiTrash2, FiEdit, FiFile } from "react-icons/fi";
import nullAbleImg from '../../assets/nuable_img.png'
import moment from 'moment';

export default function Infractions() {

  const apiInfractions = HInfractions();
  const token = localStorage.getItem('T');
  const [loading, setLoading] = useState<boolean>(true);
  const [infractions, setInfractions] = useState<any>([]);
  const [firstData, setFirstData] = useState<any>(null);
  const [search, setSearch] = useState<string>('');

  // função para pesquisar infrações
  const getInfractions = async (numPage = 1, _search = search) => {

    const params = {
      page: numPage,
      search: _search,
    }
    setLoading(true);
    await apiInfractions.index(params).then(resp => {
      setInfractions(resp);
    }).finally(() => {
      setLoading(false);
    })

  }
  // função para pesquisar infrações

  useEffect(() => {
    getInfractions();
  }, []);


  return (
    <>
      <StaffPage iconSvg={SvgInfractions} description='Mantenha a ordem e a segurança acompanhando e gerenciando infrações!' name='Infrações' />
      <CardSearchAndAdd add={() => { (window as any).$(`#modalAdd`).modal('show') }} searchButton={() => { getInfractions() }} searchValue={search} searchChange={(e: any) => { setSearch(e.target.value) }} />

      <div className="card">
        <h5 className="card-header">Todas Infrações</h5>
        <TableComponent PaginateFunction={getInfractions} PaginateListData={infractions} loading={loading} colums={['#', 'Responsável', 'DT Ocorrência', 'Descrição da Ocorrência', 'Notificado', 'Recebeu Multa', 'Multa Paga', 'Valor da Multa', 'Ações',]} >
          {
            infractions?.data?.map((d: any, k: number) => {
              return (
                <tr key={k}>
                  <td>{d.id}</td>
                  <td>
                    <span title={d.name} style={{ cursor: 'pointer' }} onClick={() => { (window as any).$('#viewImage').modal('show') }} >
                      {
                        d.photo_path ?
                          <img width={32} height={32} src={d.image_url + '/' + token} className="rounded-circle" />
                          : <img width={32} height={32} src={nullAbleImg} />
                      }
                    </span>
                  </td>
                  <td>{moment(d.date).format("DD/MM/YYYY")}</td>
                  <td>{d.occurrence_description}</td>
                  <td>
                    {
                      d.notified === '1' ? <span className="badge bg-label-success me-1">Sim</span>
                        :
                        <span className="badge bg-label-danger me-1">Não</span>
                    }
                  </td>
                  <td>
                    {
                      d.received_fine === '1'

                        ?

                        <span className="badge bg-label-success me-1">Sim</span>

                        :

                        <span className="badge bg-label-danger me-1">Não</span>
                    }
                  </td>
                  <td>
                    {
                      d.fine_paid === '1'

                        ?

                        <span className="badge bg-label-success me-1">Sim</span>

                        :

                        <span className="badge bg-label-danger me-1">Não</span>
                    }
                  </td>
                  <td>{d.amount_of_the_fine ? parseFloat(d.amount_of_the_fine).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '--'}</td>
                  <td>
                    <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                      <button onClick={() => { (window as any).$('#modalView').modal('show'); setFirstData(d) }} title='Visualizar' type="button" className="btn btn-primary"><FiFile /></button>
                      <button onClick={() => { (window as any).$('#modalEdit').modal('show'); setFirstData(d) }} title='Editar' type="button" className="btn btn-primary"><FiEdit /></button>
                      <button onClick={() => { (window as any).$('#modalDelete').modal('show'); setFirstData(d) }} title='Apagar' type="button" className="btn btn-primary"><FiTrash2 /></button>
                    </div>
                  </td>
                </tr>
              );
            })
          }
        </TableComponent>
      </div>

      <AddInfractions id='modalAdd' getInfractions={getInfractions} />
      <ViewInfractions token={token} id='modalView' data={firstData} />
      <EditInfractions id='modalEdit' data={firstData} getInfractions={getInfractions} />
      <DeleteInfractions id='modalDelete' data={firstData} getInfractions={getInfractions} />


    </>
  );
}