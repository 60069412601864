import React, { useState, useContext } from "react"
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import imgLogo from '../../assets/logempresa.png';
import Swal from "sweetalert2";

export default function Login() {

    const auth = useContext(AuthContext);

    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const handleLogin = async (event: any) => {
        event.preventDefault();


        if (!username || !password) {
            Swal.fire({
                title: "Atenção!!!",
                icon: "info",
                showConfirmButton: false,
                timer: 5000,
                text: "Preencha todos os campos",
                toast: true,
                position: 'top-right'
            })
            return false;
        }



        if (username && password) {

            setLoading(true);
            const isLogged = await auth!.login(username, password).finally(() => {
                setLoading(false);
            });


            if (isLogged) {

                navigate('/dashboard');

                Swal.fire({
                    toast: true,
                    icon: "success",
                    showConfirmButton: false,
                    timer: 3000,
                    title: 'Bem vindo(a)',
                    position: 'top-right',
                    timerProgressBar: true
                })

            }
        }

    }

    return (
        <div>
            <div className="container-xxl">
                <div className="authentication-wrapper authentication-basic container-p-y">
                    <div className="authentication-inner">
                        {/* <!-- Register --> */}
                        <div className="card">
                            <div className="card-body">
                                {/* <!-- Logo --> */}
                                <div className="app-brand justify-content-center">
                                    <img style={{ width: 250 }} src={imgLogo} alt="IMG" />
                                </div>
                                {/* <!-- /Logo --> */}
                                <h4 className="mb-2">Bem-vindo ao SafeEntry! 👋</h4>
                                <p className="mb-4">A chave para um controle de visitantes seguro e eficiente!</p>

                                <form >
                                    <div className="mb-3">
                                        <label className="form-label">Usuário</label>
                                        <input
                                            type="text"
                                            value={username}
                                            onChange={(e) => { setUsername(e.target.value) }}
                                            className="form-control"
                                            placeholder="Entre com seu nome de usuário"
                                        />
                                    </div>
                                    <div className="mb-3 form-password-toggle">
                                        <div className="d-flex justify-content-between">
                                            <label className="form-label" >Senha</label>

                                        </div>
                                        <div className="input-group input-group-merge">
                                            <input
                                                type="password"
                                                id="password"
                                                className="form-control"
                                                value={password}
                                                onChange={(e) => { setPassword(e.target.value) }}
                                                placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                                                aria-describedby="password"
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="mb-3">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="remember-me" />
                                            <label className="form-check-label" > Lembre de mim </label>
                                        </div>
                                    </div> */}
                                    <div className="mb-3">
                                        <button className="btn btn-primary d-grid w-100" onClick={(event) => handleLogin(event)} type="submit">Entrar</button>

                                    </div>
                                </form>

                            </div>
                        </div>
                        {/* <!-- /Register --> */}
                    </div>
                </div>
            </div>
        </div>
    )
}


