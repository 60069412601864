import Api from "../../services/api";

const HPassages = () => ({
  index: async (data: any) => {
    const response = await Api.get("/passages", {
      params: data,
    });
    return response.data;
  },
  listPassStatus: async () => {
    try {
      const response = await Api.get("/passages/list_pass_status");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  store: async (data: any) => {
    const response = await Api.post("/passages", data);
    return response.data;
  },
  update: async (data: any) => {
    try {
      const response = await Api.put(`/passages/${data.id}`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  delete: async (id: any) => {
    try {
      const response = await Api.delete(`/passages/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
});

export default HPassages;
