import { ModalDialog } from "../../components/modalComponent";
import teste from '../../assets/teste.jpg';
import TableComponent from "../../components/tableComponent";

export default function ViewResidents({ id, firstData, token, nullAbleImg }: any) {

    return (
        <ModalDialog modalSize={'xl'} id={id} title={`Detalhes do Morador #${firstData.id_resident}`} >
            <div className="row">
                <div className="col-md-12 mb-4"  >

                    <div style={{ border: '1px solid #cbdbdb', padding: 20 }}>

                        <div style={{ top: 13, backgroundColor: '#ffffff', zIndex: 1000, paddingLeft: 10, paddingRight: 10 }}>
                            <strong>Dados do Morador</strong>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-4">
                                <div className="col-md-6">
                                    <div style={{ borderRadius: 10 }}>
                                        <span style={{ cursor: 'pointer' }} onClick={() => { (window as any).$('#viewImage').modal('show') }} >
                                            {
                                                firstData.photo_path ?
                                                    <img style={{ borderRadius: 10, width: '200%' }} src={firstData.image_url + '/' + token} />
                                                    : <img width={160} src={nullAbleImg} />
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-2">
                                            <strong >Nome completo:</strong> <br />
                                            <span>{firstData.name}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">

                                        <div className="mb-2">
                                            <strong >Documento:</strong> <br />
                                            <span>{firstData.document}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-2">
                                            <strong >Status:</strong> <br />
                                            <td> <span className={`badge bg-label-${firstData.active == 1 ? 'success' : 'danger'} me-1`}>{firstData.active == 1 ? 'Ativo' : 'Inativo'}</span></td>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-2">
                                            <strong >Endereço residencial:</strong> <br />
                                            <span>{firstData.home_address}</span>
                                        </div>
                                    </div>
                                </div>


                                <div className="mb-2">
                                    <strong >Email:</strong> <br />
                                    <span>{firstData.email}</span>
                                </div>
                                <span><strong>Descrição: </strong>  {firstData.observation}</span><br />

                            </div>

                        </div>

                    </div>

                </div>
                <div className="col-md-12 mb-4">
                    <div style={{ border: '1px solid #cbdbdb', padding: 20 }}>

                        <div style={{ top: 13, backgroundColor: '#ffffff', zIndex: 1000, paddingLeft: 10, paddingRight: 10 }}>
                            <strong>Dependentes</strong>
                        </div>
                        <hr />

                        <TableComponent colums={['#', 'Foto', 'Nome', 'Grau de parentesco', 'Telef.']} size="sm">
                            {
                                firstData.dependents &&
                                firstData.dependents.map((d: any, k: any) => (
                                    <tr key={k}>
                                        <td>{d.id_dependent}</td>
                                        <td>
                                            <span style={{ cursor: 'pointer' }} onClick={() => { (window as any).$('#viewImage').modal('show') }} >
                                                {
                                                    d.photo_path ?
                                                        <img width={40} height={40} src={d.image_url + '/' + token} className="rounded-circle" />
                                                        : <img width={40} height={40} src={nullAbleImg} />
                                                }
                                            </span>
                                        </td>
                                        <td>{d.name}</td>
                                        <td>{d.type}</td>
                                        <td>{d.phone}</td>
                                    </tr>
                                ))
                            }
                        </TableComponent>

                    </div>
                </div>

                <div className="col-md-12 mb-4">
                    <div style={{ border: '1px solid #cbdbdb', padding: 20 }}>

                        <div style={{ top: 13, backgroundColor: '#ffffff', zIndex: 1000, paddingLeft: 10, paddingRight: 10 }}>
                            <strong>Funcionários</strong>
                        </div>

                        <hr />

                        <TableComponent colums={['#', 'FOTO', 'NOME FUNCIONÁRIo', 'TIPO FUNCIONÁRIO', 'TELEF.']} size="sm">
                            {
                                firstData.employees &&
                                firstData.employees.map((d: any, k: any) => (
                                    <tr key={k}>
                                        <td>{d.id_employee}</td>
                                        <td>
                                            <span style={{ cursor: 'pointer' }} onClick={() => { (window as any).$('#viewImage').modal('show') }} >
                                                {
                                                    d.photo_path ?
                                                        <img width={40} height={40} src={d.image_url + '/' + token} className="rounded-circle" />
                                                        : <img width={40} height={40} src={nullAbleImg} />
                                                }
                                            </span>
                                        </td>
                                        <td>
                                            {d.name}
                                        </td>
                                        <td>{d.type}</td>
                                        <td>{d.phone}</td>
                                    </tr>
                                ))
                            }

                        </TableComponent>

                    </div>
                </div>
                <div className="col-md-12">
                    <div style={{ border: '1px solid #cbdbdb', padding: 20 }}>

                        <div style={{ top: 13, backgroundColor: '#ffffff', zIndex: 1000, paddingLeft: 10, paddingRight: 10 }}>
                            <strong>Veículos</strong>
                        </div>

                        <hr />

                        <TableComponent colums={['#', 'Marca', 'Modelo', 'Tipo', 'Placa']} size="sm">

                            {
                                firstData.vehicles &&
                                firstData.vehicles.map((d: any, k: any) => (
                                    <tr key={k}>
                                        <td>{d.id}</td>
                                        <td>{d.brand}</td>
                                        <td>{d.model}</td>
                                        <td>{d.type}</td>
                                        <td>{d.plate}</td>
                                    </tr>
                                ))
                            }

                        </TableComponent>

                    </div>
                </div>
            </div>
        </ModalDialog>
    );
}