import { useNavigate } from 'react-router-dom';
import SvgEmployees from '../../assets/svg/employees.svg'
import CardSearchAndAdd from '../../components/cardSearchAndAdd';
import StaffPage from '../../components/staffPage';
import TableComponent from '../../components/tableComponent';
import AddEmployees from './add_employees';
import DeleteEmployees from './delete_employees';
import ViewEmployees from './view_employees';
import { FiTrash2, FiEdit, FiFile } from "react-icons/fi";
import { HEmployees } from '../../hooks/employees';
import { useEffect, useState } from 'react';
import nullAbleImg from '../../assets/nuable_img.png'
import moment from 'moment';

export default function Employees() {

  const navigate = useNavigate();
  const apiEmployees = HEmployees();
  const [listData, setListData] = useState<any>();
  const token = localStorage.getItem('T');
  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [FirstData, setFirstData] = useState<any>([]);

  const getListData = async (numPage = 1, _search = search) => {

    setLoading(true);
    const params = {
      page: numPage,
      paginate: 10,
      search: _search
    }

    await apiEmployees.index(params).then((res) => {
      setListData(res)
    }).finally(() => {
      setLoading(false)
    });

  }

  useEffect(() => {
    getListData();
  }, [])

  return (
    <>
      <StaffPage iconSvg={SvgEmployees} description='Gerencie seus funcionários de maneira eficaz e construa uma equipe confiável!' name='Funcionários' />

      <CardSearchAndAdd add={() => { (window as any).$(`#modalAdd`).modal('show') }} searchButton={() => { getListData() }} searchValue={search} searchChange={(e: any) => { setSearch(e.target.value) }} />

      <div className="card">
        <h5 className="card-header">Todos Funcionários</h5>
        <TableComponent loading={loading} PaginateFunction={getListData} PaginateListData={listData} colums={['#', 'Funcionário', 'Nome Funcionário', 'Tipo Funcionário', 'Documento', 'Telefone', 'Status', 'Cadastro', 'Ações',]} >
          {
            listData &&
            listData.data.map((d: any, k: any) => (
              <tr key={k}>
                <td>{d.id_employee}</td>
                <td>
                  <span style={{ cursor: 'pointer' }} onClick={() => { (window as any).$('#viewImage').modal('show') }} >
                    {
                      d.photo_path ?
                        <img width={40} height={40} src={d.image_url + '/' + token} className="rounded-circle" />
                        : <img width={40} height={40} src={nullAbleImg} />
                    }
                  </span>
                </td>
                <td>
                  <span> {d.name}</span>
                </td>
                <td>{d.type_employee}</td>
                <td>{d.document}</td>
                <td>{d.phone}</td>
                <td> <span className={`badge bg-label-${d.active == 1 ? 'success' : 'danger'} me-1`}>{d.active == 1 ? 'Ativo' : 'Inativo'}</span></td>
                <td>{moment(d.created_at).format("DD/MM/YYYY")}</td>
                <td>
                  <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                    <button onClick={() => { (window as any).$('#modalView').modal('show'); setFirstData(d) }} title='Visualizar' type="button" className="btn btn-primary"><FiFile /></button>
                    <button onClick={() => navigate(`edit/${d.id_employee}`)} title='Editar' type="button" className="btn btn-primary"><FiEdit /></button>
                  </div>
                </td>
              </tr>
            ))
          }
        </TableComponent>
      </div>

      <AddEmployees id='modalAdd' getListData={getListData} />
      <ViewEmployees id='modalView' data={FirstData} token={token} />
      <DeleteEmployees id='modalDelete' />


    </>
  );
}