
import moment from "moment";
import { ModalDialog } from "../../components/modalComponent";
import TableComponent from "../../components/tableComponent";
import nullAbleImg from '../../assets/nuable_img.png'

export default function ViewVehicles({ id, firstData }: any) {

    const token = localStorage.getItem('T');

    return (
        <ModalDialog modalSize={'lg'} id={id} title="Detalhes Veículo" >

            <div className="mb-3" style={{ border: '1px solid #cbdbdb', padding: 20 }}>
                <div style={{ top: 13, backgroundColor: '#ffffff', zIndex: 1000, paddingLeft: 10, paddingRight: 10 }}>
                    <strong>Dados do Veículo</strong>
                </div>
                <hr />

                <div className="row">
                    <div className="col-md-4">
                        <div className="mb-2">
                            <strong >Marca:</strong> <br />
                            <span>{firstData.brand}</span>
                        </div>
                    </div>
                    <div className="col-md-4">

                        <div className="mb-2">
                            <strong >Modelo:</strong> <br />
                            <span>{firstData.model}</span>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="mb-2">
                            <strong >Status:</strong> <br />
                            <td> <span className={`badge bg-label-${firstData.active == 1 ? 'success' : 'danger'} me-1`}>{firstData.active == 1 ? 'Ativo' : 'Inativo'}</span></td>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="mb-2">
                            <strong >Tipo:</strong> <br />
                            <span>{firstData.type}</span>
                        </div>
                    </div>
                    <div className="col-md-4">

                        <div className="mb-2">
                            <strong >Placa:</strong> <br />
                            <span>{firstData.plate}</span>
                        </div>
                    </div>
                    <div className="col-md-4">

                        <div className="mb-2">
                            <strong >Vaga Tipo:</strong> <br />
                            <span>{firstData.vacancy_type}</span>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="mb-2">
                            <strong >Cor Veículo:</strong> <br />
                            <span>{firstData.vehicle_color}</span>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="mb-2">
                            <strong >Cadstro:</strong> <br />
                            <span>{moment(firstData.created_at).format('DD/MM/YYYY')}</span>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="mb-2">
                            <strong >Ultima Atualização:</strong> <br />
                            <span>{moment(firstData.updated_at).format('DD/MM/YYYY')}</span>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-2">
                            <strong >Observação:</strong> <br />
                            <pre>{firstData.observation}</pre>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ border: '1px solid #cbdbdb', padding: 20 }}>
                <div style={{ top: 13, backgroundColor: '#ffffff', zIndex: 1000, paddingLeft: 10, paddingRight: 10 }}>
                    <strong>Veículo pertence</strong>
                </div>
                <hr />

                <TableComponent size="sm" colums={['#', 'Nome', 'tipo', 'telefone']}>

                    {
                        firstData.register_commons &&
                        firstData.register_commons.map((d: any, k: any) => (
                            <tr key={k}>
                                <td>{d.id}</td>
                                <td>

                                    <span style={{ cursor: 'pointer' }} onClick={() => { (window as any).$('#viewImage').modal('show') }} >
                                        {
                                            d.photo_path ?
                                                <img width={32} height={32} src={d.image_url + '/' + token} className="rounded-circle" />
                                                : <img width={32} height={32} src={nullAbleImg} />
                                        }
                                    </span>
                                    <span> {d.name}</span>
                                </td>

                                <td>{d.types_peoples_description}</td>
                                <td>{d.phone}</td>
                            </tr>

                        ))
                    }

                </TableComponent>

            </div>

        </ModalDialog>
    );
}