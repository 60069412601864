import InputMask from "react-input-mask";
import { ModalDialog } from "../../components/modalComponent";
import imgUser from '../../assets/svg/photo_session_re_c0cp.svg'
import { FiCamera, FiPlus, FiTrash2, FiSearch } from "react-icons/fi";
import TableComponent from "../../components/tableComponent";
import { useNavigate, useParams } from 'react-router-dom';
import nullAbleImg from '../../assets/nuable_img.png'
import { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { HResidents } from "../../hooks/Residents";
import { HVehicles } from "../../hooks/vehicles";
import { ToastInfo } from "../../components/ToastInfo";
import { WebcamComponent } from "../../components/WebcamComponent";
import { HRegisterCommons } from "../../hooks/RegisterCommons";
import { HDependents } from "../../hooks/dependents";
import { HEmployees } from "../../hooks/employees";

export default function EditResidents() {

    const { id } = useParams();
    const navigate = useNavigate();
    const apiResident = HResidents();
    const apiVehicles = HVehicles();
    const apiDependents = HDependents();
    const apiEmployees = HEmployees();
    const apiRegisterCommons = HRegisterCommons();
    const token = localStorage.getItem('T');
    const [firstResident, setFirstResident] = useState<any>([]);

    const [selectedVehicles, setSelectedVehicles] = useState<any>([]);
    const [selectedDependents, setSelectedDependents] = useState<any>([]);
    const [selectedEmployees, setSelectedEmployees] = useState<any>([]);

    const [listDatahVehicles, setListDatahVehicles] = useState<any>();
    const [listDataDependents, setListDataDependents] = useState<any>();
    const [listDataEmployees, setListDataEmployees] = useState<any>();

    const [searchVehicle, setSearchVehicle] = useState<string>('');
    const [searchDependents, setSearchDependents] = useState<string>('');
    const [searchEmployees, setSearchEmployees] = useState<string>('');

    const [loading, setLoading] = useState<boolean>(false);
    const [imgAddPhoto, setImgAddPhoto] = useState<string | null>(null);


    const { handleSubmit, register, formState: { errors } } = useForm({
        defaultValues: {
            name: firstResident.name ?? '',
            document: firstResident.document ?? '',
            phone: firstResident.phone ?? '',
            image: firstResident.image ?? '',
            observation: firstResident.observation ? firstResident.observation : '',
            home_address: firstResident.home_address ?? '',
            email: firstResident.email ?? '',
            active: firstResident.active ? 1 : 0,

        },
        values: {
            name: firstResident.name ?? '',
            document: firstResident.document ?? '',
            phone: firstResident.phone ?? '',
            image: firstResident.image ?? '',
            observation: firstResident.observation ? firstResident.observation : '',
            home_address: firstResident.home_address ?? '',
            email: firstResident.email ?? '',
            active: firstResident.active ? 1 : 0,
        }
    })


    const getFirsResident = async () => {
        await apiResident.index({ id: id }).then((res) => {
            setFirstResident(res.data[0]);

            const vehicleIds = res.data[0].vehicles.map((vehicle: any) => vehicle.id);
            setSelectedVehicles(vehicleIds);

            const dependentId = res.data[0].dependents.map((dependents: any) => dependents.id_dependent);
            setSelectedDependents(dependentId);

            const employeeId = res.data[0].employees.map((employees: any) => employees.id_employee);
            setSelectedEmployees(employeeId);
        })
    }


    const getListDatahVehicles = async (numPage = 1, _search = searchVehicle) => {
        const params = {
            page: numPage,
            paginate: 10,
            search: _search,
            showActive: 1
        }
        await apiVehicles.index(params).then((res) => {
            setListDatahVehicles(res)
        }).finally(() => {
            setLoading(false)
        });
    }

    const getListDataDependents = async (numPage = 1, _search = searchVehicle) => {
        const params = {
            page: numPage,
            paginate: 10,
            search: _search,
            showActive: 1
        }
        await apiDependents.index(params).then((res) => {
            setListDataDependents(res)

        }).finally(() => {
            setLoading(false)
        });
    }
    const getListDataEmployees = async (numPage = 1, _search = searchVehicle) => {
        const params = {
            page: numPage,
            paginate: 10,
            search: _search,
            showActive: 1
        }
        await apiEmployees.index(params).then((res) => {
            setListDataEmployees(res)

        }).finally(() => {
            setLoading(false)
        });
    }

    const handleTakePhoto = (photo: string) => {
        setImgAddPhoto(photo);
    }


    const edit = async (formData: any) => {
        formData.image = imgAddPhoto;
        formData.id = firstResident.id_registerCommon;

        try {
            await apiResident.update(formData).then((res: any) => {
                ToastInfo(res);
                (window as any).$(`#${id}`).modal('hide');
                (window as any).$(`#modalWebcam`).modal('hide');
                getFirsResident();
            });
        } catch (error) {
            (window as any).$(`#${id}`).modal('hide');
        }
    }

    const handleCheckboxClickVeicle = (vehicleId: number, isChecked: boolean) => {
        // Se o checkbox está marcado e o ID do veículo não está na lista de selecionados, adicione-o.
        if (isChecked && !selectedVehicles.includes(vehicleId)) {
            setSelectedVehicles([...selectedVehicles, vehicleId]);
        }
        // Se o checkbox não está marcado e o ID do veículo está na lista de selecionados, remova-o.
        else if (!isChecked && selectedVehicles.includes(vehicleId)) {
            setSelectedVehicles(selectedVehicles.filter((id: any) => id !== vehicleId));
        }
    }


    const handleCheckboxClickDependents = (dependentId: number, isChecked: boolean) => {
        // Se o checkbox está marcado e o ID do veículo não está na lista de selecionados, adicione-o.
        if (isChecked && !selectedDependents.includes(dependentId)) {
            setSelectedDependents([...selectedDependents, dependentId]);
        }
        // Se o checkbox não está marcado e o ID do veículo está na lista de selecionados, remova-o.
        else if (!isChecked && selectedDependents.includes(dependentId)) {
            setSelectedDependents(selectedDependents.filter((id: any) => id !== dependentId));
        }
    }

    const handleCheckboxClickEmployees = (employeeId: number, isChecked: boolean) => {
        // Se o checkbox está marcado e o ID do veículo não está na lista de selecionados, adicione-o.
        if (isChecked && !selectedEmployees.includes(employeeId)) {
            setSelectedEmployees([...selectedEmployees, employeeId]);
        }
        // Se o checkbox não está marcado e o ID do veículo está na lista de selecionados, remova-o.
        else if (!isChecked && selectedEmployees.includes(employeeId)) {
            setSelectedEmployees(selectedEmployees.filter((id: any) => id !== employeeId));
        }
    }


    const updateDeleteVehicle = async () => {
        const datasend = {
            id: firstResident.id_registerCommon,
            vehicles: selectedVehicles
        }
        await apiRegisterCommons.updateVehicles(datasend).then((res: any) => {
            ToastInfo(res);
            (window as any).$('#addDependentes').modal('hide');
        }).finally(() => {
            getFirsResident();
            (window as any).$('#addVeiculos').modal('hide');
        })
    }

    const updateDeleteDependents = async () => {
        const datasend = {
            id: firstResident.id_resident,
            dependent_ids: selectedDependents
        }
        await apiResident.updateDeleteDependentes(datasend).then((res: any) => {
            ToastInfo(res);
            (window as any).$('#addDependentes').modal('hide');
        }).finally(() => {
            getFirsResident();
            (window as any).$('#addDependentes').modal('hide');
        })
    }
    const updateDeleteEmployees = async () => {
        const datasend = {
            id: firstResident.id_resident,
            employees_ids: selectedEmployees
        }

        await apiResident.updateDeleteEmployees(datasend).then((res: any) => {
            ToastInfo(res);
            (window as any).$('#addFuncionarios').modal('hide');
        }).finally(() => {
            getFirsResident();
            (window as any).$('#addFuncionarios').modal('hide');
        })
    }


    useEffect(() => {
        getFirsResident();
        getListDatahVehicles();
        getListDataDependents();
        getListDataEmployees();
    }, [])


    return (
        <div className=' card' style={{ padding: 30, }}>

            <div className="row">
                <div className="col-md-4">


                    <strong>Foto Atual #{firstResident.id_registerCommon}</strong>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                        {
                            firstResident.photo_path ? <img style={{ width: 250 }} src={firstResident.image_url + '/' + token} /> : <img style={{ width: 250 }} src={nullAbleImg} />
                        }
                    </div>
                    <br />
                    <WebcamComponent showPhoto={false} modalPhoto={true} onClick={handleSubmit(edit)} onTakePhoto={handleTakePhoto} />


                </div>
                <div className="col-md-8">


                    <div className="mb-3">
                        <label >Nome completo:</label>
                        <input type="text" {...register('name')} className="form-control" placeholder="nome completo" />
                    </div>
                    <div className="mb-3">
                        <label >Documento</label>
                        <input type="text"  {...register('document')} className="form-control" />
                    </div>

                    <div className="mb-3">
                        <label >Endereço residencial</label>
                        <input type="text" {...register('home_address')} className="form-control" />
                    </div>

                    <div className="mb-3">
                        <label >status:</label>
                        <select className="form-control" {...register('active')} >
                            <option value={1}>Ativo</option>
                            <option value={0}>Inativo</option>
                        </select>
                    </div>

                    <div className="mb-3">
                        <label >Número de telefone</label>
                        <input type="text" {...register('phone')} className="form-control" />
                    </div>

                    <div className="mb-3">
                        <label >Email</label>
                        <input type="email" {...register('email')} className="form-control" />
                    </div>


                    <div className="mb-3">
                        <label >Observação</label>
                        <textarea {...register('observation')} rows={2} className="form-control"></textarea>
                    </div>
                </div>
            </div>

            <hr />
            <div className="row">
                <div className="col-md-8">
                    <strong>Dependentes</strong>
                </div>
                <div className="col-md-4" style={{ display: 'flex', justifyContent: 'end' }} >
                    <button onClick={() => { (window as any).$('#addDependentes').modal('show') }} className="btn btn-primary btn-sm" ><FiPlus /></button>
                </div>
            </div>

            <TableComponent colums={['#', 'Foto', 'Nome', 'Grau de parentesco', 'Telef.']} size="sm">
                {
                    firstResident.dependents &&
                    firstResident.dependents.map((d: any, k: any) => (
                        <tr key={k}>
                            <td>{d.id_dependent}</td>
                            <td>
                                <span style={{ cursor: 'pointer' }} onClick={() => { (window as any).$('#viewImage').modal('show') }} >
                                    {
                                        d.photo_path ?
                                            <img width={40} height={40} src={d.image_url + '/' + token} className="rounded-circle" />
                                            : <img width={40} height={40} src={nullAbleImg} />
                                    }
                                </span>
                            </td>
                            <td>{d.name}</td>
                            <td>{d.type}</td>
                            <td>{d.phone}</td>
                        </tr>
                    ))
                }
            </TableComponent>


            <hr />
            <div className="row">
                <div className="col-md-8">
                    <strong>Funcionários</strong>
                </div>
                <div className="col-md-4" style={{ display: 'flex', justifyContent: 'end' }} >
                    <button onClick={() => { (window as any).$('#addFuncionarios').modal('show') }} className="btn btn-primary btn-sm" ><FiPlus /></button>
                </div>
            </div>

            <TableComponent colums={['#', 'Foto', 'Nome', 'TIPO', 'Telef.']} size="sm">
                {
                    firstResident.employees &&
                    firstResident.employees.map((d: any, k: any) => (
                        <tr key={k}>
                            <td>{d.id_employee}</td>
                            <td>
                                <span style={{ cursor: 'pointer' }} onClick={() => { (window as any).$('#viewImage').modal('show') }} >
                                    {
                                        d.photo_path ?
                                            <img width={40} height={40} src={d.image_url + '/' + token} className="rounded-circle" />
                                            : <img width={40} height={40} src={nullAbleImg} />
                                    }
                                </span>
                            </td>
                            <td>
                                {d.name}
                            </td>
                            <td>{d.type}</td>
                            <td>{d.phone}</td>
                        </tr>
                    ))
                }

            </TableComponent>

            <hr />

            <div className="row">
                <div className="col-md-8" >
                    <strong>Veículos</strong>
                </div>
                <div className="col-md-4" style={{ display: 'flex', justifyContent: 'end' }}>
                    <button onClick={() => { (window as any).$('#addVeiculos').modal('show') }} className="btn btn-primary btn-sm" ><FiPlus /></button>
                </div>
            </div>

            <TableComponent colums={['#', 'Marca', 'Modelo', 'Tipo', 'Placa']} size="sm">

                {
                    firstResident.vehicles &&
                    firstResident.vehicles.map((d: any, k: any) => (
                        <tr key={k}>
                            <td>{d.id}</td>
                            <td>{d.brand}</td>
                            <td>{d.model}</td>
                            <td>{d.type}</td>
                            <td>{d.plate}</td>
                        </tr>
                    ))
                }


            </TableComponent>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button onClick={() => { navigate('/residents') }} className='btn btn-primary'>Voltar</button>
                <button onClick={handleSubmit(edit)} type='button' className='btn btn-primary'>Editar</button>
            </div>



            <ModalDialog id="addFuncionarios" modalDialog="centered" modalSize={'lg'} onClick={updateDeleteEmployees} buttonClose={[true, 'Cancelar']} buttonSend={[true, 'Atualizar']} title="Adiciona funcionários ao moradores"  >

                <div>
                    <div className="input-group mb-3">
                        <input type="text" value={searchEmployees} onChange={(e: any) => { setSearchEmployees(e.target.value) }} className="form-control" placeholder="Pesquisar..." />
                        <button onClick={() => getListDataEmployees(1, searchEmployees)} type='button' className="btn btn-primary"><FiSearch /></button>
                    </div>
                </div>

                <TableComponent PaginateFunction={getListDataEmployees} PaginateListData={listDataEmployees} colums={['selecione', '#', 'foto', 'Nome', 'tipo', 'Telef.']} size="sm">

                    {
                        listDataEmployees &&
                        listDataEmployees.data.map((d: any, k: any) => (
                            <tr key={k}>
                                <td>
                                    <div className="form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={`vehicle-checkbox-${d.id}`}
                                            onChange={(e) => handleCheckboxClickEmployees(d.id_employee, e.target.checked)}
                                            checked={selectedEmployees.includes(d.id_employee)}
                                        />

                                    </div>
                                </td>
                                <td>{d.id_employee}</td>
                                <td>
                                    <span style={{ cursor: 'pointer' }} onClick={() => { (window as any).$('#viewImage').modal('show') }} >
                                        {
                                            d.photo_path ?
                                                <img width={40} height={40} src={d.image_url + '/' + token} className="rounded-circle" />
                                                : <img width={40} height={40} src={nullAbleImg} />
                                        }
                                    </span></td>
                                <td>{d.name}</td>
                                <td>{d.type_employee}</td>
                                <td>{d.phone}</td>

                            </tr>
                        ))
                    }

                </TableComponent>


            </ModalDialog>


            <ModalDialog id="addDependentes" modalDialog="centered" modalSize={'lg'} onClick={updateDeleteDependents} buttonClose={[true, 'Cancelar']} buttonSend={[true, 'Atualizar']} title="Adiciona dependentes ao morador"  >

                <div>
                    <div className="input-group mb-3">
                        <input type="text" value={searchDependents} onChange={(e: any) => { setSearchDependents(e.target.value) }} className="form-control" placeholder="Pesquisar..." />
                        <button onClick={() => getListDataDependents(1, searchDependents)} type='button' className="btn btn-primary"><FiSearch /></button>
                    </div>
                </div>

                <TableComponent PaginateFunction={getListDataDependents} PaginateListData={listDataDependents} colums={['selecione', '#', 'foto', 'Nome', 'Grau de parentesco', 'Telef.']} size="sm">

                    {
                        listDataDependents &&
                        listDataDependents.data.map((d: any, k: any) => (
                            <tr key={k}>
                                <td>
                                    <div className="form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={`vehicle-checkbox-${d.id}`}
                                            onChange={(e) => handleCheckboxClickDependents(d.id_dependent, e.target.checked)}
                                            checked={selectedDependents.includes(d.id_dependent)}
                                        />

                                    </div>
                                </td>
                                <td>{d.id_dependent}</td>
                                <td>
                                    <span style={{ cursor: 'pointer' }} onClick={() => { (window as any).$('#viewImage').modal('show') }} >
                                        {
                                            d.photo_path ?
                                                <img width={40} height={40} src={d.image_url + '/' + token} className="rounded-circle" />
                                                : <img width={40} height={40} src={nullAbleImg} />
                                        }
                                    </span></td>
                                <td>{d.name}</td>
                                <td>{d.types_dependent}</td>
                                <td>{d.phone}</td>

                            </tr>
                        ))
                    }

                </TableComponent>


            </ModalDialog>

            <ModalDialog id="addVeiculos" modalDialog="centered" onClick={updateDeleteVehicle} buttonClose={[true, 'Cancelar']} buttonSend={[true, 'Atualizar']} title="Adicionar Veículos" >

                <div>
                    <div className="input-group mb-3">
                        <input type="text" value={searchVehicle} onChange={(e: any) => { setSearchVehicle(e.target.value) }} className="form-control" placeholder="Pesquisar..." />
                        <button onClick={() => getListDatahVehicles(1, searchVehicle)} type='button' className="btn btn-primary"><FiSearch /></button>
                    </div>
                </div>

                <TableComponent size='sm' PaginateFunction={getListDatahVehicles} PaginateListData={listDatahVehicles} colums={['selecione', '#', 'Marca', 'Modelo', 'Tipo', 'Placa']}>
                    {
                        listDatahVehicles &&
                        listDatahVehicles.data.map((d: any, k: any) => (
                            <tr key={k}>
                                <td>
                                    <div className="form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={`vehicle-checkbox-${d.id}`}
                                            onChange={(e) => handleCheckboxClickVeicle(d.id, e.target.checked)}
                                            checked={selectedVehicles.includes(d.id)}
                                        />

                                    </div>
                                </td>
                                <td>{d.id}</td>
                                <td>{d.brand}</td>
                                <td>{d.model}</td>
                                <td>{d.type}</td>
                                <td>{d.plate}</td>
                            </tr>
                        ))
                    }
                </TableComponent>
            </ModalDialog >




        </div >
    );
}