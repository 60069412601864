import loadingb from "../assets/svg/loadingBlack.svg";
import loadingw from "../assets/svg/loadingWhite.svg";
import loadingbolha from "../assets/svg/loadingBolha.svg";

interface ILoading {
    color?: any
    position?: any;
    top?: boolean;
    width?: any;
    height?: any;
}

export default function Loading({ color, top, width = 90, height = 90 }: ILoading) {
    return (
        <div style={{ position: "absolute", zIndex: 312312, display: "flex", justifyContent: "center", alignItems: "center", width: "100%", marginTop: top ? 32 : 0 }}>
            {
                color === 'black' ?
                    (<img src={loadingb} width={width} height={height} alt="loading" />) :
                    color === 'white' ?
                        (<img src={loadingw} width={width} height={height} alt="loading" />) :
                        (<img src={loadingbolha} width={width} height={height} alt="loading" />)

            }
        </div>

    )
}