import { useState } from 'react';
import { useContext } from "react";
import { ModalDialog } from "../../components/modalComponent";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import { useForm, Controller } from "react-hook-form";
import HOccurrenceBook from "../../hooks/occurrenceBook";
import { ToastInfo } from '../../components/ToastInfo';
import Swal from 'sweetalert2';


interface Item {
    quantity: number;
    discrepancy: number;
}

interface FormValues {
    items: Item[];
    post: any;
    start_of_shift: any;
    complete_description: any;
    conciergeItems: any;
}

interface IDeletePassages {
    id: any,
    getListPostItens?: any,
    listPosts?: any,
    listItemsPost?: any,
    getListData?: any
}


export default function AddOccurrenceBook({ id, getListPostItens, listPosts, listItemsPost, getListData }: IDeletePassages) {


    const apiOccurrenceBook = HOccurrenceBook();
    const auth = useContext(AuthContext);
    const [showDiscrepancy, setShowDiscrepancy] = useState(Array(listItemsPost.length).fill(false));
    const [checkIsNextBook, setCheckIsNextBook] = useState<any>(2);

    const { control, register, handleSubmit, reset, formState: { errors } } = useForm<FormValues>({
        defaultValues: {
            post: '',
            start_of_shift: '',
            complete_description: '',
            conciergeItems: '',
            items: listItemsPost.map(() => ({ quantity: 0, discrepancy: 0 }))  // Cria uma matriz de itens com valores iniciais 0 para quantidade e discrepância
        }
    })

    const store = async (data: FormValues) => {

        if (checkIsNextBook === 0 || checkIsNextBook === 2) {
            Swal.fire({
                toast: true,
                icon: "warning",
                title: "Atenção",
                text: "Para que possa abrir um novo livro de ata para este posto selecionado, o livro de ata atualmente aberto deve ser finalizado pelo responsável. Por favor, assegure-se de que isso aconteça.",
                showConfirmButton: false,
                showDenyButton: false,
                showCancelButton: false,
                position: 'top-right',
                timer: 4000
            });
            (window as any).$(`#${id}`).modal('hide');
            reset();
            setCheckIsNextBook(2)
            return false;
        }
        const conciergeItems = listItemsPost.map((item: any, i: any) => ({
            id: item.id,
            item_initial: item.amount,
            name: item.name,
            check_initial: data.items[i]?.quantity,
            check_description: data.items[i]?.discrepancy ?? ''
        }));

        const dataSend = {
            post: data.post,
            start_of_shift: data.start_of_shift,
            complete_description: data.complete_description,
            conciergeItems: conciergeItems.filter((item: any) => item.check_initial !== undefined)
        }

        await apiOccurrenceBook.store(dataSend).then((res) => {
            ToastInfo(res);
            reset();
            getListData();
            setCheckIsNextBook(2);
            (window as any).$(`#${id}`).modal('hide');

        })

    };


    const getChecksIfTheLastBookWasClosed = async (post_id: any) => {
        if (post_id === 'selecione...') {
            setCheckIsNextBook(2)
            return
        }
        apiOccurrenceBook.checksIfTheLastBookWasClosed(post_id).then((res) => {
            setCheckIsNextBook(res === 1 ? 1 : 0)
        })



    }

    const handleAmountChange = (index: number, value: string, amount: number) => {
        const newShowDiscrepancy = [...showDiscrepancy];
        newShowDiscrepancy[index] = Number(value) !== amount;
        setShowDiscrepancy(newShowDiscrepancy);
    }
    return (
        <ModalDialog id={id} modalSize={'lg'} form={true} onClick={handleSubmit(store)} title="Cadastrar nova ata" buttonClose={[true, 'Cancelar']} buttonSend={[true, 'Salvar']}>

            <div>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className="mb-3">
                            <label >Posto:</label>
                            <select {...register('post', { required: true })} onChange={(e: any) => { getListPostItens(e.target?.value); getChecksIfTheLastBookWasClosed(e.target?.value) }} className="form-control">
                                <option >selecione...</option>
                                {
                                    listPosts &&
                                    listPosts.map((d: any, k: any) => (
                                        <option key={k} value={d.id}> {d.name}</option>
                                    ))
                                }
                            </select>
                            {
                                errors.post &&
                                <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                            }
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label >Inicio do Turno:</label>
                            <input  {...register('start_of_shift', { required: true })} type="time" className="form-control" />
                        </div>
                        {
                            errors.start_of_shift &&
                            <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                        }
                    </div>
                </div>

                {
                    checkIsNextBook === 1 &&
                    <>
                        <div className="mb-3">
                            <label >Porteiro:</label>
                            <input type="text" value={auth!.user?.name} className="form-control" readOnly />
                            {
                                errors.post &&
                                <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                            }
                        </div>


                        <div className="mb-3">
                            <label >Descrição completa da ATA:</label>
                            <textarea {...register('complete_description', { required: true })} rows={7} className="form-control" />
                            {
                                errors.complete_description &&
                                <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                            }
                        </div>
                    </>
                }


            </div>

            {
                checkIsNextBook === 1 &&
                <div><hr />
                    <h6>Checklist Entrada</h6>
                    {
                        listItemsPost &&
                        listItemsPost.map((d: any, k: any) => (
                            <div className="row" key={k}>
                                <div className="col-md-4">
                                    <div className="form-check" >
                                        <label className="form-check-label" htmlFor="itens">
                                            • {d.name}
                                        </label>
                                    </div>
                                </div>


                                <div className="col-md-2 mb-1">
                                    <div style={{ marginLeft: 20 }}>
                                        <Controller
                                            control={control}
                                            name={`items.${k}.quantity`}
                                            rules={{ required: "Campo obrigatório" }}
                                            render={({ field }) => (
                                                <input
                                                    type="number"
                                                    placeholder={`Qtd: ${d.amount}`}
                                                    className="form-control form-control-sm"
                                                    value={field.value}
                                                    onChange={e => {
                                                        field.onChange(e.target.value);
                                                        handleAmountChange(k, e.target.value, d.amount);
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.items?.[k]?.quantity && <span style={{ fontSize: 10, color: 'red' }}>Informe Qtd.</span>}
                                    </div>
                                </div>


                                {showDiscrepancy[k] &&
                                    <div className="col-md-6">
                                        <div style={{ marginLeft: 20 }}>
                                            <Controller
                                                control={control}
                                                name={`items.${k}.discrepancy`}
                                                rules={{ required: showDiscrepancy[k] ? "Campo obrigatório" : false }}
                                                render={({ field }) => (
                                                    <input
                                                        type="text"
                                                        placeholder={`Informe a Divergência.`}
                                                        className="form-control form-control-sm"
                                                        value={field.value}
                                                        onChange={e => field.onChange(e.target.value)}
                                                    />
                                                )}
                                            />
                                            {errors.items?.[k]?.discrepancy && <span style={{ fontSize: 10, color: 'red' }}>Campo obrigatório</span>}
                                        </div>
                                    </div>
                                }



                            </div>
                        ))
                    }
                </div>
            }

            {
                checkIsNextBook === 0 &&
                <>
                    <div style={{ textAlign: "center" }}>
                        <br />
                        <h3>Atenção</h3>
                        <p style={{ color: '#caa600' }}>Para que possa abrir um novo livro de ata para este posto selecionado, o livro de ata atualmente aberto deve ser finalizado pelo responsável. Por favor, assegure-se de que isso aconteça.</p>
                    </div>
                </>
            }


        </ModalDialog >
    );
}