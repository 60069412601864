import Api from "../../services/api";

export const HRegisterCommons = () => ({


    index: async (data: any) => {
        const respose = await Api.get(`/register-commons/index`, { params: data });
        return respose.data;
    },

    updateVehicles: async (data: any) => {
        const respose = await Api.post(`/register-commons/${data.id}`, data);
        return respose.data;
    },

    registerCommons: async (type_people_id: number) => {
        const response = await Api.get(`/register-commons`, {
            params: {
                type_people_id
            }
        });

        return response.data;
    }

});

