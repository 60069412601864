
import { Box, Tab, Tabs, Typography } from '@mui/material';
import usePassagensListOpen from '../hooks/use-passagens-list-open';
import PassagensComponentOpen from '../components/passagens-component-open';
import PassagensComponentClosing from '../components/passagens-component-closing';
import usePassagensListClosing from '../hooks/use-passagens-list-closing';



export default function Passages() {

  //list
  const hookPassagensListOpen = usePassagensListOpen();
  const { handleChange, tabValue } = hookPassagensListOpen;

  //list
  const hookPassagensListClosing = usePassagensListClosing();

  return (
    <>
      <div className="card">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Passagens em Aberto" />
            <Tab label="Passagens em Finalizadas" />
          </Tabs>
        </Box>
        <CustomTabPanel value={tabValue} index={0}>
          <PassagensComponentOpen hookPassagensListOpen={hookPassagensListOpen} />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <PassagensComponentClosing hookPassagensListClosing={hookPassagensListClosing} />
        </CustomTabPanel>
      </div>
    </>
  );

}


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}


function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}