import convertSaveImage from "../../components/ImageUploader";
import Api from "../../services/api";




export const HVisitors = () => ({
    index: async (data: any) => {
        const response = await Api.get('/visitors', { params: data });
        return response.data;
    },
    store: async (data: any) => {

        try {
            const formData = new FormData();

            // Adicionar campos de formulário ao objeto FormData
            formData.append('name', data.name);
            formData.append('document', data.document);
            formData.append('phone', data.phone);
            formData.append('company', data.company);
            formData.append('observation', data.observation);

            var sendFormData = await convertSaveImage(data, formData);

            const response = await Api.post('/visitors', sendFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Definir o tipo de conteúdo como multipart/form-data
                },
            });

            return response.data;
        } catch (error) {
            console.error('Erro ao cadastrar visitante:', error);
            throw error;
        }
    },
    update: async (data: any) => {

        try {
            const formData = new FormData();

            // Adicionar campos de formulário ao objeto FormData
            formData.append('name', data.name);
            formData.append('document', data.document);
            formData.append('phone', data.phone);
            formData.append('company', data.company);
            formData.append('active', data.active);
            formData.append('observation', data.observation);

            var sendFormData = await convertSaveImage(data, formData);

            const response = await Api.post(`/visitors/update/${data.id}`, sendFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Definir o tipo de conteúdo como multipart/form-data
                },
            });

            return response.data;


        } catch (error) {
            console.error('Erro ao editar visitante:', error);
            throw error;
        }
    },
});

