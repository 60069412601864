import { useForm } from "react-hook-form";
import { ModalDialog } from "../../components/modalComponent";
import { useState } from 'react';
import { HUsers } from "../../hooks/Users";
import { ToastInfo } from "../../components/ToastInfo";

export default function AddUsers({ id, roles, listUsers }: any) {
    const apiUser = HUsers();
    const [checkboxValues, setCheckboxValues] = useState<Array<string>>([]);


    const { handleSubmit, register, reset, formState: { errors } } = useForm({
        defaultValues: {
            name: '',
            email: '',
            username: '',
            password: '',
            roles: []
        }
    });

    const addUser = async (formData: any) => {
        formData.roles = checkboxValues;
        try {
            await apiUser.store(formData).then((res) => {
                ToastInfo(res)
                reset();
                (window as any).$(`#${id}`).modal('hide');
                listUsers();
            });
        } catch (error: unknown) {
            (window as any).$(`#${id}`).modal('hide');
        }
    };


    return (
        <ModalDialog id={id} title="Cadastrar novo usuário" form={true} onClick={handleSubmit(addUser)} buttonClose={[true, 'Cancelar']} buttonSend={[true, 'Salvar']} >

            <div className="mb-3">
                <label >Nome:</label>
                <input type="text" {...register('name', { required: true })} className="form-control" placeholder="nome" />
                {
                    errors.name &&
                    <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                }
            </div>

            <div className="mb-3">
                <label >E-mail:</label>
                <input type="email" {...register('email', { required: true })} className="form-control" placeholder="exemple@email.com" />
                {
                    errors.email &&
                    <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                }
            </div>

            <div className="mb-3">
                <label >Usuário:</label>
                <input type="text" {...register('username', { required: true })} className="form-control" placeholder="user.nome" />
                {
                    errors.username &&
                    <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                }
            </div>

            <div className="mb-3">
                <label>Senha:</label>
                <input
                    type="password"
                    {...register('password', {
                        required: "Campo obrigatório",
                        minLength: {
                            value: 8,
                            message: "A senha deve ter pelo menos 8 caracteres",
                        }
                    })}
                    className="form-control"
                    placeholder="**********"
                />
                {
                    errors.password &&
                    <span style={{ fontSize: 12, color: 'red' }}>{errors.password.message}</span>
                }
            </div>

            <hr />

            <h5>Selecione as permissão do usuário</h5>
            {
                roles &&
                roles.map((d: any, k: any) => (
                    <div key={k} className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id={k}
                            checked={checkboxValues.includes(d.id)}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setCheckboxValues([...checkboxValues, d.id]);
                                } else {
                                    setCheckboxValues(checkboxValues.filter(id => id !== d.id));
                                }
                            }}
                        />
                        <label className="form-check-label" htmlFor={k}>{d.description}</label>
                    </div>
                ))
            }


        </ModalDialog>

    );
}