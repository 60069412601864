import { Link, useLocation } from "react-router-dom";

interface INaveg {
    name?: string;
    Icon?: any;
    link?: string;
}

export default function SimpleNavigation({ name, Icon, link }: INaveg) {
    const location = useLocation();
    const isActive = location.pathname.includes(link || '');
    
    return (
        <li className={`menu-item ${isActive ? 'active' : ''}`}>
            <Link to={`${link}`} className="menu-link">
                <i className={`menu-icon tf-icons bx bx-${Icon}`}></i>
                <div data-i18n="Analytics">{name}</div>
            </Link>
        </li>
    )
}
