import { ModalDialog } from "../../components/modalComponent";

import imgUser from '../../assets/svg/photo_session_re_c0cp.svg'
import InputMask from 'react-input-mask';
import TableComponent from "../../components/tableComponent";
import { FiCamera, FiPlus, FiTrash2, FiSearch } from "react-icons/fi";
import { WebcamComponent } from "../../components/WebcamComponent";
import { useNavigate, useParams } from "react-router-dom";
import { HResidents } from "../../hooks/Residents";
import { HVehicles } from "../../hooks/vehicles";
import { HDependents } from "../../hooks/dependents";
import { HRegisterCommons } from "../../hooks/RegisterCommons";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ToastInfo } from "../../components/ToastInfo";
import nullAbleImg from '../../assets/nuable_img.png'

export default function EditDependents() {

    const { id } = useParams();
    const navigate = useNavigate();
    const apiVehicles = HVehicles();
    const apiDependents = HDependents();
    const apiRegisterCommons = HRegisterCommons();
    const token = localStorage.getItem('T');
    const [firstDependent, setFirstDependent] = useState<any>([]);
    const [typesDependents, setTypesDependents] = useState<any>([]);
    const [selectedVehicles, setSelectedVehicles] = useState<any>([]);
    const [listDatahVehicles, setListDatahVehicles] = useState<any>();
    const [searchVehicle, setSearchVehicle] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [imgAddPhoto, setImgAddPhoto] = useState<string | null>(null);

    const { handleSubmit, register, formState: { errors } } = useForm({
        defaultValues: {
            name: firstDependent.name,
            document: firstDependent.document,
            phone: firstDependent.phone,
            image: firstDependent.image,
            observation: firstDependent.observation ? firstDependent.observation : '',
            type_dependent_id: firstDependent.type_dependent_id,
            active: firstDependent.active ? 1 : 0,

        },
        values: {
            name: firstDependent.name,
            document: firstDependent.document,
            phone: firstDependent.phone,
            image: firstDependent.image,
            observation: firstDependent.observation ? firstDependent.observation : '',
            type_dependent_id: firstDependent.type_dependent_id,
            active: firstDependent.active ? 1 : 0,
        }
    })

    const handleTakePhoto = (photo: string) => {
        setImgAddPhoto(photo);
    }

    const getFirsDependents = async () => {
        await apiDependents.index({ id: id }).then((res) => {
            setFirstDependent(res.data[0]);

            const vehicleIds = res.data[0].vehicles.map((vehicle: any) => vehicle.id);
            setSelectedVehicles(vehicleIds);

        })
    }

    const getListDatahVehicles = async (numPage = 1, _search = searchVehicle) => {
        const params = {
            page: numPage,
            paginate: 10,
            search: _search,
            showActive: 1
        }
        await apiVehicles.index(params).then((res) => {
            setListDatahVehicles(res)
        }).finally(() => {
            setLoading(false)
        });
    }
    const getTypesDependents = async () => {
        await apiDependents.TypesDependents().then((res) => {
            setTypesDependents(res)
        }).finally(() => {
            setLoading(false)
        });
    }

    const edit = async (formData: any) => {
        formData.image = imgAddPhoto;
        formData.id = firstDependent.id_register_commons;
        try {
            await apiDependents.update(formData).then((res: any) => {
                ToastInfo(res);
                (window as any).$(`#${id}`).modal('hide');
                (window as any).$(`#modalWebcam`).modal('hide');
                getFirsDependents();
            });
        } catch (error) {
            (window as any).$(`#${id}`).modal('hide');
        }
    }


    const handleCheckboxClickVeicle = (vehicleId: number, isChecked: boolean) => {
        // Se o checkbox está marcado e o ID do veículo não está na lista de selecionados, adicione-o.
        if (isChecked && !selectedVehicles.includes(vehicleId)) {
            setSelectedVehicles([...selectedVehicles, vehicleId]);
        }
        // Se o checkbox não está marcado e o ID do veículo está na lista de selecionados, remova-o.
        else if (!isChecked && selectedVehicles.includes(vehicleId)) {
            setSelectedVehicles(selectedVehicles.filter((id: any) => id !== vehicleId));
        }
    }


    const updateDeleteVehicle = async () => {
        const datasend = {
            id: firstDependent.id_register_commons,
            vehicles: selectedVehicles
        }
        await apiRegisterCommons.updateVehicles(datasend).then((res: any) => {
            ToastInfo(res)
        }).finally(() => {
            getFirsDependents();
            (window as any).$('#addVeiculos').modal('hide');
        })
    }


    useEffect(() => {
        getFirsDependents();
        getListDatahVehicles();
        getTypesDependents();
    }, [])

    return (
        <div className=' card' style={{ padding: 30, }}>

            <div className="row ">
                <div className="col-md-4">
                    <strong>Foto Atual #{firstDependent.id_dependent}</strong>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                        {
                            firstDependent.photo_path ? <img style={{ width: 250 }} src={firstDependent.image_url + '/' + token} /> : <img style={{ width: 250 }} src={nullAbleImg} />
                        }
                    </div>
                    <br />
                    <WebcamComponent showPhoto={false} modalPhoto={true} onClick={handleSubmit(edit)} onTakePhoto={handleTakePhoto} />
                </div>
                <div className="col-md-8">
                    <div className="">
                        <div className="mb-3">
                            <label >Nome completo Dependente:</label>
                            <input {...register('name', { required: true })} type="text" className="form-control" />
                        </div>

                        <div className="mb-3">
                            <label >Tipo de Dependente:</label>
                            <select {...register('type_dependent_id', { required: true })} className="form-control" >

                                <option selected disabled value={firstDependent.type_dependent_id}>{firstDependent.types_dependent}</option>
                                {
                                    typesDependents &&
                                    typesDependents.map((d: any, k: any) => (
                                        <option key={k} value={d.id}>{d.description}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="mb-3">
                            <label >Documento:</label>
                            <input type="text" {...register('document')} className="form-control" />
                        </div>
                        <div className="mb-3">
                            <label >Telefone:</label>
                            <InputMask  {...register('phone')} className="form-control" mask="(99) 9 9999-9999" />
                        </div>

                        <div className="mb-3">
                            <label >status:</label>
                            <select className="form-control" {...register('active')} >
                                <option value={1}>Ativo</option>
                                <option value={0}>Inativo</option>
                            </select>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label >Observação</label>
                        <textarea {...register('observation')} rows={2} className="form-control"></textarea>
                    </div>

                    <hr />
                    <div className="row">
                        <div className="col-md-8" >
                            <strong>Veículos do Dependente</strong>
                        </div>
                        <div className="col-md-4" style={{ display: 'flex', justifyContent: 'end' }}>
                            <button onClick={() => { (window as any).$('#addVeiculos').modal('show') }} className="btn btn-primary btn-sm" ><FiPlus /></button>
                        </div>
                    </div>
                </div>
            </div>


            <TableComponent colums={['#', 'Marca', 'Modelo', 'Tipo', 'Placa']} size="sm">
                {
                    firstDependent.vehicles &&
                    firstDependent.vehicles.map((d: any, k: any) => (
                        <tr key={k}>
                            <td>{d.id}</td>
                            <td>{d.brand}</td>
                            <td>{d.model}</td>
                            <td>{d.type}</td>
                            <td>{d.plate}</td>
                        </tr>
                    ))
                }
            </TableComponent>


            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button onClick={() => { navigate('/dependents') }} className='btn btn-primary'>Voltar</button>
                <button onClick={handleSubmit(edit)} type='button' className='btn btn-primary'>Editar</button>
            </div>


            <ModalDialog id="addVeiculos" modalDialog="centered" onClick={updateDeleteVehicle} buttonClose={[true, 'Cancelar']} buttonSend={[true, 'Atualizar']} title="Adicionar Veículos" >

                <div>
                    <div className="input-group mb-3">
                        <input type="text" value={searchVehicle} onChange={(e: any) => { setSearchVehicle(e.target.value) }} className="form-control" placeholder="Pesquisar..." />
                        <button onClick={() => getListDatahVehicles(1, searchVehicle)} type='button' className="btn btn-primary"><FiSearch /></button>
                    </div>
                </div>

                <TableComponent size='sm' PaginateFunction={getListDatahVehicles} PaginateListData={listDatahVehicles} colums={['selecione', '#', 'Marca', 'Modelo', 'Tipo', 'Placa']}>
                    {
                        listDatahVehicles &&
                        listDatahVehicles.data.map((d: any, k: any) => (
                            <tr key={k}>
                                <td>
                                    <div className="form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={`vehicle-checkbox-${d.id}`}
                                            onChange={(e) => handleCheckboxClickVeicle(d.id, e.target.checked)}
                                            checked={selectedVehicles.includes(d.id)}
                                        />

                                    </div>
                                </td>
                                <td>{d.id}</td>
                                <td>{d.brand}</td>
                                <td>{d.model}</td>
                                <td>{d.type}</td>
                                <td>{d.plate}</td>
                            </tr>
                        ))
                    }
                </TableComponent>
            </ModalDialog >


        </div>
    );
}