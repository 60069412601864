import Api from "../../services/api";

const HInfractions = () => ({

    index: async (data: any) => {

        try {
            const response = await Api.get('/infractions', {
                params: data
            });
            return response.data;
        } catch (error) {
            throw error;
        }

    },

    store: async (data: any) => {

        try {
            const response = await Api.post('/infractions', data);
            return response.data;
        } catch (error) {
            throw error
        }

    },

    update: async (id: number, data: any) => {
        try {
            const response = await Api.put(`/infractions/${id}`, data);
            return response.data;
        } catch (error) {
            throw error
        }
    },

    delete: async (id: number) => {
        try {
            const response = await Api.delete(`/infractions/${id}`);
            return response.data;
        } catch (error) {
            throw error
        }
    },

})

export default HInfractions;