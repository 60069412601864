
import { useState, useEffect } from 'react'
import { ModalDialog } from "../../components/modalComponent";
import nullAbleImg from '../../assets/nuable_img.png'
import HPassages from "../../hooks/passages";
import { useForm } from "react-hook-form";
import { ToastInfo } from "../../components/ToastInfo";
import moment from "moment";


interface IEditPassages {
    id?: any;
    firstDataPassage?: any;
    token?: any;
    firstListAllPassStatus?: any;
    getListPassages: (numPage?: number, _search?: string, _perPage?: any) => Promise<void>
}

export default function EditPassages({ id, firstDataPassage, token, firstListAllPassStatus, getListPassages }: IEditPassages) {

    const apiPassages = HPassages();

    const { handleSubmit, register, setValue, formState: { errors } } = useForm({
        defaultValues: {
            pass_status_id: firstDataPassage?.pass_status_id,
            entry_time: firstDataPassage?.entry_time,
            exit_time: firstDataPassage?.exit_time,
            description: firstDataPassage?.description ?? '',
        },
        values: {
            pass_status_id: firstDataPassage?.pass_status_id,
            entry_time: firstDataPassage?.entry_time,
            exit_time: firstDataPassage?.exit_time,
            description: firstDataPassage?.description ?? '',
        }
    })

    const update = async (formData: any) => {
        formData.id = firstDataPassage.id
        await apiPassages.update(formData).then((res) => {
            ToastInfo(res);
            (window as any).$(`#${id}`).modal('hide');
            getListPassages();
        }).catch((error) => {
        })
    }

    const setTimePass = (e: React.ChangeEvent<HTMLSelectElement>) => {
        var valuesStatus = e.target.value;
        var dateTime = moment().format('YYYY-MM-DDTHH:mm:ss')
        if (valuesStatus === '3') {

            setValue('entry_time', dateTime) // update field value
            setValue('exit_time', '') // update field value
        }
        if (valuesStatus === '4') {

            setValue('exit_time', dateTime) // update field value
        }
        if (valuesStatus === '1' || valuesStatus === '2' || valuesStatus === '5' || valuesStatus === '6') {
            setValue('entry_time', '') // update field value
            setValue('exit_time', '') // update field value
        }
    }


    useEffect(() => {
    }, [])

    return (
        <ModalDialog modalSize={'xl'} id={id} title={`Editar Passagem #${firstDataPassage.id}`} onClick={handleSubmit(update)} buttonClose={[true, 'Cancelar']} buttonSend={[true, 'Editar']}>
            <div>
                <h4>Realizando a passagem</h4>

                <div className="row">
                    <div className="col-md-2">
                        <span style={{ cursor: 'pointer' }} onClick={() => { (window as any).$('#viewImage').modal('show') }} >
                            {
                                firstDataPassage?.registerCommon?.photo_path ?
                                    <img style={{ borderRadius: 10, width: 120 }} src={firstDataPassage?.registerCommon.image_url + '/' + token} />
                                    : <img width={110} src={nullAbleImg} />
                            }
                        </span>
                    </div>
                    <div className="col-md-10">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="mb-2">
                                    <strong >Nome completo</strong> <br />
                                    <span>{firstDataPassage?.registerCommon?.name}</span>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-2">
                                    <strong >Documento</strong> <br />
                                    <span>{firstDataPassage?.registerCommon?.document}</span>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-2">
                                    <strong >Telefone</strong> <br />
                                    <span>{firstDataPassage?.registerCommon?.phone}</span>
                                </div>
                            </div>
                        </div>



                        <div className="row">

                            <div className="col-md-4">
                                <div className="mb-2">
                                    <strong >Tipo</strong> <br />
                                    <span>{firstDataPassage?.registerCommon?.types_people}</span>
                                </div>
                            </div>

                            {
                                firstDataPassage?.registerCommon?.visitors &&
                                <div className="col-md-4">
                                    <div className="mb-2">
                                        <strong >Empresa</strong> <br />
                                        <span>{firstDataPassage?.registerCommon?.visitors.company}</span>
                                    </div>
                                </div>
                            }
                            {
                                firstDataPassage?.registerCommon?.residents &&
                                <div className="col-md-4">
                                    <div className="mb-2">
                                        <strong >Email</strong> <br />
                                        <span>{firstDataPassage?.registerCommon?.residents.email}</span>
                                    </div>
                                </div>
                            }
                            {
                                firstDataPassage?.registerCommon?.residents &&
                                <div className="col-md-4">
                                    <div className="mb-2">
                                        <strong >Endereço</strong> <br />
                                        <span>{firstDataPassage?.registerCommon?.residents.home_address}</span>
                                    </div>
                                </div>
                            }

                            {
                                firstDataPassage?.dependents &&
                                <div className="col-md-4">
                                    <div className="mb-2">
                                        <strong >Tipo</strong> <br />
                                        <span>{firstDataPassage?.dependents.type}</span>
                                    </div>
                                </div>
                            }


                            {
                                firstDataPassage?.employee &&
                                <div className="col-md-4">
                                    <div className="mb-2">
                                        <strong >Tipo Funcionário</strong> <br />
                                        <span>{firstDataPassage?.employee.type}</span>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </div>

            </div>
            <br />
            <hr />
            <div>
                <h4>Responsável por essa passagem</h4>

                <div className="row">
                    <div className="col-md-2">
                        <span style={{ cursor: 'pointer' }} onClick={() => { (window as any).$('#viewImage').modal('show') }} >
                            {
                                firstDataPassage?.responsibleTheVisit?.photo_path ?
                                    <img style={{ borderRadius: 10, width: 120 }} src={firstDataPassage?.responsibleTheVisit.image_url + '/' + token} />
                                    : <img width={110} src={nullAbleImg} />
                            }
                        </span>
                    </div>
                    <div className="col-md-10">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="mb-2">
                                    <strong >Nome completo</strong> <br />
                                    <span>{firstDataPassage?.responsibleTheVisit?.name}</span>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-2">
                                    <strong >Documento</strong> <br />
                                    <span>{firstDataPassage?.responsibleTheVisit?.document}</span>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-2">
                                    <strong >Telefone</strong> <br />
                                    <span>{firstDataPassage?.responsibleTheVisit?.phone}</span>
                                </div>
                            </div>
                        </div>

                        <div className="row">

                            <div className="col-md-4">
                                <div className="mb-2">
                                    <strong >Tipo</strong> <br />
                                    <span>{firstDataPassage?.responsibleTheVisit?.types_people}</span>
                                </div>
                            </div>

                            {
                                firstDataPassage?.responsibleTheVisit?.visitors &&
                                <div className="col-md-4">
                                    <div className="mb-2">
                                        <strong >Empresa</strong> <br />
                                        <span>{firstDataPassage?.responsibleTheVisit?.visitors.company}</span>
                                    </div>
                                </div>
                            }
                            {
                                firstDataPassage?.responsibleTheVisit?.residents &&
                                <div className="col-md-4">
                                    <div className="mb-2">
                                        <strong >Email</strong> <br />
                                        <span>{firstDataPassage?.responsibleTheVisit?.residents.email}</span>
                                    </div>
                                </div>
                            }
                            {
                                firstDataPassage?.responsibleTheVisit?.residents &&
                                <div className="col-md-4">
                                    <div className="mb-2">
                                        <strong >Endereço</strong> <br />
                                        <span>{firstDataPassage?.responsibleTheVisit?.residents.home_address}</span>
                                    </div>
                                </div>
                            }

                            {
                                firstDataPassage?.dependents &&
                                <div className="col-md-4">
                                    <div className="mb-2">
                                        <strong >Tipo</strong> <br />
                                        <span>{firstDataPassage?.dependents.type}</span>
                                    </div>
                                </div>
                            }


                            {
                                firstDataPassage?.employee &&
                                <div className="col-md-4">
                                    <div className="mb-2">
                                        <strong >Tipo Funcionário</strong> <br />
                                        <span>{firstDataPassage?.employee.type}</span>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </div>



            </div>
            <br />
            <hr />

            {
                firstDataPassage?.vehicles &&
                (
                    <>
                        <div>

                            <h4>Dados do Veículo</h4>

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="mb-2">
                                        <strong >Placa</strong> <br />
                                        <span>{firstDataPassage?.vehicles.plate}</span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-2">
                                        <strong >Marca</strong> <br />
                                        <span>{firstDataPassage?.vehicles.brand}</span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-2">
                                        <strong >Modelo</strong> <br />
                                        <span>{firstDataPassage?.vehicles.model}</span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-2">
                                        <strong >Tipo Carro</strong> <br />
                                        <span>{firstDataPassage?.vehicles.type}</span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-2">
                                        <strong >Vaga tipo</strong> <br />
                                        <span>{firstDataPassage?.vehicles.vacancy_type}</span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-2">
                                        <strong >cor do veículo</strong> <br />
                                        <span>{firstDataPassage?.vehicles.vehicle_color}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <hr />
                    </>
                )

            }

            <span><strong>Registrado por:</strong> {firstDataPassage?.responsible_for_releasing}</span>
            <br />
            <hr />


            <div className="row">
                <div className="col-md-4">

                    <div className="mb-3">
                        <label htmlFor="">Status:</label>
                        <select {...register('pass_status_id', { required: true })} onChange={setTimePass} className="form-control" id="">
                            <option value="">Selecione...</option>
                            {
                                firstListAllPassStatus &&
                                firstListAllPassStatus.map((d: any, k: any) => (
                                    <option key={k} title={d.description} value={d.id}>{d.name}</option>
                                ))
                            }
                        </select>
                        {
                            errors.pass_status_id &&
                            <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                        }
                    </div>

                </div>
                <div className="col-md-4">


                    <div className="mb-3">
                        <label htmlFor="">Entrada:</label>
                        <input type="datetime-local" {...register('entry_time')} className="form-control" />

                    </div>

                </div>
                <div className="col-md-4">

                    <div className="mb-3">
                        <label htmlFor="">Saída:</label>
                        <input type="datetime-local" {...register('exit_time')} className="form-control" />

                    </div>

                </div>
                <div className="col-md-12">
                    <div className="mb-3">
                        <label htmlFor="">observação:</label>
                        <textarea {...register('description')} rows={7} className="form-control" />
                    </div>
                </div>
            </div>


        </ModalDialog>
    );
}



