import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

export const RequireAuth = ({ children, route_role_id }: { children: JSX.Element, route_role_id: any[] }) => {
    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!auth!.authenticating && auth!.user) {
            // Extrai os IDs de roles do usuário, se existirem
            const userRoles = auth!.user.roles ? auth!.user.roles.map(role => role.id) : [];

            // Verifica se algum dos roles do usuário está na lista de roles permitidos
            const hasPermission = route_role_id.some(role => userRoles.includes(role));

            // Se o usuário não tem permissão, navega para a página sem permissão
            if (!hasPermission) {
                navigate("/sem-permissao");
            }
        } else if (!auth!.authenticating && !auth!.user) {
            navigate("/login");
        }
    }, [auth, navigate, route_role_id]);

    if (auth!.authenticating) {
        return null;  // Ou retorne seu componente de carregamento
    }

    // Se o usuário tem permissão, renderiza as rotas filhas
    return children;
}