import { ModalDialog } from "../../components/modalComponent";
import InputMask from 'react-input-mask';
import { WebcamComponent } from "../../components/WebcamComponent";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { HVisitors } from "../../hooks/visitors";
import { ToastInfo } from "../../components/ToastInfo";


export default function AddVisitors({ id, getListData }: any) {
    const Apidata = HVisitors();

    const [imgAddPhoto, setImgAddPhoto] = useState<string | null>(null);

    const { handleSubmit, register, reset, formState: { errors } } = useForm({
        defaultValues: {
            name: '',
            document: '',
            phone: '',
            image: '',
            company: '',
            observation: ''
        },
    })

    const handleTakePhoto = (photo: string) => {
        setImgAddPhoto(photo);
    }


    const add = async (formData: any) => {
        formData.image = imgAddPhoto;

        await Apidata.store(formData).then((res: any) => {
            ToastInfo(res);
            (window as any).$(`#${id}`).modal('hide');
            reset();
            getListData();
        }).catch((error) => {
            (window as any).$(`#${id}`).modal('hide');
            console.error('Error ao salvar Cadastrar visitante', error)
        });
    }

    return (
        <ModalDialog id={id} title="Cadastrar visitante" form={true} onClick={handleSubmit(add)} buttonClose={[true, 'Cancelar']} buttonSend={[true, 'Salvar']}>

            <WebcamComponent onTakePhoto={handleTakePhoto} />

            <div className="">
                <div className="mb-3">
                    <label >Nome do Visitante	:</label>
                    <input {...register('name', { required: true })} name="name" type="text" className="form-control" />
                    {
                        errors.name &&
                        <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                    }
                </div>
                <div className="mb-3">
                    <label >Documento	:</label>
                    <input {...register('document', { required: true })} type="text" className="form-control" />
                    {
                        errors.document &&
                        <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                    }
                </div>

                <div className="mb-3">
                    <label >Telefone:</label>
                    <InputMask {...register('phone', { required: true })} className="form-control" mask="(99) 9 9999-9999" placeholder="(00) 0 0000-0000" />
                    {
                        errors.phone &&
                        <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                    }
                </div>

                <div className="mb-3">
                    <label >Empresa:</label>
                    <input {...register('company')} type="text" className="form-control" />

                </div>

                <div className="mb-3">
                    <label >Observação</label>
                    <textarea {...register('observation')} rows={5} className="form-control"></textarea>
                </div>
            </div>
        </ModalDialog>
    );
}