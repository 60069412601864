import SvgDependents from '../../assets/svg/dependents.svg'
import CardSearchAndAdd from '../../components/cardSearchAndAdd';
import StaffPage from '../../components/staffPage';
import TableComponent from '../../components/tableComponent';
import { FiEdit, FiFile } from "react-icons/fi";
import AddDependents from './add_dependents';
import ViewDependents from './view_dependents';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { HDependents } from '../../hooks/dependents';
import nullAbleImg from '../../assets/nuable_img.png'

export default function Dependents() {
  const navigate = useNavigate();
  const apiDependents = HDependents();
  const token = localStorage.getItem('T');
  const [listData, setListData] = useState<any>();
  const [listTypesDependents, setListTypesDependents] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [firstData, setFirstData] = useState<any>([]);


  const getListData = async (numpage = 1, _search = search) => {
    setLoading(true)
    const params = {
      page: numpage,
      search: _search
    }
    apiDependents.index(params).then((res: any) => {
      setListData(res)
    }).finally(() => {
      setLoading(false)
    })
  }

  const getlistTypesDependents = async () => {
    apiDependents.TypesDependents().then((res) => {
      setListTypesDependents(res)
    })
  }

  useEffect(() => {
    getListData();
    getlistTypesDependents();
  }, [])

  return (
    <>
      <StaffPage iconSvg={SvgDependents} description='Garanta a segurança e o controle dos dependentes na sua comunidade!' name='Dependentes' />


      <CardSearchAndAdd add={() => { (window as any).$(`#modalAdd`).modal('show') }} searchChange={(e: any) => { setSearch(e.target.value) }} searchValue={search} searchButton={() => { getListData(1, search) }} />

      <div className="card">
        <h5 className="card-header">Todos Dependentes</h5>
        <TableComponent loading={loading} PaginateFunction={getListData} PaginateListData={listData?.meta} colums={['#', 'Dependente', 'nome', 'Tipo de Dependente', 'Documento', 'Status', 'Telefone', 'Ações',]} >
          {
            listData &&
            listData.data.map((d: any, k: any) => (
              <tr key={k}>
                <td>{d.id_dependent}</td>
                <td>
                  <span style={{ cursor: 'pointer' }} onClick={() => { (window as any).$('#viewImage').modal('show') }} >
                    {
                      d.photo_path ?
                        <img width={40} height={40} src={d.image_url + '/' + token} className="rounded-circle" />
                        : <img width={40} height={40} src={nullAbleImg} />
                    }
                  </span>

                </td>
                <td> {d.name}</td>
                <td>{d.types_dependent}</td>
                <td>{d.document}</td>
                <td> <span className={`badge bg-label-${d.active == 1 ? 'success' : 'danger'} me-1`}>{d.active == 1 ? 'Ativo' : 'Inativo'}</span></td>
                <td>{d.phone}</td>
                <td>
                  <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                    <button onClick={() => { (window as any).$('#modalView').modal('show'); setFirstData(d) }} title='Visualizar' type="button" className="btn btn-primary"><FiFile /></button>
                    <button onClick={() => navigate(`edit/${d.id_dependent}`)} title='Editar' type="button" className="btn btn-primary"><FiEdit /></button>

                  </div>
                </td>
              </tr>
            ))
          }
        </TableComponent>
      </div>

      <AddDependents listTypesDependents={listTypesDependents} getListData={getListData} id='modalAdd' />
      <ViewDependents nullAbleImg={nullAbleImg} token={token} firstData={firstData} id='modalView' />
    </>
  );
}