import { useForm } from "react-hook-form";
import { ModalDialog } from "../../components/modalComponent";
import HPost from "../../hooks/post";
import { ToastInfo } from "../../components/ToastInfo";
interface IDeletePassages {
    id: any
    getListData: any
}

export default function AddPost({ id, getListData }: IDeletePassages) {

    const apiPost = HPost();
    const { register, handleSubmit, reset, formState: { errors } } = useForm<any>({
        defaultValues: {
            name: '',
            description: ''
        }
    })

    const store = async (formData: any) => {
        await apiPost.store(formData).then((res) => {
            ToastInfo(res);
            getListData();
            reset();
            (window as any).$(`#${id}`).modal('hide');
        })
    }

    return (
        <ModalDialog id={id} title="Cadastrar novo Posto" form={true} onClick={handleSubmit(store)} buttonClose={[true, 'Cancelar']} buttonSend={[true, 'Salvar']}>

            <div className="mb-3">
                <label >Nome do Posto</label>
                <input  {...register('name', { required: true })} type="text" className="form-control" />
                {
                    errors.name &&
                    <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                }
            </div>

            <div className="mb-3">
                <label >Descrição do Posto</label>
                <input  {...register('description', { required: true })} type="text" className="form-control" />
                {
                    errors.description &&
                    <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                }
            </div>
        </ModalDialog>
    );
}