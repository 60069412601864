import SvgUser from '../../assets/svg/user.svg'
import CardSearchAndAdd from "../../components/cardSearchAndAdd";
import TableComponent from "../../components/tableComponent";
import AddUsers from "./add_users";
import EditUsers from "./edit_users";
import StaffPage from '../../components/staffPage';
import { FiEdit, FiFile } from "react-icons/fi";
import { useEffect, useState } from 'react';
import { HUsers } from '../../hooks/Users';

export default function Users() {

    const setApiUsers = HUsers();

    const [roles, setRoles] = useState<Array<String>>([]);
    const [selectedDataEdit, setSelectedDataEdit] = useState<any>([]);
    const [selectedRoles, setSelectedRoles] = useState<any>([]);

    const [listAllUsers, setlistAllUsers] = useState<any>([]);
    const [loading, setloading] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');

    const getAllRoles = async () => {
        const data = await setApiUsers.getListRoles();
        setRoles(data);
    }

    const listUsers = async (numberPage = 1, _search = search) => {
        setloading(true)
        const sedData = {
            page: numberPage,
            search: _search
        }
        const data = await setApiUsers.index(sedData).finally(() => { setloading(false) });
        setlistAllUsers(data);
    }


    const geRolesId = (dataSelected: any) => {
        const roles_id = dataSelected?.roles?.map((item: any) => item.id);
        setSelectedRoles(roles_id);
        setSelectedDataEdit(dataSelected);
        (window as any).$('#modalEdit').modal('show');
    }

    useEffect(() => {
        getAllRoles();
        listUsers();
    }, [])

    return (
        <>
            <StaffPage iconSvg={SvgUser} description='Gerenciar usuários' name='Usuários' />

            <CardSearchAndAdd add={() => { (window as any).$(`#modalAdd`).modal('show') }} searchButton={() => { listUsers(1, search) }} searchValue={search} searchChange={(e: any) => { setSearch(e.target.value) }} />

            <div className="card">
                <h5 className="card-header">Todos Moradores</h5>
                <TableComponent loading={loading} PaginateFunction={listUsers} PaginateListData={listAllUsers} colums={['#', 'Nome', 'E-mail', 'Usuário', 'status', 'Ações',]} >
                    {
                        listAllUsers.data &&
                        listAllUsers.data.map((d: any, k: any) => (
                            <tr key={k}>
                                <td>{d.id}</td>
                                <td>{d.name}</td>
                                <td>{d.email}</td>
                                <td>{d.username}</td>
                                <td>
                                    <span className={`badge bg-label-${d.active == 1 ? 'success' : 'danger'} me-1`}>{d.active == 1 ? 'Ativo' : 'Inativo'}</span>
                                </td>
                                <td>
                                    <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                                        <button onClick={() => { geRolesId(d) }} title='Editar' type="button" className="btn btn-primary"><FiEdit /></button>
                                    </div>
                                </td>

                            </tr>
                        ))
                    }

                </TableComponent>
            </div>

            <AddUsers listUsers={listUsers} roles={roles} id='modalAdd' />
            <EditUsers listUsers={listUsers} selectedRoles={selectedRoles} setSelectedRoles={setSelectedRoles} roles={roles} id='modalEdit' selectedDataEdit={selectedDataEdit} />
        </>
    );
}