/* eslint-disable jsx-a11y/alt-text */
import { useContext } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import Swal from "sweetalert2";
import SimpleNavigation from "../../components/simpleNavigation";
import logo from '../../assets/logempresa.png'
import { FaUserAlt } from "react-icons/fa";


export default function LayoutDefaut() {


    function HiderShowMenu() {
        (window as any).$("html").toggleClass("light-style layout-menu-fixed layout-menu-expanded");
    }


    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    const handleLogout = async () => {
        await auth!.logout();
        navigate('/')
        Swal.fire({
            toast: true,
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
            title: 'Deslogado com sucesso.',
            position: 'top-right',
            timerProgressBar: true
        })
    }

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                {/* <!-- Menu --> */}

                <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
                    <div className="app-brand demo">
                        <a href="index.html" className="app-brand-link">
                            <span className="app-brand-logo demo">

                            </span>
                            <span className="app-brand-text demo menu-text fw-bolder ms-2">    <img src={logo} alt="" style={{ width: 130 }} /></span>

                        </a>

                        <a onClick={HiderShowMenu} style={{ cursor: "pointer" }} className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none">
                            <i className="bx bx-chevron-left bx-sm align-middle"></i>
                        </a>
                    </div>

                    <div className="menu-inner-shadow"></div>

                    <ul className="menu-inner py-1">
                        {/* <!-- Dashboard --> */}


                        <li className="menu-header small text-uppercase">
                            <span className="menu-header-text">Controle Condomínio</span>
                        </li>

                        <SimpleNavigation Icon={'home-circle'} link="dashboard" name="Dashboard" />
                        <SimpleNavigation Icon={'user-voice'} link="visitors" name="Visitantes" />
                        <SimpleNavigation Icon={'user'} link="residents" name="Moradores" />
                        <SimpleNavigation Icon={'user-plus'} link="dependents" name="Dependentes" />
                        <SimpleNavigation Icon={'car'} link="vehicles" name="Veículos" />
                        <SimpleNavigation Icon={'street-view'} link="employees" name="Funcionários" />
                        <SimpleNavigation Icon={'block'} link="infractions" name="Infrações" />

                        <SimpleNavigation Icon={'book-bookmark'} link="occurrence_book" name="Livro Ata" />
                        <SimpleNavigation Icon={'menu-alt-right'} link="passages" name="Passagens" />



                        {
                            auth!.user?.roles?.some((role) => role.id === 1) ?
                                <>
                                    <li className="menu-header small text-uppercase">
                                        <span className="menu-header-text">Área administrativa</span>
                                    </li>

                                    <SimpleNavigation Icon={'user-check'} link="users" name="Usuários" />
                                    <SimpleNavigation Icon={'message-square-check'} link="post" name="Posto" />
                                    <SimpleNavigation Icon={'user-voice'} link="logs" name="logs" />
                                </>
                                : <></>
                        }




                    </ul>
                </aside>
                {/* <!-- / Menu --> */}

                {/* <!-- Layout container --> */}
                <div className="layout-page">
                    {/* <!-- Navbar --> */}

                    <nav
                        className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
                        id="layout-navbar"
                    >
                        <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
                            <a onClick={HiderShowMenu} style={{ cursor: "pointer" }} className="nav-item nav-link px-0 me-xl-4">
                                <i className="bx bx-menu bx-sm"></i>
                            </a>
                        </div>

                        <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">

                            <ul className="navbar-nav flex-row align-items-center ms-auto">
                                {/* <!-- Place this tag where you want the button to render. --> */}
                                <li className="nav-item lh-1 me-3">
                                    <span>{auth!.user?.name}</span>
                                </li>

                                {/* <!-- User --> */}
                                <li className="nav-item navbar-dropdown dropdown-user dropdown">
                                    <a className="nav-link dropdown-toggle hide-arrow" data-bs-toggle="dropdown" rel="noreferrer">

                                        <FaUserAlt style={{ cursor: 'pointer' }} />

                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-end">
                                        <li>
                                            <a className="dropdown-item" >
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <FaUserAlt />
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <span className="fw-semibold d-block">{auth!.user?.name}</span>
                                                        {auth!.user?.roles?.find((role) => role.id === auth!.user?.roles)?.description}
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <div className="dropdown-divider"></div>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to={'/profile'}>
                                                <i className="bx bx-user me-2"></i>
                                                <span className="align-middle">Meu Perfil</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <div className="dropdown-divider"></div>
                                        </li>
                                        <li>
                                            <span style={{ cursor: 'pointer' }} onClick={handleLogout} className="dropdown-item">
                                                <i className="bx bx-power-off me-2"></i>
                                                <span className="align-middle">Sair</span>
                                            </span>
                                        </li>
                                    </ul>
                                </li>
                                {/* <!--/ User --> */}
                            </ul>
                        </div>
                    </nav>

                    {/* <!-- / Navbar --> */}

                    {/* <!-- Content wrapper --> */}
                    <div className="content-wrapper">
                        {/* <!-- Content --> */}

                        <div className="container-xxl flex-grow-1 container-p-y">
                            <Outlet />
                            {/* <!-- / Content --> */}

                            {/* <!-- Footer --> */}
                            <footer className="content-footer footer bg-footer-theme">
                                <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                                    <div className="mb-2 mb-md-0">
                                        @ Feito por
                                        <a href="https://oliversofttech.com/" target="_blank" className="footer-link fw-bolder" rel="noreferrer"> Oliver Soft Tech</a>
                                    </div>
                                    <div>
                                        <a href="https://oliversofttech.com/documentation/license" className="footer-link me-4" target="_blank" rel="noreferrer">Licença</a>

                                        <a
                                            href="https://oliversofttech.com/documentation"
                                            target="_blank"
                                            className="footer-link me-4" rel="noreferrer"
                                        >Documentação</a>

                                        <a
                                            href="https://oliversofttech.com/support"
                                            target="_blank"
                                            className="footer-link me-4" rel="noreferrer"
                                        >Suporte</a>
                                    </div>
                                </div>
                            </footer>
                            {/* <!-- / Footer --> */}

                            <div className="content-backdrop fade"></div>
                        </div>
                        {/* <!-- Content wrapper --> */}
                    </div>
                    {/* <!-- / Layout page --> */}
                </div>

                {/* <!-- Overlay --> */}
                <div className="layout-overlay layout-menu-toggle"></div>
            </div>
        </div>
    );
}