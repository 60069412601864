import { useState } from "react";
import { FiCheck, FiEdit3, FiTrash2 } from "react-icons/fi";

export default function EditableItem({ item, updateItem, itemKey, itemId, deleteItem }: any) {
    const [name, setName] = useState(item.name);
    const [amount, setAmount] = useState(item.amount);
    const [isEditing, setIsEditing] = useState(false);

    const handleNameChange = (e: any) => {
        setName(e.target.value);
    };

    const handleAmountChange = (e: any) => {
        setAmount(e.target.value);
    };

    const handleUpdate = () => {
        updateItem(itemKey, { name, amount, id: itemId });
        setIsEditing(false);
    };


    const handleEdit = () => {
        setIsEditing(true);
    };

    return (
        <>
            <div className="row bg-light  rounded" key={itemKey}>
                <div className="col-md-7">
                    {isEditing
                        ? <input type="text" className="form-control form-control-sm" placeholder="Cadastre um novo item, informe o nome" value={name} onChange={handleNameChange} />
                        : <h6 className="mt-2">{name}</h6>
                    }
                </div>
                <div className="col-md-3">
                    {isEditing
                        ? <input type="number" className="form-control form-control-sm" placeholder="Informe a quantidade" value={amount} onChange={handleAmountChange} />
                        : <h6 className="mt-2">{amount}</h6>
                    }
                </div>
                <div className="col-md-2 d-flex align-items-center">
                    {isEditing
                        ? <button type="button" className="btn btn-outline-success btn-sm" onClick={handleUpdate}> <FiCheck /> </button>
                        : (
                            <>

                                <div className="btn-group btn-group-sm" role="group" aria-label="...">
                                    <button type="button" className="btn btn-outline-primary" onClick={handleEdit}><FiEdit3 /></button>
                                    <button type="button" className="btn btn-outline-danger" onClick={() => { deleteItem(itemId) }}>< FiTrash2 /></button>
                                </div>
                            </>
                        )
                    }


                </div>

            </div>
        </>
    );
}

