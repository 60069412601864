

import { ToastInfo } from "../../components/ToastInfo";
import { ModalDialog } from "../../components/modalComponent";
import HPassages from "../../hooks/passages";


interface IDeletePassages {
    id: any
    firstDataPassage?: any;
    getListPassages: (numPage?: number, _search?: string, _perPage?: any) => Promise<void>
}

export default function DeletePassages({ id, getListPassages, firstDataPassage }: IDeletePassages) {
    const apiPassages = HPassages();

    const deletePassagem = async () => {
        apiPassages.delete(firstDataPassage.id).then((res) => {
            ToastInfo(res);
            getListPassages();
            (window as any).$(`#${id}`).modal('hide');
        }).catch((err) => {
        })
    }

    return (
        <ModalDialog id={id} title="Excluir  passagem" onClick={deletePassagem} buttonClose={[true, 'Não']} buttonSend={[true, 'Sim']}>

            <div>
                Tem certeza que deseja excluir essa passagem?
            </div>

        </ModalDialog>
    );
}