import Loading from "./loading";
import { PaginationTable } from "./paginationTable";

interface ITableComponent {
    colums?: any;
    children?: any;
    size?: string;
    loading?: boolean;
    PaginateFunction?: any;
    PaginateListData?: any;
    perPageOnChange?: any;
    perPageValue?: any;
    bordered?: boolean
}

export default function TableComponent({ colums, children, size, loading, PaginateFunction, PaginateListData, perPageOnChange, perPageValue, bordered }: ITableComponent) {
    return (

        <div style={{ margin: 20 }} className="table-responsive text-nowrap">
            <table className={`table table-${size} table-${bordered ? 'bordered' : ''}`}>

                {
                    colums &&
                    (
                        <thead>
                            <tr>
                                {
                                    colums.map((c: any, k: any) => (
                                        <th key={k} >
                                            {c}
                                        </th>
                                    ))
                                }
                            </tr>
                        </thead>
                    )
                }
                <tbody className="table-border-bottom-0">
                    {children}
                </tbody>
            </table>
            {
                loading == true &&
                (
                    <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", paddingTop: 10, paddingBottom: 50 }}>
                        <Loading width={100} height={100} />
                    </div>
                )
            }

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                <div>
                    {
                        perPageValue &&
                        <>
                            <label style={{ fontSize: 10, marginTop: 20 }} >Qtd Por Página</label><br />
                            <select onChange={perPageOnChange} value={perPageValue} className="form-control form-control-sm"  >
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                <option value={80}>80</option>
                                <option value={100}>100</option>
                            </select>
                        </>
                    }

                </div>

                <div style={{ display: 'flex', justifyContent: 'end', marginTop: 20 }}>
                    {
                        PaginateListData &&
                        <PaginationTable functionPagination={PaginateFunction} dadosPagination={PaginateListData} />
                    }
                </div>
            </div>

        </div>
    );

}