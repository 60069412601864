import Api from "../../services/api";

const HLogs = () => ({

    index: async (data: any) => {

        try {
            const response = await Api.get('/logs', {
                params: data
            });
            return response.data;
        } catch (error) {
            throw error;
        }

    },


})

export default HLogs;