
import { useForm } from "react-hook-form";
import { ModalDialog } from "../../components/modalComponent";
import { useEffect, useState } from "react";
import { HRegisterCommons } from "../../hooks/RegisterCommons";
import HInfractions from "../../hooks/Infractions";
import { ToastInfo } from "../../components/ToastInfo";

export default function EditInfractions({ id, data, getInfractions }: any) {

    const [registerCommons, setRegisterCommons] = useState<any>([]);

    const apiRegisterCommons = HRegisterCommons();
    const apiInfractions = HInfractions();

    const { handleSubmit, register, reset, formState: { errors }, control } = useForm({
        defaultValues: {
            register_common_id: data?.register_common_id,
            occurrence_date: data?.occurrence_date,
            occurrence_description: data?.occurrence_description,
            notified: data?.notified,
            received_fine: data?.received_fine,
            fine_paid: data?.fine_paid,
            amount_of_the_fine: data?.amount_of_the_fine ? parseFloat(data?.amount_of_the_fine,).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '--',
            observation: data?.observation,
        },
        values: {
            register_common_id: data?.register_common_id,
            occurrence_date: data?.occurrence_date,
            occurrence_description: data?.occurrence_description,
            notified: data?.notified,
            received_fine: data?.received_fine,
            fine_paid: data?.fine_paid,
            amount_of_the_fine: data?.amount_of_the_fine ? parseFloat(data?.amount_of_the_fine,).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '--',
            observation: data?.observation,
        },
    })

    // função para pesquisar reghister commons
    const getRegisterCommons = async () => {

        let typePeople: number | null = null;

        (window as any).$(".form-check-input:checked").each((i: number, e: any) => {
            typePeople = parseInt(e.value);
        });

        if (typePeople) {
            await apiRegisterCommons.registerCommons(typePeople).then(resp => {
                setRegisterCommons(resp);
            })
        } else {
            await apiRegisterCommons.registerCommons(data?.register_common_id).then(resp => {
                setRegisterCommons(resp);
            })
        }

    }
    // função para pesquisar reghister commons

    const edit = async (formData: any) => {

        if (formData.amount_of_the_fine) {
            let valor = formData.amount_of_the_fine;
            valor = valor = String(valor).replace("R$", "").replace(".", "").replace(",", ".");
            let valorEmDouble = parseFloat(valor);
            formData.amount_of_the_fine = valorEmDouble;
        }


        await apiInfractions.update(data?.id, formData).then(resp => {
            ToastInfo(resp);
            (window as any).$("#" + id).modal("hide");
            reset();
            getInfractions();
        }).catch(error => {
            (window as any).$(`#${id}`).modal('hide');
        })

    }

    useEffect(() => {
        if (data) {
            getRegisterCommons();
        }
    }, [data])

    return (
        <ModalDialog id={id} title="Editar ocorrência" onClick={handleSubmit(edit)} buttonClose={[true, 'Cancelar']} buttonSend={[true, 'Editar']}>
            {
                data &&

                <>
                    <div className="mb-3">
                        <label htmlFor="">Descrição da ocorrência</label>
                        <textarea {...register("occurrence_description", { required: true })} name="occurrence_description" rows={2} className="form-control"></textarea>
                        {
                            errors.occurrence_description &&
                            <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                        }
                    </div>

                    <div className="">
                        <div className="mb-3">
                            <label htmlFor="">Foi Notificado:</label>
                            <select className="form-control" {...register("notified", { required: true })}>
                                <option value="">Selecione...</option>
                                <option value="1">Sim</option>
                                <option value="0">Não</option>
                            </select>
                            {
                                errors.notified &&
                                <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                            }
                        </div>
                        <div className="mb-3">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" defaultChecked={data?.types_people_id == 1} onChange={getRegisterCommons} id="inlineRadio1" value="1" />
                                <label className="form-check-label" htmlFor="inlineRadio1">Visitante</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" defaultChecked={data?.types_people_id == 2} onChange={getRegisterCommons} id="inlineRadio2" value="2" />
                                <label className="form-check-label" htmlFor="inlineRadio2">Morador</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" defaultChecked={data?.types_people_id == 3} onChange={getRegisterCommons} id="inlineRadio3" value="3" />
                                <label className="form-check-label" htmlFor="inlineRadio3">Dependente</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" defaultChecked={data?.types_people_id == 4} onChange={getRegisterCommons} id="inlineRadio4" value="4" />
                                <label className="form-check-label" htmlFor="inlineRadio3">Funcionário</label>
                            </div>
                        </div>
                        {
                            registerCommons.length > 0 &&

                            <div className="mb-3">
                                <label htmlFor="">Responsável:</label>
                                <select className="form-control" {...register("register_common_id", { required: true })}>
                                    <option value="">Selecione...</option>
                                    {
                                        registerCommons.map((r: any, k: any) => {
                                            return <option key={k} value={r.id}>{r.name}</option>;
                                        })
                                    }
                                </select>
                                {
                                    errors.register_common_id &&
                                    <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                                }
                            </div>
                        }
                        <div className="mb-3">
                            <label htmlFor="">Data da Notificação:</label>
                            <input type="date" className="form-control" {...register("occurrence_date")} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="">Recebeu Multa:</label>
                            <select className="form-control" {...register("received_fine", { required: true })} >
                                <option value="">Selecione...</option>
                                <option value="1">Sim</option>
                                <option value="0">Não</option>
                            </select>
                            {
                                errors.received_fine &&
                                <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                            }
                        </div>
                        <div className="mb-3">
                            <label htmlFor="">Multa paga:</label>
                            <select className="form-control" {...register("fine_paid", { required: true })}>
                                <option value="">Selecione...</option>
                                <option value="1">Sim</option>
                                <option value="0">Não</option>
                            </select>
                            {
                                errors.fine_paid &&
                                <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                            }
                        </div>
                        <div className="mb-3">
                            <label htmlFor="">Valor da Multa</label>
                            <input type="text" className="form-control" {...register("amount_of_the_fine")} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="">Mais detalhes sobre o caso:</label>
                            <textarea rows={6} className="form-control" {...register("observation")}></textarea>
                        </div>

                    </div>
                </>
            }
        </ModalDialog>
    );
}