import { ModalDialog } from "../../components/modalComponent";
import InputMask from 'react-input-mask';
import { useEffect, useState } from "react";
import { WebcamComponent } from "../../components/WebcamComponent";
import { HEmployees } from "../../hooks/employees";
import { useForm } from "react-hook-form";
import { ToastInfo } from "../../components/ToastInfo";
import Loading from "../../components/loading";

export default function AddEmployees({ id, getListData, type_employees }: any) {

    const Apidata = HEmployees();
    const [imgAddPhoto, setImgAddPhoto] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [typeEmployees, setTypeEmployees] = useState<any>([]);
    const apiEmployees = HEmployees();

    const { handleSubmit, register, reset, formState: { errors } } = useForm({
        defaultValues: {
            name: '',
            document: '',
            phone: '',
            image: '',
            type_employee_id: '',
            observation: ''
        },
    })

    const handleTakePhoto = (photo: string) => {
        setImgAddPhoto(photo);
    }

    // função para pesquisar os tipos de funcionários
    const getTypesEmployees = async () => {

        setLoading(true);

        await apiEmployees.getTypeEmployees().then(resp => {
            setTypeEmployees(resp);
        }).catch(error => {
            (window as any).$(`#${id}`).modal('hide');
        });

        setLoading(false);
    }
    // função para pesquisar os tipos de funcionários


    const add = async (formData: any) => {
        formData.image = imgAddPhoto;

        await Apidata.store(formData).then((res: any) => {
            ToastInfo(res);
            (window as any).$(`#${id}`).modal('hide');
            reset();
            getListData();
        }).catch((error) => {
            (window as any).$(`#${id}`).modal('hide');
            console.error('Error ao salvar Cadastrar Funcionário', error)
        });
    }

    useEffect(() => {
        getTypesEmployees();
    }, []);

    return (
        <ModalDialog id={id} title="Cadastrar Funcionário" onClick={handleSubmit(add)} buttonClose={[true, 'Cancelar']} buttonSend={[true, 'Salvar']}>


            <WebcamComponent onTakePhoto={handleTakePhoto} />

            <div className="mb-3">
                <label htmlFor="">Nome Funcionário</label>
                <input {...register('name', { required: true })} name="name" type="text" className="form-control" />
                {
                    errors.name &&
                    <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                }
            </div>

            <div className="mb-3">

                <label htmlFor="">Tipo Funcionário</label>
                <select {...register("type_employee_id", { required: true })} name="type_employee_id" className="form-control">
                    <option value="">Selecione...</option>
                    {
                        typeEmployees &&
                        typeEmployees.map((d: any, k: any) => (
                            <option key={k} value={d.id}>{d.description}</option>
                        ))
                    }
                </select>
                {
                    errors.type_employee_id &&
                    <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                }
            </div>


            <div className="row">
                <div className="col-md-6">
                    <div className="mb-3">
                        <label htmlFor="">Documento</label>
                        <input type="text" className="form-control"  {...register("document", { required: true })} name="document" />
                        {
                            errors.document &&
                            <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                        }
                    </div>

                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label htmlFor="">Telefone</label>
                        <InputMask className="form-control" mask="(99) 9 9999-9999" placeholder="(00) 0 0000-0000" {...register("phone", { required: true })} name="phone" />
                        {
                            errors.phone &&
                            <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                        }
                    </div>

                </div>
            </div>


            <div className="mb-3">
                <label htmlFor="">Observação</label>
                <textarea rows={5} className="form-control" {...register("observation")} name="observation"></textarea>
            </div>

        </ModalDialog>
    );
}