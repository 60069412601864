import { useNavigate } from "react-router-dom";
import HPassages from "../../../hooks/passages";
import { useEffect, useState } from "react";
import moment from "moment";
import { IUsePassagensListClosing } from "./types/i-use-passagens-list-closing";

export default function usePassagensListClosing(): IUsePassagensListClosing {

    //api's
    const apiPassages = HPassages();
    const token = localStorage.getItem('T');

    //states
    const [listPassages, setListPassages] = useState<any>();
    const [firstDataPassage, setFirstDataPassage] = useState<any>([]);
    const [firstListAllPassStatus, setfirstListAllPassStatus] = useState<any>([]);
    const [loading, setloading] = useState<boolean>(false);
    const [elapsedTimes, setElapsedTimes] = useState<{ [key: string]: string }>({});
    const [search, setSearch] = useState<string>('');
    const [perPage, setPerPage] = useState<any>(30);

    //hooks
    const navigate = useNavigate();


    //constantes
    const statusColors: any = {
        1: '#ff9702',
        2: '#006bff',
        3: '#ff5602',
        4: '#009b60',
        5: '#ea2200',
        6: '#ff00a5',
        default: '#233446'
    };

    //functios
    const getListPassages = async (numPage = 1, _search = search, _perPage = perPage) => {
        setloading(true)
        const params = {
            page: numPage,
            search: _search,
            perPage: _perPage,
            status: [4,5,6]
        }
        await apiPassages.index(params).then((res: any) => {
            setListPassages(res)
        }).finally(() => { setloading(false) })
    }

    const perPageOnChange = (e: any) => {
        const newPerPage = e.target.value;
        setPerPage(newPerPage);
        getListPassages(1, search, newPerPage);
    }

    const getAllPassStatus = async () => {
        await apiPassages.listPassStatus().then((res) => {
            setfirstListAllPassStatus(res)
        })
    }

    //effects
    useEffect(() => {
        getListPassages();
        getAllPassStatus();
    }, [])

    useEffect(() => {
        if (listPassages) {
            const intervals: { [key: string]: NodeJS.Timeout } = {};

            listPassages.data.forEach((d: any) => {
                if (!d.entry_time) return;
                const startTime = moment(d.entry_time);
                const endTime = d.exit_time ? moment(d.exit_time) : null;

                intervals[d.id] = setInterval(() => {
                    const now = endTime ? endTime : moment();
                    const duration = moment.duration(now.diff(startTime));

                    const hours = Math.floor(duration.asHours());
                    const mins = Math.floor(duration.asMinutes()) - hours * 60;
                    const secs = Math.floor(duration.asSeconds()) - mins * 60 - hours * 3600;

                    const formattedHours = hours.toString().padStart(2, '0');
                    const formattedMins = mins.toString().padStart(2, '0');
                    const formattedSecs = secs.toString().padStart(2, '0');

                    setElapsedTimes(prev => ({ ...prev, [d.id]: `${formattedHours}:${formattedMins}:${formattedSecs} ` }));

                    // Se existir um horário de saída, limpe o intervalo
                    if (endTime) clearInterval(intervals[d.id]);
                }, 1000);
            });



            return () => Object.values(intervals).forEach(clearInterval);
        }
    }, [listPassages]);




    return {
        navigate,
        firstDataPassage,
        firstListAllPassStatus,
        loading,
        elapsedTimes,
        perPageOnChange,
        setFirstDataPassage,
        setSearch,
        token,
        statusColors,
        getListPassages,
        perPage,
        search,
        listPassages
    }
}