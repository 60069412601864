import Api from "../../services/api";

export const HUsers = () => ({
    index: async (data: any) => {
        const response = await Api.get('/users', { params: data });
        return response.data;
    },
    getListRoles: async () => {
        const respose = await Api.get('/users/get_list_roles',);
        return respose.data;
    },
    store: async (data: any) => {
        const response = await Api.post('/users', data);
        return response.data;
    },
    update: async (data: any) => {
        const respose = await Api.put(`/users/${data.id}`, data);
        return respose.data;
    },
    destroy: async () => {
        const respose = await Api.delete('/users');
        return respose.data;
    },
    alterPassword: async (data: any) => {
        const respose = await Api.post('/users/alter_password', data);
        return respose.data;
    }

});

