import React, { useState, useRef, useEffect } from 'react';
import imgUser from '../assets/svg/photo_session_re_c0cp.svg';
import { ModalDialog } from './modalComponent';

// Define os tipos de props que o WebcamComponent espera. 
// Nesse caso, espera um callback chamado onTakePhoto, que recebe uma string como argumento.
type WebcamComponentProps = {
    onTakePhoto: (photo: string) => void;
    showPhoto?: boolean;
    modalPhoto?: boolean
    onClick?: () => void;
};


export const WebcamComponent: React.FC<WebcamComponentProps> = ({ onTakePhoto, showPhoto = true, modalPhoto = false, onClick }) => {
    // Utiliza o Hook useState para criar um estado para a foto a ser adicionada e sua função setter.
    const [imgAddPhoto, setImgAddPhoto] = useState<string | null>(null);

    // Utiliza o Hook useRef para criar uma referência para o elemento de vídeo e para o elemento canvas.
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    // Utiliza o Hook useState para criar um estado que controla se a webcam está ativa ou não e sua função setter.
    const [isWebcamActive, setIsWebcamActive] = useState(false);

    // Utiliza o Hook useRef para criar uma referência para o input de arquivo.
    const inputFileRef = useRef<HTMLInputElement | null>(null);

    // Função para abrir a webcam.
    const openWebcam = async () => {
        if (videoRef.current) {
            try {
                // Solicita acesso à webcam do usuário e inicia a transmissão de vídeo para o elemento de vídeo.
                const stream = await navigator.mediaDevices.getUserMedia({ video: true });
                videoRef.current.srcObject = stream;
                videoRef.current.play();
                setIsWebcamActive(true);

                // Limpa o valor do input de arquivo, se existir.
                if (inputFileRef.current) {
                    inputFileRef.current.value = '';
                }
            } catch (error) {
                console.error('Falha ao abrir a webcam:', error);
            }
        }
    }

    // Função para parar a webcam.
    const stopWebcam = () => {
        if (videoRef.current && videoRef.current.srcObject && videoRef.current.srcObject instanceof MediaStream) {
            // Para a transmissão de vídeo e define a webcam como inativa.
            const track = videoRef.current.srcObject.getTracks()[0];
            track && track.stop();
            setIsWebcamActive(false);
        }
    }

    // Função para tirar uma foto.
    const takePhoto = () => {
        if (videoRef.current && canvasRef.current) {
            const context = canvasRef.current.getContext('2d');
            if (context) {
                // Desenha a imagem atual da webcam no canvas e pega o URL da imagem.
                canvasRef.current.width = videoRef.current.videoWidth;
                canvasRef.current.height = videoRef.current.videoHeight;
                context.drawImage(videoRef.current, 0, 0, videoRef.current.videoWidth, videoRef.current.videoHeight);
                const dataUrl = canvasRef.current.toDataURL('image/png');

                // Atualiza o estado da foto a ser adicionada e para a webcam.
                setImgAddPhoto(dataUrl);
                stopWebcam();

                // Invoca o callback com a foto.
                onTakePhoto(dataUrl);
            }
        }
    }

    // Função para lidar com a seleção de um arquivo.
    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            // Cria um URL de objeto para o arquivo selecionado e atualiza o estado da foto a ser adicionada.
            const url = URL.createObjectURL(file);
            setImgAddPhoto(url);
            // Invoca o callback com a foto.
            onTakePhoto(url);
        }
        if (modalPhoto) {
            (window as any).$('#modalWebcam').modal('show');
        }

    };

    // Utiliza o Hook useEffect para revogar o URL do objeto da foto a ser adicionada quando ele muda.
    useEffect(() => {
        return () => {
            if (imgAddPhoto) {
                URL.revokeObjectURL(imgAddPhoto);
            }
        }
    }, [imgAddPhoto]);


    return (

        <>

            {
                modalPhoto == false ?
                    (
                        <>

                            {showPhoto && imgAddPhoto == null && isWebcamActive == false ? <img width={450} src={imgUser} alt="" /> : ''}
                            <div style={{ display: 'flex', justifyContent: "center" }}>
                                <video width={450} ref={videoRef} style={{ display: isWebcamActive ? 'block' : 'none' }} autoPlay playsInline muted />
                                <canvas width={450} ref={canvasRef} style={{ display: 'none' }} />
                                {!isWebcamActive && <img width={450} src={imgAddPhoto || ''} alt="" />}
                            </div>

                            <br />
                            {isWebcamActive && <div style={{ display: 'flex', justifyContent: 'center' }}><button type='button' className="btn btn-primary" onClick={takePhoto}>Tirar foto</button></div>}
                            <br />
                        </>
                    ) : ''
            }

            <div className='row'>
                <div className='col-md-8'>
                    <input type="file" ref={inputFileRef} className="form-control" onChange={handleFileSelect} />
                </div>
                <div className='col-md-4'>
                    {
                        modalPhoto ? <button type='button' style={{ marginTop: 5 }} className="btn btn-sm btn-primary" onClick={() => { (window as any).$('#modalWebcam').modal('show'); openWebcam() }}>Abrir Webcam</button> :
                            <button type='button' style={{ marginTop: 5 }} className="btn btn-sm btn-primary" onClick={openWebcam}>Abrir Webcam</button>
                    }

                </div>
            </div>
            <br />

            <ModalDialog id='modalWebcam' modalDialog="centered" onClick={onClick} buttonClose={[true, 'Cancelar']} buttonSend={[true, 'Salvar']} >
                {
                    modalPhoto == true ?

                        (
                            <>
                                {showPhoto && imgAddPhoto == null && isWebcamActive == false ? <img width={450} src={imgUser} alt="" /> : ''}

                                <div style={{ display: 'flex', justifyContent: "center" }}>
                                    <video width={450} ref={videoRef} style={{ display: isWebcamActive ? 'block' : 'none' }} autoPlay playsInline muted />
                                    <canvas width={450} ref={canvasRef} style={{ display: 'none' }} />
                                    {!isWebcamActive && <img width={450} src={imgAddPhoto || ''} alt="" />}
                                </div>

                                <br />
                                {isWebcamActive && <div style={{ display: 'flex', justifyContent: 'center' }}><button type='button' className="btn btn-primary" onClick={takePhoto}>Tirar foto</button></div>}
                                <br />
                            </>
                        ) : ''
                }


            </ModalDialog>
        </>
    );
};
