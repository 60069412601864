interface IStaffPage {
    iconSvg: any
    name: string
    description: string
}

export default function StaffPage({ iconSvg, name, description }: IStaffPage) {
    return (
        <div className="col-lg-12 mb-4 order-0">
            <div className="card">
                <div className="d-flex align-items-end row">

                    <div className="col-sm-7">
                        <div className="card-body">
                            <h5 className="card-title text-primary"> {name}</h5>
                            <p style={{ color: '#566a7f' }}>

                                {description}

                            </p>
                        </div>
                    </div>
                    <div className="col-sm-5 text-center text-sm-left">
                        <div className="card-body pb-0 px-0 px-md-4">
                            <img
                                src={iconSvg}
                                height="100"
                                alt="View Badge User"
                                data-app-dark-img="illustrations/man-with-laptop-dark.png"
                                data-app-light-img="illustrations/man-with-laptop-light.png"
                            />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}