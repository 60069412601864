import { ModalDialog } from "../../components/modalComponent";
import TableComponent from "../../components/tableComponent";
import moment from 'moment';

export default function ViewVisitors({ id, FirstData, token, nullAbleImg }: any) {

    return (
        <ModalDialog modalSize={'xl'} id={id} title={`Detalhes visitantes #${FirstData.id_visitors}`} >
            <div style={{ border: '1px solid #cbdbdb', padding: 20 }}>
                <div className="row">
                    <div className="col-md-4">
                        <div className="col-md-6">
                            <div style={{ borderRadius: 10 }}>
                                <span style={{ cursor: 'pointer' }} onClick={() => { (window as any).$('#viewImage').modal('show') }} >
                                    {
                                        FirstData.photo_path ?
                                            <img style={{ borderRadius: 10, width: '200%' }} src={FirstData.image_url + '/' + token} />
                                            : <img width={160} src={nullAbleImg} />
                                    }
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-8">

                        <div className="mb-2">
                            <strong >Nome do Visitante:</strong> <br />
                            <span>{FirstData.name}</span>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <strong >Documento:</strong> <br />
                                    <span>{FirstData.document}</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <strong >Empresa:</strong> <br />
                                    <span>{FirstData.company}</span>
                                </div>
                            </div>

                        </div>

                        <div className="row">

                            <div className="col-md-6">
                                <div className="mb-2">
                                    <strong >Status:</strong> <br />
                                    <td> <span className={`badge bg-label-${FirstData.active === 1 ? 'success' : 'danger'} me-1`}>{FirstData.active === 1 ? 'Ativo' : 'Inativo'}</span></td>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <strong >Cadastro:</strong> <br />
                                    <span>{moment(FirstData.created_at).format('DD/MM/YYYY')}</span>
                                </div>
                            </div>
                        </div>

                        <span><strong>Descrição: </strong>  {FirstData.observation}</span><br />

                    </div>


                </div>

            </div>



            <div className="mt-3">
                <div style={{ border: '1px solid #cbdbdb', padding: 20 }}>

                    <div style={{ top: 13, backgroundColor: '#ffffff', zIndex: 1000, paddingLeft: 10, paddingRight: 10 }}>
                        <strong>Veículos</strong>
                    </div>
                    <hr />


                    <TableComponent colums={['#', 'Marca', 'Modelo', 'Tipo', 'Placa']} size="sm">

                        {
                            FirstData.vehicles &&
                            FirstData.vehicles.map((d: any, k: any) => (
                                <tr key={k}>
                                    <td>{d.id}</td>
                                    <td>{d.brand}</td>
                                    <td>{d.model}</td>
                                    <td>{d.type}</td>
                                    <td>{d.plate}</td>
                                </tr>
                            ))
                        }


                    </TableComponent>

                </div>
            </div>


        </ModalDialog>
    );
}