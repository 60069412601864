import { Route, Routes } from "react-router-dom";
import DefaultLogin from "./layouts/login";
import LayoutDefaut from "./layouts/default";
import { RequireAuth } from "./contexts/Auth/RequireAuth";


//pages Padrão
import Dashboard from "./pages/dashboard";
import Profile from "./pages/profile";
import Login from "./pages/login";
import Users from "./pages/users";
//pages Padrão


import Residents from "./pages/residents";
import EditResidents from "./pages/residents/edit_residents";

import Dependents from "./pages/dependents";
import EditDependents from "./pages/dependents/edit_dependents";

import Vehicles from "./pages/vehicles";
import Employees from "./pages/employees";

import Visitors from "./pages/visitors";
import EditVisitors from "./pages/visitors/edit_visitors";

import Infractions from "./pages/infractions";
import Passages from "./pages/passages/list";
import AccessDanied from "./pages/accessDanied";
import EditEmployees from "./pages/employees/edit_employees";
import AddPassages from "./pages/passages/add_passages";
import OccurrenceBook from "./pages/occurrenceBook";
import Post from "./pages/post";
import EditPost from "./pages/post/edit_post";
import Logs from "./pages/logs";


export default function Rotas() {
    return (
        <Routes>
            <Route element={<DefaultLogin />}>
                <Route path="/" element={<Login />} ></Route>
                <Route path="/login" element={<Login />} ></Route>
                <Route path="/sem-permissao" element={<AccessDanied />} ></Route>
            </Route>

            <Route element={<RequireAuth route_role_id={[2, 1]}><LayoutDefaut /></RequireAuth>}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/profile" element={<Profile />} />

                {/* Rotas sistema */}
                <Route path="residents" element={<Residents />} />
                <Route path="dependents" element={<Dependents />} />
                <Route path="vehicles" element={<Vehicles />} />
                <Route path="employees" element={<Employees />} />
                <Route path="visitors" element={<Visitors />} />
                <Route path="infractions" element={<Infractions />} />
                <Route path="passages" element={<Passages />} />
                <Route path="occurrence_book" element={<OccurrenceBook />} />
                <Route path="post" element={<Post />} />
                <Route path="logs" element={<Logs />} />
                {/* Rotas sistema */}

                <Route path="visitors/edit/:id" element={<EditVisitors />} />
                <Route path="dependents/edit/:id" element={<EditDependents />} />
                <Route path="residents/edit/:id" element={<EditResidents />} />
                <Route path="employees/edit/:id" element={<EditEmployees />} />
                <Route path="post/edit/:id" element={<EditPost />} />

                <Route path="passages/store" element={<AddPassages />} />

            </Route>

            <Route element={<RequireAuth route_role_id={[1]}><LayoutDefaut /></RequireAuth>}>
                <Route path="/users" element={<Users />} />
            </Route>
        </Routes >
    )

}

