import Api from "../../services/api";

const HPost = () => ({
  index: async (data: any) => {
    const response = await Api.get("/post", {
      params: data,
    });
    return response.data;
  },
  store: async (data: any) => {
    const response = await Api.post("/post", data);
    return response.data;
  },
  update: async (data: any) => {
    try {
      const response = await Api.put(`/post/${data.id}`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  storeItem: async (data: any) => {
    const response = await Api.post("/post/itens", data);
    return response.data;
  },
  updateItem: async (data: any) => {
    try {
      const response = await Api.put(`/post/itens/${data.id}`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  delteItem: async (id: any) => {
    const response = await Api.delete(`/post/itens/${id}`);
    return response.data;
  },
});

export default HPost;
