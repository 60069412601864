import Swal from "sweetalert2"


export const ToastInfo = (res: any) => {
    if (res.status === 1) {
        //success
        Swal.fire({
            toast: true,
            icon: "success",
            title: "Sucesso",
            text: res.msg,
            showConfirmButton: false,
            showDenyButton: false,
            showCancelButton: false,
            position: 'top-right',
            timer: 2000
        })
        //success
    }

    if (res.status === 0) {
        Swal.fire({
            icon: "warning",
            title: 'Atenção!',
            text: res.msg,
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: 'Tentar novamente',

        })
    }
}