import { ModalDialog } from "../../components/modalComponent";
import InputMask from 'react-input-mask';
import { useState } from "react";
import { WebcamComponent } from "../../components/WebcamComponent";
import { HResidents } from "../../hooks/Residents";
import { useForm } from "react-hook-form";
import { ToastInfo } from "../../components/ToastInfo";

interface IAddResidents {
    id: any;
    getListData: () => void;
}

export default function AddResidents({ id, getListData }: IAddResidents) {
    const Apidata = HResidents();

    const [imgAddPhoto, setImgAddPhoto] = useState<string | null>(null);

    const { handleSubmit, register, reset, formState: { errors } } = useForm({
        defaultValues: {
            name: '',
            document: '',
            phone: '',
            image: '',
            home_address: '',
            email: '',
            observation: ''
        },
    })

    const handleTakePhoto = (photo: string) => {
        setImgAddPhoto(photo);
    }


    const add = async (formData: any) => {
        formData.image = imgAddPhoto;

        await Apidata.store(formData).then((res: any) => {
            ToastInfo(res);
            (window as any).$(`#${id}`).modal('hide');
            reset();
            getListData();
        }).catch((error) => {
            (window as any).$(`#${id}`).modal('hide');
            console.error('Error ao salvar Cadastrar visitante', error)
        });
    }

    return (
        <ModalDialog modalSize={'xl'} form={true} id={id} title="Cadastrar novo Morador" onClick={handleSubmit(add)} buttonClose={[true, 'Cancelar']} buttonSend={[true, 'Salvar']}>

            <div className="row">
                <div className="col-md-6">

                    <div className="mb-3">
                        <label >Nome completo:</label>
                        <input {...register('name', { required: true })} type="text" className="form-control" placeholder="nome completo" />
                        {
                            errors.name &&
                            <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                        }
                    </div>
                    <div className="mb-3">
                        <label >Documento</label>
                        <input {...register('document', { required: true })} type="text" className="form-control" />
                        {
                            errors.document &&
                            <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                        }
                    </div>

                    <div className="mb-3">
                        <label >Endereço residencial</label>
                        <input  {...register('home_address', { required: true })} type="text" className="form-control" />
                        {
                            errors.home_address &&
                            <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                        }
                    </div>

                    <div className="mb-3">
                        <label >Número de telefone</label>
                        <InputMask {...register('phone')} className="form-control" mask="(99) 9 9999-9999" />
                    </div>
                    <div className="mb-3">
                        <label >Observação</label>
                        <textarea {...register('observation')} rows={5} className="form-control"></textarea>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="mb-3">
                        <label >Email</label>
                        <input  {...register('email')} type="text" className="form-control" placeholder="exemplo@gmail.com" />
                    </div>

                    <WebcamComponent onTakePhoto={handleTakePhoto} />

                </div>
            </div>


        </ModalDialog>
    );
}