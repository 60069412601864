import React, { createContext } from 'react';
import { IUser } from '../../types/User';

export type AuthContextType = {
    user: IUser | null;
    login: (username: string, password: string) => Promise<boolean>;
    logout: () => Promise<void>;
    tokenInvalid: boolean | null;
    authenticating: boolean;  // Adicionado o estado de autenticating
}

export const AuthContext = createContext<AuthContextType | null>(null);
