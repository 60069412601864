import { useState, useEffect } from 'react'
import SvgCurrenceBook from '../../assets/svg/occurrenceBook.svg'
import CardSearchAndAdd from '../../components/cardSearchAndAdd';
import StaffPage from '../../components/staffPage';
import TableComponent from '../../components/tableComponent';
import { FiEdit } from "react-icons/fi";
import { BsFileEarmarkPdf } from "react-icons/bs";
import EditOccurrenceBook from './edit_occurrence_book';
import AddOccurrenceBook from './add_occurrence_book';
import HOccurrenceBook from '../../hooks/occurrenceBook';
import moment from 'moment';


import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;


export default function OccurrenceBook() {

    const apiOccurrenceBook = HOccurrenceBook();
    const [listdata, setListData] = useState<any>();
    const [listPosts, setPosts] = useState<any>([]);
    const [listItemsPost, setListItemsPost] = useState<any>([]);
    const [firstItemsPost, setFirstItemsPost] = useState<any>([]);
    const [search, setSearch] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)

    const getListData = async (numPage = 1, _search = search) => {
        setLoading(true);
        const params = {
            page: numPage,
            search: _search
        }
        await apiOccurrenceBook.index(params).then((res: Promise<any>) => {
            setListData(res)

        }).finally(() => { setLoading(false) });
    }

    const getPosts = async () => {
        await apiOccurrenceBook.listPost().then(res => {
            setPosts(res)
        })
    }

    const getListPostItens = async (idPost?: any) => {
        await apiOccurrenceBook.getListItemsPost(idPost).then((res) => {
            setListItemsPost(res)
        })
    }



    function gerarRelatorio(dataPdf: any) {


        const headerInitial = [[{ text: 'DESCRIÇÃO', style: 'tableHeader' }, { text: 'QTD. INICIAL', style: 'tableHeader' }, { text: 'QTD. CHECK', style: 'tableHeader' }, { text: 'DIVERGÊNCIA', style: 'tableHeader' }]];

        const rowsInitial = dataPdf.check_concierge_items.map((item: any) => {
            // Certifique-se de que todas as propriedades estão definidas
            if (item.name === undefined || item.initial_volume === undefined || item.check_initial === undefined || item.description_initial === undefined) {
                return ['undefined', 'undefined', 'undefined', 'undefined'];
            } else {
                return [item.name, item.initial_volume, item.check_initial, item.description_initial ?? '----'];
            }
        });
        const headerEnd = [[{ text: 'DESCRIÇÃO', style: 'tableHeader' }, { text: 'QTD. INICIAL', style: 'tableHeader' }, { text: 'QTD. CHECK FINAL', style: 'tableHeader' }, { text: 'DIVERGÊNCIA', style: 'tableHeader' }]];

        const rowsEnd = dataPdf.check_concierge_items.map((item: any) => {
            // Certifique-se de que todas as propriedades estão definidas
            if (item.name === undefined || item.initial_volume === undefined || item.check_final === undefined || item.description_final === undefined) {
                return ['undefined', 'undefined', 'undefined', 'undefined'];
            } else {
                return [item.name, item.initial_volume, item.check_final, item.description_final ?? '----'];
            }
        });

        var pdkMaker: any = {
            content: [
                {
                    text: 'Associação dos Moradores do Coopa-Df\n\n',
                    style: 'header'
                },
                {
                    text: `CADERNO ATA Nº #${dataPdf.id}\n\n`,
                    style: 'bigger'
                },
                { text: `${moment(dataPdf.created_at).format('llll')}\n\n`, style: 'footer' },
                { text: 'Dados Incial\n\n', style: 'negrito' },
                {
                    ul: [
                        `Posto: ${dataPdf?.post?.name}`,
                        `Porteiro:  ${dataPdf?.user?.name}`,
                        `Inicio do Turno:  ${dataPdf?.start_of_shift ?? ''}`,
                        `Fim do Turno: ${dataPdf?.end_of_shift ?? ''} \n\n`,
                    ]
                },
                { text: 'Descrição completa da ATA:\n\n', style: 'negrito' },
                { text: ` ${dataPdf?.complete_description} \n\n`, style: 'description' },

                { text: 'Checklist Entrada:\n\n', style: 'negrito' },
                {
                    style: 'tableExample',
                    table: {
                        widths: ['*', '*', '*', 200],
                        body: [...headerInitial, ...rowsInitial]
                    }
                },

                { text: '\n' },
                { text: 'Checklist Saída:\n\n', style: 'negrito' },
                {
                    style: 'tableExample',
                    table: {
                        widths: ['*', '*', '*', 200],
                        body: [...headerEnd, ...rowsEnd]
                    }
                },

            ],

            styles: {
                header: {
                    fontSize: 18,
                    alignment: 'center'
                },
                footer: {
                    fontSize: 10,
                    bold: true,
                    alignment: 'center'
                },
                bigger: {
                    fontSize: 10,
                    alignment: 'center'
                },
                description: {
                    fontSize: 10,
                },
                negrito: {
                    bold: true,
                },
                tableHeader: {
                    bold: true,
                    fontSize: 12,
                    color: 'black'
                }
            }

        }
        pdfMake.createPdf(pdkMaker).open();

    }


    useEffect(() => {
        getListData();
        getPosts();
    }, [])

    return (
        <>
            <StaffPage iconSvg={SvgCurrenceBook} description='A cada turno, um novo capítulo na história do nosso condomínio.' name='Livro Ata' />

            <CardSearchAndAdd add={() => { (window as any).$(`#modalAdd`).modal('show') }} searchButton={() => { getListData(1, search) }} searchValue={search} searchChange={(e: any) => { setSearch(e.target.value) }} />

            <div className="card">
                <h5 className="card-header">Livros Ata</h5>
                <TableComponent size='sm' loading={loading} PaginateFunction={getListData} PaginateListData={listdata} colums={['#', 'Responsável', 'Check', 'Status', 'Posto', 'Inico', 'Fim', 'data', 'Ações',]} >

                    {
                        listdata &&
                        listdata.data.map((d: any, k: any) => {

                            var checkDivergent = d.check_concierge_items.filter((item: any) => item.description_initial).length;
                            return (
                                <tr key={k}>
                                    <td>{d.id}</td>
                                    <td>{d.user?.name}</td>
                                    <td>
                                        <span className={`badge bg-label-${checkDivergent >= 1 ? 'danger' : 'success'}`}>
                                            {
                                                checkDivergent
                                            }
                                        </span>

                                    </td>
                                    <td> <span className={`badge bg-label-${d.finalized == 1 ? 'success' : 'danger'} me-1`}>{d.finalized == 1 ? 'Ata finalizada' : ' Ata não finalizada'}</span></td>
                                    <td>{d.post?.name}</td>
                                    <td>{d.start_of_shift}</td>
                                    <td>{d.end_of_shift ?? '--'}</td>

                                    <td>
                                        {moment(d.created_at).format('DD/MM/YYYY')}
                                    </td>
                                    <td>
                                        <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                                            <button onClick={() => { (window as any).$('#modalEdit').modal('show'); setFirstItemsPost(d) }} title='Editar' type="button" className="btn btn-primary"><FiEdit /></button>
                                            <button onClick={() => { gerarRelatorio(d) }} title='PDF' type="button" className="btn btn-primary"><BsFileEarmarkPdf /></button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }

                </TableComponent>
            </div>

            <EditOccurrenceBook getListData={getListData} id='modalEdit' firstItemsPost={firstItemsPost} />
            <AddOccurrenceBook getListData={getListData} listPosts={listPosts} listItemsPost={listItemsPost} getListPostItens={getListPostItens} id='modalAdd' />
        </>
    );
}