
import { ToastInfo } from "../../components/ToastInfo";
import { ModalDialog } from "../../components/modalComponent";
import HInfractions from "../../hooks/Infractions";

export default function DeleteInfractions({ id, data, getInfractions }: any) {

    const apiInfractions = HInfractions();

    const deleteInfraction = async () => {

        await apiInfractions.delete(data?.id).then(resp => {
            ToastInfo(resp);
            (window as any).$("#" + id).modal("hide");            
            getInfractions();
        }).catch(erro => {
            (window as any).$("#" + id).modal("hide");
        })

    }

    return (
        <ModalDialog id={id} title="Excluir  ocorrência" onClick={deleteInfraction} buttonClose={[true, 'Não']} buttonSend={[true, 'Sim']}>

            <div>
                Tem certeza que deseja excluir essa ocorrência?
            </div>

        </ModalDialog>
    );
}