import Api from "../../services/api";

const HOccurrenceBook = () => ({

    index: async (data: any) => {

        try {
            const response = await Api.get('/occurrence_book', {
                params: data
            });
            return response.data;
        } catch (error) {
            throw error;
        }

    },

    listPost: async () => {

        try {
            const response = await Api.get('/occurrence_book/list_post');
            return response.data;
        } catch (error) {
            throw error;
        }

    },

    getListItemsPost: async (post_id: any) => {

        try {
            const response = await Api.get('/occurrence_book/get_list_items_post', {
                params: {
                    post_id: post_id
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }

    },

    checksIfTheLastBookWasClosed: async (post_id: any) => {
        try {
            const response = await Api.get(`/occurrence_book/checks_if_the_last_book_was_closed`, {
                params: {
                    post_id: post_id
                }
            });
            return response.data;
        } catch (error) {
            throw error
        }
    },

    store: async (data: any) => {
        try {
            const response = await Api.post('/occurrence_book', data);
            return response.data;
        } catch (error) {
            throw error
        }
    },

    update: async (data: any) => {
        try {
            const response = await Api.put(`/occurrence_book/${data.id}`, data);
            return response.data;
        } catch (error) {
            throw error
        }
    },

    updateRealTimeDescriptionAta: async (data: any) => {
        try {
            const response = await Api.put(`/occurrence_book/update_real_time_description/${data.id}`, data);
            return response.data;
        } catch (error) {
            throw error
        }
    },



})

export default HOccurrenceBook;