function base64ToBlob(base64: string, contentType: string): Blob {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
}


export default async function convertSaveImage(data: any, formData: FormData) {
    if (data.image) {
        if (data.image.startsWith('data:image')) {
            // Imagem no formato base64
            const base64Image = data.image.split(',')[1];
            const convertedFile = base64ToBlob(base64Image, 'image/png');
            formData.append('image', convertedFile, 'photo.png');
        } else if (data.image.startsWith('blob:')) {
            // Imagem como blob
            const file = await fetch(data.image)
                .then((res) => res.blob())
                .then((blob) => new File([blob], 'photo.png', { type: 'image/png' }));

            formData.append('image', file);
        }
    }

    return formData;
}
