import { ModalDialog } from "../../components/modalComponent";
import { FiSearch, FiUsers } from "react-icons/fi";
import TableComponent from "../../components/tableComponent";
import { useState, useEffect, useContext } from 'react';
import { HRegisterCommons } from "../../hooks/RegisterCommons";
import nullAbleImg from '../../assets/nuable_img.png'
import { useNavigate } from "react-router-dom";
import HPassages from "../../hooks/passages";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import Swal from "sweetalert2";
import { ToastInfo } from "../../components/ToastInfo";
import moment from "moment";

export default function AddPassages() {

    const navigate = useNavigate();
    const apiRegisterCommons = HRegisterCommons();
    const token = localStorage.getItem('T');
    const apiPassages = HPassages();

    const auth = useContext(AuthContext);


    const [flexRadioChecked, setFlexRadioChecked] = useState(false);
    const [flexRadioCheckedResponsible, setFlexRadioCheckedResponsible] = useState(false);
    const [flexRadioCheckedVehicle, setFlexRadioCheckedVehicle] = useState(false);

    const [listDataPassing, setListDataPassing] = useState<any>();

    const [firslistDataPassing, setFirstListDataPassing] = useState<any>([]);
    const [firslistDataResponsible, setFirstListDataResponsible] = useState<any>([]);
    const [firslistDataVehicle, setFirstListDataVehicle] = useState<any>([]);

    const [listPassStatus, setListPassStatus] = useState<any>([]);

    const [passingSearch, setPassingSearch] = useState<string>('');

    const [radioSelecttypePeople, setRadioSelectTypePeople] = useState<any>();

    const [checkRadioTypes, setCheckRadioTypes] = useState<any>();

    const { handleSubmit, register, setValue, formState: { errors } } = useForm({
        defaultValues: {
            user_id: auth!.user?.id,
            pass_status_id: '',
            entry_time: '',
            exit_time: '',
            description: '',
        },
        values: {
            user_id: auth!.user?.id,
        }
    })

    const getListDataPassing = async (numpage = 1, _search = passingSearch, type_people = radioSelecttypePeople) => {
        const params = {
            page: numpage,
            search: _search,
            type_people_id: type_people
        }
        await apiRegisterCommons.index(params).then((res) => {
            setListDataPassing(res)
        })
    }

    const getListPassStatus = async () => {
        await apiPassages.listPassStatus().then((res) => {
            setListPassStatus(res);
        }).catch((err) => {

        })
    }

    const modalStartRegister = () => {

        // Resetando os estados
        setListDataPassing(null);
        setFlexRadioChecked(false);

        (window as any).$('#modalStartRegister').modal('hide');
        (window as any).$('#modalSelectResponsible').modal('show');
    }

    const modalSelectResponsible = () => {

        // Resetando os estados
        setFlexRadioCheckedResponsible(false);

        (window as any).$('#modalSelectResponsible').modal('hide');
        (window as any).$('#modalSelectTheVehicle').modal('show');
    }

    const modalSelectTheVehicle = () => {

        // Resetando os estados
        setFirstListDataVehicle([]);
        setFlexRadioCheckedVehicle(false);

        (window as any).$('#modalSelectTheVehicle').modal('hide');
    }



    const storePassagem = async (formData: any) => {
        formData.pass_status_id = parseInt(formData.pass_status_id)

        if (firslistDataPassing.length === 0 || firslistDataResponsible.length === 0) {
            Swal.fire({
                toast: true,
                icon: "info",
                title: "Atenção",
                text: 'Escolha a pessoa que está efetuando a passagem e identifique quem é o responsável por essa transição.',
                showConfirmButton: false,
                showDenyButton: false,
                showCancelButton: false,
                position: 'top-right',
                timer: 4000
            });
            return false
        }

        formData.register_common_id = firslistDataPassing.id;
        formData.responsible_the_visit_id = firslistDataResponsible.id;
        formData.vehicle_id = firslistDataVehicle.id ? firslistDataVehicle.id : null;


        await apiPassages.store(formData).then((res) => {
            ToastInfo(res);
            navigate('/passages');
        }).catch((error) => {
        })

    }

    const setTimePass = (e: React.ChangeEvent<HTMLSelectElement>) => {
        var valuesStatus = e.target.value;
        var dateTime = moment().format('YYYY-MM-DDTHH:mm:ss')
        if (valuesStatus === '3') {

            setValue('entry_time', dateTime) // update field value
            setValue('exit_time', '') // update field value
        }
        if (valuesStatus === '4') {

            setValue('exit_time', dateTime) // update field value
        }
        if (valuesStatus === '1' || valuesStatus === '2' || valuesStatus === '5' || valuesStatus === '6') {
            setValue('entry_time', '') // update field value
            setValue('exit_time', '') // update field value
        }
    }


    useEffect(() => {
        getListPassStatus();
        getListDataPassing();
    }, [])



    return (
        <div className=' card' style={{ padding: 30, }}>


            <h5>Registra nova Pasagem</h5>
            <hr />

            <button onClick={() => { (window as any).$('#modalStartRegister').modal('show'); setCheckRadioTypes(false); setRadioSelectTypePeople(null); setFlexRadioChecked(false); setFlexRadioCheckedResponsible(false) }} className="btn btn-primary"> <FiUsers /> Definir Detalhes da Passagem</button>
            <br />

            {
                firslistDataPassing && firslistDataPassing.id &&
                (
                    <>
                        <div>
                            <h4>Realizando a passagem</h4>

                            <div className="row">
                                <div className="col-md-2">
                                    <span style={{ cursor: 'pointer' }} onClick={() => { (window as any).$('#viewImage').modal('show') }} >
                                        {
                                            firslistDataPassing?.photo_path ?
                                                <img style={{ borderRadius: 10, width: 120 }} src={firslistDataPassing?.image_url + '/' + token} />
                                                : <img width={110} src={nullAbleImg} />
                                        }
                                    </span>
                                </div>
                                <div className="col-md-10">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="mb-2">
                                                <strong >Nome completo</strong> <br />
                                                <span>{firslistDataPassing.name}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-2">
                                                <strong >Documento</strong> <br />
                                                <span>{firslistDataPassing.document}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-2">
                                                <strong >Telefone</strong> <br />
                                                <span>{firslistDataPassing.phone}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="mb-2">
                                                <strong >Tipo</strong> <br />
                                                <span>{firslistDataPassing.types_peoples}</span>
                                            </div>
                                        </div>

                                        {
                                            firslistDataPassing.visitors &&
                                            <div className="col-md-4">
                                                <div className="mb-2">
                                                    <strong >Empresa</strong> <br />
                                                    <span>{firslistDataPassing.visitors.company}</span>
                                                </div>
                                            </div>
                                        }


                                        {
                                            firslistDataPassing.residents &&
                                            <div className="col-md-4">
                                                <div className="mb-2">
                                                    <strong >Email</strong> <br />
                                                    <span>{firslistDataPassing.residents.email}</span>
                                                </div>
                                            </div>
                                        }

                                        {
                                            firslistDataPassing.residents &&
                                            <div className="col-md-4">
                                                <div className="mb-2">
                                                    <strong >Endereço</strong> <br />
                                                    <span>{firslistDataPassing.residents.home_address}</span>
                                                </div>
                                            </div>
                                        }

                                        {
                                            firslistDataPassing.dependents &&
                                            <div className="col-md-4">
                                                <div className="mb-2">
                                                    <strong >Tipo</strong> <br />
                                                    <span>{firslistDataPassing.dependents.type}</span>
                                                </div>
                                            </div>
                                        }


                                        {
                                            firslistDataPassing.employee &&
                                            <div className="col-md-4">
                                                <div className="mb-2">
                                                    <strong >Tipo Funcionário</strong> <br />
                                                    <span>{firslistDataPassing.employee.type}</span>
                                                </div>
                                            </div>
                                        }



                                    </div>

                                </div>
                            </div>

                        </div>

                        <hr />
                    </>
                )
            }


            {
                firslistDataResponsible && firslistDataResponsible.id &&
                (
                    <div>
                        <h4>Responsável por essa passagem</h4>


                        <div className="row">
                            <div className="col-md-2">
                                <span style={{ cursor: 'pointer' }} onClick={() => { (window as any).$('#viewImage').modal('show') }} >
                                    {
                                        firslistDataResponsible?.photo_path ?
                                            <img style={{ borderRadius: 10, width: 120 }} src={firslistDataResponsible?.image_url + '/' + token} />
                                            : <img width={110} src={nullAbleImg} />
                                    }
                                </span>
                            </div>
                            <div className="col-md-10">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="mb-2">
                                            <strong >Nome completo</strong> <br />
                                            <span>{firslistDataResponsible.name}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-2">
                                            <strong >Documento</strong> <br />
                                            <span>{firslistDataResponsible.document}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-2">
                                            <strong >Telefone</strong> <br />
                                            <span>{firslistDataResponsible.phone}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    {
                                        firslistDataResponsible.residents &&
                                        <div className="col-md-4">
                                            <div className="mb-2">
                                                <strong >Email</strong> <br />
                                                <span>{firslistDataResponsible.residents.email}</span>
                                            </div>
                                        </div>
                                    }

                                    {
                                        firslistDataResponsible.residents &&
                                        <div className="col-md-4">
                                            <div className="mb-2">
                                                <strong >Endereço</strong> <br />
                                                <span>{firslistDataResponsible.residents.home_address}</span>
                                            </div>
                                        </div>
                                    }

                                    {
                                        firslistDataResponsible.dependents &&
                                        <div className="col-md-4">
                                            <div className="mb-2">
                                                <strong >Tipo</strong> <br />
                                                <span>{firslistDataResponsible.dependents.type}</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>

                )
            }

            <hr />
            {
                firslistDataVehicle && firslistDataVehicle.id &&

                (
                    <>
                        <div>
                            <h4>Dados do Veículo</h4>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="mb-2">
                                        <strong >Placa</strong> <br />
                                        <span>{firslistDataVehicle.plate}</span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-2">
                                        <strong >Marca</strong> <br />
                                        <span>{firslistDataVehicle.brand}</span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-2">
                                        <strong >Modelo</strong> <br />
                                        <span>{firslistDataVehicle.model}</span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-2">
                                        <strong >Tipo Carro</strong> <br />
                                        <span>{firslistDataVehicle.type}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </>
                )
            }


            {
                firslistDataPassing && firslistDataPassing.id &&
                firslistDataResponsible && firslistDataResponsible.id &&
                <>
                    <div className="row">
                        <div className="col-md-4">

                            <div className="mb-3">
                                <label htmlFor="">Status:</label>
                                <select {...register('pass_status_id', { required: true })} onChange={setTimePass} className="form-control" id="">
                                    <option value="">Selecione...</option>
                                    {
                                        listPassStatus &&
                                        listPassStatus.map((d: any, k: any) => (
                                            <option key={k} title={d.description} value={d.id}>{d.name}</option>
                                        ))
                                    }
                                </select>
                                {
                                    errors.pass_status_id &&
                                    <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                                }
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label htmlFor="">Entrada:</label>
                                <input {...register('entry_time')} type="datetime-local" className="form-control" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label htmlFor="">Saída:</label>
                                <input {...register('exit_time')} type="datetime-local" className="form-control" />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label htmlFor="">observação:</label>
                                <textarea {...register('description')} rows={7} className="form-control" />
                            </div>
                        </div>

                    </div>

                    <hr />
                </>
            }


            <div style={{ display: 'flex', justifyContent: 'end' }}>
                <div>
                    <button onClick={() => { navigate('/passages') }} style={{ marginRight: 10 }} className="btn btn-secondary">Cancelar</button>
                    <button onClick={handleSubmit(storePassagem)} className="btn btn-primary">Salvar</button>
                </div>
            </div>

            {/* -------------------------------------------------------------------------------Modais de registrto-------------------------------------------------------------------------------------- */}

            <ModalDialog modalSize={'xl'} title="Selecione quem estará realizando a passagem." onClick={modalStartRegister} buttonSend={[true, 'Próximo']} id="modalStartRegister">
                <div className="mb-3" style={{ border: '1px solid #cbdbdb', padding: 20 }}>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="input-group input-group-sm mb-3">
                                <input value={passingSearch} onChange={(e) => { setPassingSearch(e.target.value) }} type="text" className="form-control" placeholder="Pesquisar..." />
                                <button onClick={() => { getListDataPassing(1, passingSearch, radioSelecttypePeople) }} className="btn btn-primary"> <FiSearch /></button>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div style={{ marginBottom: 20 }}>

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="inlineRadioOptions" checked={checkRadioTypes === 1} onChange={() => { setCheckRadioTypes(1); setRadioSelectTypePeople(1); getListDataPassing(1, '', 1); setFlexRadioChecked(false); }} id="inlineRadio1" />
                                    <label className="form-check-label" htmlFor="inlineRadio1">Visitante</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="inlineRadioOptions" checked={checkRadioTypes === 2} onChange={() => { setCheckRadioTypes(2); setRadioSelectTypePeople(2); getListDataPassing(1, '', 2); setFlexRadioChecked(false); }} id="inlineRadio2" />
                                    <label className="form-check-label" htmlFor="inlineRadio2">Morador</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="inlineRadioOptions" checked={checkRadioTypes === 3} onChange={() => { setCheckRadioTypes(3); setRadioSelectTypePeople(3); getListDataPassing(1, '', 3); setFlexRadioChecked(false); }} id="inlineRadio3" />
                                    <label className="form-check-label" htmlFor="inlineRadio3">Dependente</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="inlineRadioOptions" checked={checkRadioTypes === 4} onChange={() => { setCheckRadioTypes(4); setRadioSelectTypePeople(4); getListDataPassing(1, '', 4); setFlexRadioChecked(false); }} id="inlineRadio4" />
                                    <label className="form-check-label" htmlFor="inlineRadio3">Funcionário</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        radioSelecttypePeople &&
                        <TableComponent PaginateFunction={getListDataPassing} PaginateListData={listDataPassing?.meta} size="sm" colums={['selecione', 'foto', 'Nome', 'Documento', 'Telefone']}>
                            {
                                listDataPassing &&
                                listDataPassing.data.map((d: any, k: any) => (
                                    <tr key={k}>
                                        <td >
                                            <input
                                                onChange={() => {
                                                    setFirstListDataPassing(d);
                                                    setFlexRadioChecked(k);
                                                }}
                                                className="form-check-input"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id={`flexRadioDefault${k}`}
                                                checked={flexRadioChecked === k} // Verifica se o índice é igual ao item selecionado
                                            />
                                        </td>
                                        <td>
                                            <span title={d.name} style={{ cursor: 'pointer' }} onClick={() => { (window as any).$('#viewImage').modal('show') }} >
                                                {
                                                    d.photo_path ?
                                                        <img width={32} height={32} src={d.image_url + '/' + token} className="rounded-circle" />
                                                        : <img width={32} height={32} src={nullAbleImg} />
                                                }
                                            </span>
                                        </td>
                                        <td>{d.name}</td>
                                        <td>{d.document}</td>
                                        <td>{d.phone}</td>
                                    </tr>
                                ))
                            }
                        </TableComponent>

                    }

                </div>

            </ModalDialog>

            <ModalDialog modalSize={'xl'} title="Selecione o responsável por essa passagem" onClick={modalSelectResponsible} buttonSend={[true, 'Próximo']} id="modalSelectResponsible">
                <div className="mb-3" style={{ border: '1px solid #cbdbdb', padding: 20 }}>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="input-group input-group-sm mb-3">
                                <input value={passingSearch} onChange={(e) => { setPassingSearch(e.target.value) }} type="text" className="form-control" placeholder="Pesquisar..." />
                                <button onClick={() => { getListDataPassing(1, passingSearch, radioSelecttypePeople) }} className="btn btn-primary"> <FiSearch /></button>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div style={{ marginBottom: 20 }}>

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" checked={checkRadioTypes === 5} onChange={() => { setCheckRadioTypes(5); setRadioSelectTypePeople(2); getListDataPassing(1, '', 2); setFlexRadioCheckedResponsible(false) }} />
                                    <label className="form-check-label" htmlFor="inlineRadio2">Morador</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" checked={checkRadioTypes === 6} onChange={() => { setCheckRadioTypes(6); setRadioSelectTypePeople(3); getListDataPassing(1, '', 3); setFlexRadioCheckedResponsible(false) }} />
                                    <label className="form-check-label" htmlFor="inlineRadio3">Dependente</label>
                                </div>

                            </div>
                        </div>
                    </div>

                    <TableComponent PaginateFunction={getListDataPassing} PaginateListData={listDataPassing?.meta} size="sm" colums={['foto', 'Nome', 'Documento', 'Telefone']}>


                        {
                            listDataPassing &&
                            listDataPassing.data.map((d: any, k: any) => (
                                <tr key={k}>
                                    <td >
                                        <input
                                            onChange={() => {
                                                setFirstListDataResponsible(d);
                                                setFlexRadioCheckedResponsible(k);
                                            }}
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefault"
                                            id={`flexRadioDefault${k}`}
                                            checked={flexRadioCheckedResponsible === k} // Verifica se o índice é igual ao item selecionado
                                        />
                                    </td>
                                    <td>
                                        <span title={d.name} style={{ cursor: 'pointer' }} onClick={() => { (window as any).$('#viewImage').modal('show') }} >
                                            {
                                                d.photo_path ?
                                                    <img width={32} height={32} src={d.image_url + '/' + token} className="rounded-circle" />
                                                    : <img width={32} height={32} src={nullAbleImg} />
                                            }
                                        </span>
                                    </td>
                                    <td>{d.name}</td>
                                    <td>{d.document}</td>
                                    <td>{d.phone}</td>
                                </tr>
                            ))
                        }


                    </TableComponent>
                </div>
            </ModalDialog>

            <ModalDialog modalSize={'xl'} title="Selecione o Veículo" id="modalSelectTheVehicle"  >

                <div className="mb-3" style={{ border: '1px solid #cbdbdb', padding: 20 }}>

                    <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                        <div>
                            <h5>Selecione o Veículo (
                                <span title={firslistDataPassing.name} style={{ cursor: 'pointer' }} >
                                    {
                                        firslistDataPassing.photo_path ?
                                            <img width={32} height={32} src={firslistDataPassing.image_url + '/' + token} className="rounded-circle" />
                                            : <img width={32} height={32} src={nullAbleImg} />
                                    }
                                </span>
                                <span> {firslistDataPassing.name}</span>
                                )    </h5>
                        </div>
                    </div>
                    <br />

                    <TableComponent size="sm" colums={['selecione', '#', 'MARCA', 'MODELO', 'TIPO', 'PLACA']}>

                        {
                            firslistDataPassing.vehicles &&
                            firslistDataPassing.vehicles.map((d: any, k: any) => (//
                                <tr key={k}>
                                    <td >
                                        <input
                                            onChange={() => {
                                                setFirstListDataVehicle(d);
                                                setFlexRadioCheckedVehicle(k);
                                            }}
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefault"
                                            id={`flexRadioDefault${k}`}
                                            checked={flexRadioCheckedVehicle === k} // Verifica se o índice é igual ao item selecionado
                                        />
                                    </td>
                                    <td>{d.id}</td>
                                    <td>{d.brand}</td>
                                    <td>{d.model}</td>
                                    <td>{d.type}</td>
                                    <td>{d.plate}</td>
                                </tr>
                            ))
                        }

                    </TableComponent>

                </div>
                <hr />
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <div>
                        <button onClick={modalSelectTheVehicle} style={{ marginRight: 12 }} className="btn btn-secondary">Entrada sem Veículo </button>
                        <button onClick={() => { (window as any).$('#modalSelectTheVehicle').modal('hide') }} className="btn btn-primary">Finalizar</button>
                    </div>
                </div>
            </ModalDialog>

            {/* -------------------------------------------------------------------------------Modais de registrto-------------------------------------------------------------------------------------- */}
        </div>

    );
}