import { useEffect, useState } from 'react';
import SvgHouse from '../../assets/svg/house.svg'
import CardSearchAndAdd from '../../components/cardSearchAndAdd';
import StaffPage from '../../components/staffPage';
import TableComponent from '../../components/tableComponent';
import { FiTrash2, FiEdit, FiFile } from "react-icons/fi";
import AddResidents from './add_residents';
import ViewResidents from './view_residents';
import { useNavigate } from 'react-router-dom';
import { HResidents } from '../../hooks/Residents';
import nullAbleImg from '../../assets/nuable_img.png'

export default function Residents() {


    const navigate = useNavigate();
    const apiResidents = HResidents();
    const [listData, setListData] = useState<any>();
    const token = localStorage.getItem('T');
    const [search, setSearch] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [firstData, setFirstData] = useState<any>([]);



    const getListData = async (numPage = 1, _search = search) => {
        setLoading(true);
        const params = {
            page: numPage,
            paginate: 10,
            search: _search
        }
        await apiResidents.index(params).then((res: any) => {
            setListData(res)
        }).finally(() => {
            setLoading(false)
        });
    }


    useEffect(() => {
        getListData();
    }, [])


    return (
        <>
            <StaffPage iconSvg={SvgHouse} description=' Administre os moradores eficientemente e mantenha a comunidade unida!' name='Moradores' />

            <CardSearchAndAdd add={() => { (window as any).$(`#modalAdd`).modal('show') }} searchButton={() => { getListData(1, search) }} searchValue={search} searchChange={(e: any) => { setSearch(e.target.value) }} />
            <div className="card">
                <h5 className="card-header">Todos Moradores</h5>
                <TableComponent PaginateFunction={getListData} PaginateListData={listData?.meta} loading={loading} colums={['#', 'Morador', 'Nome completo', 'Documento', 'End. resid.', 'Nº tel.', 'Status', 'Ações',]} >
                    {
                        listData &&
                        listData.data.map((d: any, k: any) => (
                            <tr key={k}>
                                <td>{d.id_resident}</td>
                                <td>
                                    <span style={{ cursor: 'pointer' }} onClick={() => { (window as any).$('#viewImage').modal('show') }} >
                                        {
                                            d.photo_path ?
                                                <img width={40} height={40} src={d.image_url + '/' + token} className="rounded-circle" />
                                                : <img width={40} height={40} src={nullAbleImg} />
                                        }
                                    </span>
                                </td>
                                <td>{d.name}</td>
                                <td>{d.document}</td>
                                <td>{d.home_address}</td>
                                <td>{d.phone}</td>
                                <td> <span className={`badge bg-label-${d.active == 1 ? 'success' : 'danger'} me-1`}>{d.active == 1 ? 'Ativo' : 'Inativo'}</span></td>
                                <td>
                                    <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                                        <button onClick={() => { (window as any).$('#modalView').modal('show'); setFirstData(d) }} title='Visualizar' type="button" className="btn btn-primary"><FiFile /></button>
                                        <button onClick={() => { navigate(`edit/${d.id_registerCommon}`) }} title='Editar' type="button" className="btn btn-primary"><FiEdit /></button>
                                    </div>
                                </td>

                            </tr>
                        ))
                    }
                </TableComponent>

            </div>



            <AddResidents getListData={getListData} id='modalAdd' />
            <ViewResidents token={token} nullAbleImg={nullAbleImg} firstData={firstData} id='modalView' />

        </>
    );
} 