
import moment from "moment";
import { ModalDialog } from "../../components/modalComponent";
import nullAbleImg from '../../assets/nuable_img.png'

export default function ViewInfractions({ id, data, token }: any) {
    return (
        <ModalDialog modalSize={'lg'} id={id} title={`Detalhes da ocorrência #${data?.id}`} >

            <div className="row">
                <div className="col-md-4">
                    <span style={{ cursor: 'pointer' }} onClick={() => { (window as any).$('#viewImage').modal('show') }} >
                        {
                            data?.photo_path ?
                                <img style={{ borderRadius: 10, width: 150 }} src={data?.image_url + '/' + token} />
                                : <img width={160} src={nullAbleImg} />
                        }
                    </span>
                </div>
                <div className="col-md-8">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-2">
                                <strong >Responsável:</strong> <br />
                                <span>{data?.name}</span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-2">
                                <strong >Tipo:</strong> <br />
                                <span>{data?.types_peoples}</span>
                            </div>
                        </div>
                    </div>
                    <div className="mb-2">
                        <strong >Documento:</strong> <br />
                        <span>{data?.document}</span>
                    </div>
                    <div className="mb-2">
                        <strong >Telefone:</strong> <br />
                        <span>{data?.phone}</span>
                    </div>
                </div>
            </div>

            <div className="mb-2">
                <strong >Descrição da Ocorrência:</strong> <br />
                <span>{data?.occurrence_description}</span>
            </div>
            <div className="mb-2">
                <strong >Data Ocorrência:</strong> <br />
                <span>{moment(data?.occurrence_date).format("DD/MM/YYYY")}</span>
            </div>

            <div className="row">
                <div className="col-md-4">
                    <div className="mb-2">
                        <strong >Notificado:</strong> <br />
                        <span>{data?.notified == 1 ? 'Sim' : 'Não'}</span>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mb-2">
                        <strong >Recebeu multa:</strong> <br />
                        <span>{data?.received_fine == 1 ? 'Sim' : 'Não'}</span>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mb-2">
                        <strong >Multa Paga	:</strong> <br />
                        <span>{data?.fine_paid == 1 ? 'Sim' : 'Não'}</span>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="mb-2">
                        <strong >Valor da multa	:</strong> <br />
                        <span>{data?.amount_of_the_fine ? parseFloat(data?.amount_of_the_fine).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '--'}</span>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="mb-2">
                        <strong >Mais detalhes sobre o caso:	:</strong> <br />
                        <pre>{data?.observation}</pre>
                    </div>
                </div>


            </div>


        </ModalDialog>
    );
}