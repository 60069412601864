import { useEffect, useState } from 'react';
import SvgVehicles from '../../assets/svg/vehicles.svg'
import CardSearchAndAdd from '../../components/cardSearchAndAdd';
import StaffPage from '../../components/staffPage';
import TableComponent from '../../components/tableComponent';
import { HVehicles } from '../../hooks/vehicles';
import AddVehicles from './add_vehicles';
import EditVehicles from './edit_vehicles';
import ViewVehicles from './view_vehicles';
import { FiEdit, FiFile } from "react-icons/fi";
import moment from 'moment';

export default function Vehicles() {
    const apiVehicles = HVehicles();
    const [listData, setListData] = useState<any>();
    const [search, setSearch] = useState<String>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [firstData, setFirstData] = useState<any>([]);


    const getLisData = async (numPage = 1, _search = search) => {
        setLoading(true)
        const params = {
            page: numPage,
            search: _search,
            showActive: 0
        }
        await apiVehicles.index(params).then((res) => {
            setListData(res)
        }).finally(() => { setLoading(false) })
    }

    useEffect(() => {
        getLisData();
    }, [])


    return (
        <>
            <StaffPage iconSvg={SvgVehicles} description='Cadastre veículos para um gerenciamento de trânsito mais eficiente e seguro!' name='Veículos' />

            <CardSearchAndAdd add={() => { (window as any).$(`#modalAdd`).modal('show') }} searchButton={() => { getLisData(1, search) }} searchValue={search} searchChange={(e: any) => { setSearch(e.target.value) }} />

            <div className="card">
                <h5 className="card-header">Todos Veículos</h5>
                <TableComponent PaginateFunction={getLisData} PaginateListData={listData} loading={loading} colums={['#', 'MARCA', 'MODELO', 'TIPO', 'PLACA', 'Status', 'Cadastro', 'Ações',]} >
                    {
                        listData &&
                        listData.data.map((d: any, k: any) => (
                            <tr key={k}>
                                <td>{d.id}</td>
                                <td>{d.brand}</td>
                                <td>{d.model}</td>
                                <td>{d.type}</td>
                                <td>{d.plate}</td>
                                <td> <span className={`badge bg-label-${d.active == 1 ? 'success' : 'danger'} me-1`}>{d.active == 1 ? 'Ativo' : 'Inativo'}</span></td>
                                <td>{moment(d.created_at).format('DD/MM/YYYY')}</td>
                                <td>
                                    <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                                        <button onClick={() => { (window as any).$('#modalView').modal('show'); setFirstData(d) }} title='Visualizar' type="button" className="btn btn-primary"><FiFile /></button>
                                        <button onClick={() => { (window as any).$('#modalEdit').modal('show'); setFirstData(d) }} title='Editar' type="button" className="btn btn-primary"><FiEdit /></button>
                                    </div>
                                </td>
                            </tr>
                        ))
                    }

                </TableComponent>
            </div>

            <AddVehicles getLisData={getLisData} apiVehicles={apiVehicles} id='modalAdd' />
            <ViewVehicles firstData={firstData} id='modalView' />
            <EditVehicles firstData={firstData} getLisData={getLisData} apiVehicles={apiVehicles} id='modalEdit' />

        </>
    );
}