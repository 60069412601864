import { useForm } from "react-hook-form";
import { ModalDialog } from "../../components/modalComponent";
import { useState } from 'react';
import { HUsers } from "../../hooks/Users";
import { ToastInfo } from "../../components/ToastInfo";

export default function EditUsers({ id, roles, listUsers, selectedDataEdit, selectedRoles, setSelectedRoles }: any) {
    const apiUser = HUsers();
    // const [checkboxValues, setCheckboxValues] = useState<Array<string>>(selectedDataEdit.roles_id || []);




    const { handleSubmit, register, reset, formState: { errors } } = useForm({
        defaultValues: {
            name: selectedDataEdit.name,
            email: selectedDataEdit.email,
            username: selectedDataEdit.username,
            active: selectedDataEdit.active ? 1 : 0,
            roles: selectedDataEdit.roles_id
        },

        values: {
            name: selectedDataEdit.name,
            email: selectedDataEdit.email,
            username: selectedDataEdit.username,
            active: selectedDataEdit.active ? 1 : 0,
            roles: selectedDataEdit.roles_id
        }
    });


    const EditUser = async (formData: any) => {
        formData.id = selectedDataEdit.id;
        formData.roles = selectedRoles;
        formData.active = parseInt(formData.active);
        try {
            await apiUser.update(formData).then((res) => {
                ToastInfo(res)
                reset();
                (window as any).$(`#${id}`).modal('hide');
                listUsers();
            });
        } catch (error: unknown) {
            (window as any).$(`#${id}`).modal('hide');
        }
    };


    const handleCheckboxClickVeicle = (role_id: number, isChecked: boolean) => {
        // Se o checkbox está marcado e o ID do veículo não está na lista de selecionados, adicione-o.
        if (isChecked && !selectedRoles.includes(role_id)) {
            setSelectedRoles([...selectedRoles, role_id]);
        }
        // Se o checkbox não está marcado e o ID do veículo está na lista de selecionados, remova-o.
        else if (!isChecked && selectedRoles.includes(role_id)) {
            setSelectedRoles(selectedRoles.filter((id: any) => id !== role_id));
        }
    }


    return (
        <ModalDialog id={id} title="Cadastrar novo usuário" form={true} onClick={handleSubmit(EditUser)} buttonClose={[true, 'Cancelar']} buttonSend={[true, 'Editar']} >

            <div className="mb-3">
                <label >Nome:</label>
                <input type="text" {...register('name', { required: true })} className="form-control" placeholder="nome" />
                {
                    errors.name &&
                    <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                }
            </div>

            <div className="mb-3">
                <label >E-mail:</label>
                <input type="email" {...register('email', { required: true })} className="form-control" placeholder="exemple@email.com" />
                {
                    errors.email &&
                    <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                }
            </div>

            <div className="mb-3">
                <label >Usuário:</label>
                <input type="text" {...register('username', { required: true })} className="form-control" placeholder="user.nome" />
                {
                    errors.username &&
                    <span style={{ fontSize: 12, color: 'red' }}>Campo obrigatório</span>
                }
            </div>

            <div className="mb-3">
                <label >status:</label>
                <select className="form-control" {...register('active')} >
                    <option value={1}>Ativo</option>
                    <option value={0}>Inativo</option>
                </select>
            </div>

            <hr />

            <h5>Selecione as permissão do usuário</h5>
            {
                roles &&
                roles.map((d: any, k: any) => (
                    <div key={k} className="form-check form-switch">
                        {/* <input
                            className="form-check-input"
                            type="checkbox"
                            id={k}
                            checked={checkboxValues.includes(d.id)}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setCheckboxValues([...checkboxValues, d.id]);
                                } else {
                                    setCheckboxValues(checkboxValues.filter(id => id !== d.id));
                                }
                            }}
                        /> */}

                        <input
                            className="form-check-input"
                            type="checkbox"
                            id={`vehicle-checkbox-${d.id}`}
                            onChange={(e) => handleCheckboxClickVeicle(d.id, e.target.checked)}
                            checked={selectedRoles.includes(d.id)}
                        />
                        <label className="form-check-label" htmlFor={k}>{d.description}</label>
                    </div>
                ))
            }




        </ModalDialog>

    );
}