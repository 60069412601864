import { FiSearch, FiPlus } from "react-icons/fi";


interface ICardSearchAndAdd {
    add?: any;
    searchChange?: any;
    searchValue?: any;
    searchButton?: any;
}

export default function CardSearchAndAdd({ add, searchChange, searchValue, searchButton }: ICardSearchAndAdd) {

    return (
        <div className='card' style={{ marginBottom: 15, padding: 15 }} >
            <div className='row'>
                <div className='col-md-4'>

                </div>
                <div className='col-md-8'>
                    <div className='row'>
                        {
                            add &&
                            <>
                                <div className='col-md-8' style={{ marginTop: 2 }}>
                                    <div className="input-group mb-3">
                                        <input onChange={searchChange} value={searchValue} className='form-control' placeholder='Pesquisar...' type="search" />
                                        <button onClick={searchButton} className='btn btn-primary'> <FiSearch /> </button>
                                    </div>
                                </div>


                                <div className='col-md-4' style={{ marginTop: 2, display: 'flex', justifyContent: 'end' }}>
                                    <button style={{ height: 42 }} className='btn btn-primary' onClick={add} >  <FiPlus /> Novo </button>
                                </div>
                            </>
                        }

                        {
                            !add &&
                            <>
                                <div className='col-md-12' style={{ marginTop: 2 }}>
                                    <div className="input-group mb-3">
                                        <input onChange={searchChange} value={searchValue} className='form-control' placeholder='Pesquisar...' type="search" />
                                        <button onClick={searchButton} className='btn btn-primary'> <FiSearch /> </button>
                                    </div>
                                </div>
                            </>
                        }


                    </div>
                </div>
            </div>
        </div>
    );
}