import axios, { AxiosResponse } from "axios";
import Swal, { SweetAlertIcon } from "sweetalert2";

const baseURL = process.env.REACT_APP_API || "https://api-moradores-coopa-df.softcipe.com/api"; // prod
// const baseURL = process.env.REACT_APP_API || "http://127.0.0.1:8000/api"; // dev


// Create axios instance with default configuration
const Api = axios.create({
    baseURL,
    headers: {
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*',
    },
});

// Interceptor for handling authentication token in request headers
Api.interceptors.request.use((config: any) => {
    const token = localStorage.getItem('T');

    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }

    // Adicionamos esta verificação para lidar com uploads de arquivos
    if (config.data instanceof FormData) {
        delete config.headers['Content-Type']
    }

    return config;
});


// Helper function to handle errors
const handleError = (icon: SweetAlertIcon, title: string, text: string) => {
    Swal.fire({
        toast: true,
        icon,
        showConfirmButton: false,
        title,
        text,
        position: 'top-right',
        timerProgressBar: true,
        timer: 5000
    });
}


// Interceptor for handling response errors
Api.interceptors.response.use((response: AxiosResponse) => response, (error) => {
    // Network error
    if (error.code === 'ERR_NETWORK') {
        handleError("error", "Informar para o Admin do sistema.", "Não é possível estabelece uma conexão com o Backend.");
        return Promise.reject(error);
    }

    // Define error handlers
    const errorHandlers: Record<number, () => void> = {
        500: () => handleError("error", "Internal Server Error", "Não é possível estabelece uma conexão com o servidor."),
        422: () => {
            if (error.response.data) {
                let messages = [];
                for (let key in error.response.data) {
                    messages.push(` ${error.response.data[key].join(', ')}`);
                }
                handleError("warning", "Atenção", messages.join(' '));
            } else {
                handleError("error", "Error", error.response?.data?.error || "Erro desconhecido.");
            }
        },


        404: () => handleError("error", "Error", error.response?.data?.error || "Recurso não encontrado."),
        403: () => handleError("warning", "Atenção", error.response?.data?.error || "Recurso não encontrado."),
        400: () => handleError("error", "Error", error.response?.data?.error || "Requisição inválida."),
        423: () => handleError("error", "Error", error.response?.data?.error || "Recurso bloqueado."),
        401: () => handleError("error", "Error", error.response?.data?.error || "Não autorizado."),
    };

    // Handle error if handler is defined
    const errorHandler = errorHandlers[error.response?.status];
    if (errorHandler) {
        errorHandler();
    }

    return Promise.reject(error);
});

export default Api;

