

import { ModalDialog } from "../../components/modalComponent";

export default function DeleteEmployees({ id }: any) {
    return (
        <ModalDialog id={id} title="Desativar Funcionário" onClick={() => { }} buttonClose={[true, 'Não']} buttonSend={[true, 'Sim']}>

            <div>
                Tem certeza que deseja desativar esse Funcionário?
            </div>

        </ModalDialog>
    );
}