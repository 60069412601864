import { useEffect, useState } from 'react';
import CardSearchAndAdd from "../../components/cardSearchAndAdd"
import StaffPage from "../../components/staffPage"
import SvgPassages from '../../assets/svg/passages.svg'
import HLogs from '../../hooks/logs';
import TableComponent from '../../components/tableComponent';
import moment from 'moment';

export default function Logs() {

    const apiLogs = HLogs();
    const [listlogs, setListlogs] = useState<any>();
    const [search, setSearch] = useState<string>('');
    const [loading, setloading] = useState<boolean>(false);

    const getListLogs = async (numPage = 1, _search = search,) => {
        setloading(true)
        const params = {
            page: numPage,
            search: _search,
        }
        await apiLogs.index(params).then((res: any) => {
            setListlogs(res)
        }).finally(() => {
            setloading(false)
        })
    }

    useEffect(() => {
        getListLogs();
    }, [

    ])

    return (
        <>
            <StaffPage iconSvg={SvgPassages} description='Os logs de usuário revelam o caminho para uma experiência perfeita.' name='Logs' />
            <CardSearchAndAdd searchButton={() => { getListLogs(1, search) }} searchValue={search} searchChange={(e: any) => { setSearch(e.target.value) }} />

            <div className="card">
                <h5 className="card-header">Todos logs</h5>
                <TableComponent bordered={true} loading={loading} size='sm' PaginateFunction={getListLogs} PaginateListData={listlogs} colums={['id', 'responsável', 'Local IP', 'ação', 'origem', 'Descrição', 'REgistro']}>
                    {
                        listlogs &&
                        listlogs.data.map((d: any, k: any) => (
                            <tr key={k}>
                                <td>{d.id}</td>
                                <td>{d.user?.name}</td>
                                <td>{d.ip_session}</td>
                                <td>{d.action}</td>
                                <td>{d.origem}</td>
                                <td style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span style={{ textAlign: 'justify' }}>{d.description}</span>
                                    <strong>Nº: {d.origem_id}</strong>
                                </td>
                                <td>{moment(d.created_at).format('llll')}</td>
                            </tr>
                        ))
                    }
                </TableComponent>
            </div>

        </>
    )
}